import React from "react";
import { useTranslation } from "react-i18next";
import ListItem from "../../Tools/ListItem";
import { TempBaseUrl } from "../../../data/content";
export default function CarBody({
  body_type,
  title,
  handleClick,
  loading,
  activeValues,
}) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  return (
    <div class="strow">
      <h3>{title}</h3>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        {body_type &&
          body_type.map((item, index) => {
            const val =
              item?.name?.[i18n.language] ||
              item?.name?.[lang] ||
              item.name ||
              item;
            return (
              <ListItem
                innerhtmlType={"html"}
                style={`nav-item ${activeValues?.includes(val) ? "active" : ""}`}
                id={`zf${index}`}
                value={
                  <button class="nav-link" onClick={() => handleClick(item)}>
                    <img src={item.image} alt="img" />
                    <span>{val}</span>
                  </button>
                }
              ></ListItem>
            );
          })}
      </ul>
    </div>
  );
}

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import English from "./locales/English/translation.json";
import Arabic from "./locales/Arabic/translation.json";
import Kurdish from "./locales/Kurdish/translation.json";

//Buy car in Baghdad
import English_BuyCarInBaghdad from "./locales/English/translations_BuyCarInBaghdad.json";
import Arabic_BuyCarInBaghdad from "./locales/Arabic/translations_BuyCarInBaghdad.json";
import Kurdish_BuyCarInBaghdad from "./locales/Kurdish/translations_BuyCarInBaghdad.json";

let val = localStorage.getItem("cl") ? localStorage.getItem("cl") : "English";
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: val,
    supportedLngs: ["English", "Arabic", "Kurdish"],
    resources: {
      English: { translation: { ...English, ...English_BuyCarInBaghdad } },
      Arabic: { translation: { ...Arabic, ...Arabic_BuyCarInBaghdad } },
      Kurdish: { translation: { ...Kurdish, ...Kurdish_BuyCarInBaghdad } },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

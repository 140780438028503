import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SelectOptions from "../SelctOptions";
import ListItem from "../ListItem";
import FilterModel from "../FilterModel";
import $ from "jquery";
import { NavLink, useLocation } from "react-router-dom";
import KeywordInput from "../Keyword";
import {
  formatPrice,
  getAllFiltersData,
  getAllFiltersData1,
  getSelectedFilters,
  numWithZero,
  numberWithCommas,
} from "../../../utils/constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import SelectMultiple from "../SelectMultiple";
import Button from "../../Tools/Button";
import { hideSpec, isFieldRequired } from "../Forms/AddCar/addFormUtils";
import { getAdFiltersList1, getModels } from "../../../Reducer/api";
import { useDidMountEffect } from "../../../utils/hooks";
import ValueOnButton from "./valueOnButton";
import ReactSelectCustom from "../ReactSelectCustom";
import { adUserTypes } from "../../../data/content";

export default function FilterSideBar({
  dataset,
  // data,
  handleClickevent,
  allFilters,
  setAllFilters,
  page,
  // models,
  resetFilters,
  ownerId,
  selectedContent,
  // getSelectedFilters
  filtersRef,
  setCurrentPage,
}) {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const location = useLocation();

  const [keywords, setKeywords] = useState([]);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const [models, setModels] = useState(null);
  useEffect(() => {
    // if (allFilters.make) {
    setModels([]);
    getModels({
      makeId: allFilters.make,
      live: true,
      ownerId: "",
      page: page,
      filters: allFilters,
    }).then((res) => {
      if (res) {
        res = res?.map((v) => ({ label: v, value: v }));
        res = [{ label: "All", value: "All" }, ...res];
        setModels(res);
      }
    });
    // }
  }, [allFilters]);

  const skipModel = ["", null, undefined];
  const handleClick = (id, type) => {
    if (filtersLoading) return;
    // if (type === "model" && skipModel.includes(allFilters.make)) return;
    if ($(`#air${id}`).hasClass("show")) {
      $(`.dropdown-menu`).removeClass("show");
      $(".dropdown").removeClass("show");
      setSelected(null);
    } else {
      $(`.dropdown-menu`).removeClass("show");
      $(".dropdown").removeClass("show");
      $(`#maq${id}`).addClass("show");
      $(`#air${id}`).addClass("show");
      setSelected(id);
    }
  };

  const handleAddKeyword = (newKeyword) => {
    setKeywords([...keywords, newKeyword]);
  };

  const removetext = (key) => {
    setKeywords((keywords) => keywords.filter((item, index) => key !== index));
  };
  const [data, setData] = useState(null);
  useDidMountEffect(() => {
    // setFiltersLoading(true);
    // getAllFiltersData(
    //   selectedContent,
    //   ownerId,
    //   allFilters,
    //   getSelectedFilters(
    //     selectedFilters,
    //     setSelectedFilters,
    //     allFilters,
    //     filtersRef,
    //     setCurrentPage
    //   )
    // ).then((res) => {
    //   setData(res);
    //   setFiltersLoading(false);
    // });
    const keys = [
      "cities",
      "condition",
      "paint",
      "colour",
      "regional",
      "gearbox",
      "fuel",
      "bodyType",
      "plateTypes",
      // "transmission",
      "makes",
      "yearsList",
      "interiorColor",
      "modelTrim",
      "cityPlates",
    ];
    const selected = getSelectedFilters(
      selectedFilters,
      setSelectedFilters,
      allFilters,
      filtersRef
    );
    keys.forEach(async (key) => {
      let response = await getAdFiltersList1(
        page,
        "",
        allFilters,
        selected,
        key
      );
      setData((data) => ({ ...data, ...getAllFiltersData1(response) }));
    });
  }, [selectedContent, allFilters]);

  const filterSkip = [t("Any"), "$0", t("Brand new")];
  const showFilteredValues = (type, fullData, value, minOrMax) => {
    // console.log(value)
    if (!value) return fullData;
    if (filterSkip.includes(value)) return fullData;

    if (minOrMax === "min") {
      return fullData?.filter((v) => v.value <= parseFloat(value));
    }
    if (minOrMax === "max") {
      return fullData?.filter((v) => v.value >= parseFloat(value));
    }

    return fullData;
  };
  //utility func, if any filter needs to be conditionally rendered
  const canRender = (item) => {
    const col = item?.backend_column;
    switch (col) {
      case "cylinders":
        if (
          allFilters?.fuel?.length > 0 &&
          allFilters?.fuel.every((item) => hideSpec?.cylinder.includes(item))
        ) {
          return false;
        } else {
          return true;
        }
      case "min_max_engine":
        if (
          allFilters?.fuel?.length > 0 &&
          allFilters?.fuel.every((item) => hideSpec?.engineSize.includes(item))
        ) {
          return false;
        } else {
          return true;
        }
      case "min_max_battery":
        if (
          allFilters?.fuel?.length > 0 &&
          allFilters?.fuel.every((item) => hideSpec?.batterySize.includes(item))
        ) {
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  };

  return (
    <div class="sidebar">
      <div class="set" style={{ padding: "unset", overflow: "" }}>
        <h4>
          {t("Basics filters")}
          <span
            class="close_ic"
            style={{ cursor: "pointer" }}
            onClick={() => $(".sidebar").removeClass("main")}
          >
            <img src="../assets/images/close_img.png" alt="icon" /> {t("Close")}
          </span>
        </h4>

        {!location.pathname.includes("Dealer") && (
          <div class="row mb-3" style={{ padding: "15px" }}>
            {isFieldRequired("sellerType", page) && (
              <div class="col-12 ">
                <label>{t("Seller type")}</label>
                <SelectMultiple
                  selectedOption={
                    allFilters?.adUserType
                      ? adUserTypes?.filter(
                          (v) => v?.value === allFilters?.adUserType
                        )?.[0]
                      : ""
                  }
                  placeholder={t("All cars")}
                  onChange={(e) =>
                    setAllFilters({ ...allFilters, adUserType: e?.value })
                  }
                  values={adUserTypes}
                  showDropdownIcon
                />
              </div>
            )}
            <div class="col-12">
              <label>{t("Cities")}</label>
              <SelectMultiple
                selectedOption={
                  allFilters?.city &&
                  allFilters?.city?.length > 0 &&
                  allFilters?.city !== t("National")
                    ? {
                        label: t(allFilters?.city?.[0]),
                        value: allFilters?.city?.[0],
                      }
                    : ""
                }
                placeholder={t("National")}
                onChange={(e) =>
                  setAllFilters({ ...allFilters, city: e?.value })
                }
                values={data?.cities}
                showDropdownIcon
                isSearchable
              />
            </div>
          </div>
        )}
        <ul>
          {dataset.drop_down_filter && dataset.drop_down_filter.length > 0
            ? dataset.drop_down_filter.map((item, index) => {
                if (!canRender(item)) return;
                return (
                  <ListItem
                    style={"nav-item dropdown"}
                    id={`air${index}`}
                    innerhtmlType={"html"}
                    inlineStyle={{
                      // condition to remove margintop from make filter since city is removed filter from dealer car view
                      borderTop:
                        item?.backend_column === "make" &&
                        location.pathname.includes("Dealer")
                          ? "none"
                          : "",
                    }}
                    value={
                      <>
                        <a
                          class={`nav-link 
                            ${
                              // item?.backend_column === "model" &&
                              // skipModel.includes(allFilters.make)
                              //   ? "bg-light text-secondary"
                              //   : ""
                              ""
                            } 
                          ${filtersLoading ? "bg-light" : ""} `}
                          onClick={() =>
                            handleClick(index, item?.backend_column)
                          }
                          aria-expanded="false"
                          style={{
                            cursor:
                              // (item?.backend_column === "model" &&
                              //   skipModel.includes(allFilters.make))
                              //   ||
                              filtersLoading ? "not-allowed" : "",
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <div>
                              {t(item.name)}{" "}
                              {/* <i class="fa-solid fa-angle-left" ></i> */}
                              <Icon
                                icon="mingcute:right-line"
                                fontSize={20}
                                rotate={
                                  selected == index
                                    ? lang === "en"
                                      ? 1
                                      : 3
                                    : 0
                                }
                              />
                            </div>
                            <ValueOnButton
                              item={item}
                              allFilters={allFilters}
                            />
                          </div>
                        </a>
                        <FilterModel
                          id={`maq${index}`}
                          handleClick={handleClickevent}
                          title={t(item?.name)}
                          data={data?.[item?.backend_column]}
                          type={item?.backend_column}
                          dataType={item?.data_type}
                          allFilters={allFilters}
                          setAllFilters={setAllFilters}
                          showFilteredValues={showFilteredValues}
                          setSelected={setSelected}
                          page={page}
                          models={models}
                        />
                      </>
                    }
                  />
                );
              })
            : ""}
        </ul>
        {/* <KeywordInput onAddKeyword={handleAddKeyword} /> */}
        {/* <ul className="add_drop">
          {keywords.map((keyword, index) => (
            <li key={index}>
              {keyword}{" "}
              <span onClick={() => removetext(index)}>
                <i class="fa-solid fa-xmark"></i>
              </span>
            </li>
          ))}
        </ul>
        <p style={{ paddingInline: '15px' }}>
          <a href="#">
            <img src="assets/images/str.png" lang="icon" /> {t("Save Search")}
          </a>
        </p>
        <p style={{ paddingInline: '15px' }}>
          <NavLink to={paths.saved}>{t("View your saved searches")}</NavLink>
        </p> */}
        <Button
          inlineStyle={{
            color: " #343434",
            fontWeight: "500",
            textDecoration: "none",
            fontSize: "15px",
            border: "none",
            background: "#fff",
            padding: 0,
          }}
          style="bnnr-btn"
          onClick={(e) => {
            e.preventDefault();
            resetFilters();
          }}
          title={
            <>
              <img
                height={26}
                className="mx-2 my-3"
                src={"../assets/images/reset_settings.png"}
              />
              {t("Reset settings")}
            </>
          }
        />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Input, Badge } from "reactstrap";
import Button from "../../Tools/Button";
import Geocode, { fromLatLng, setKey, setLanguage, setRegion } from "react-geocode";
import { toast } from "react-toastify";
import { DEFAULT_LOCATION } from "../../../utils/constants";
import { t } from "i18next";
import { cities } from "../../../data/content";

// Google GeoCode Api Key
// Geocode.setApiKey(GOOGLE_MAP_KEY);
// Geocode.setLanguage("en");
// Geocode.setRegion("es");
// Geocode.enableDebug();
setKey(process.env.REACT_APP_GOOGLE_API_KEY);
setLanguage('en')
setRegion('en')


const GoogleMapAddress = (props) => {
  // const [latitude, setLatitude] = useState();
  // const [longitude, setLongitude] = useState();
  // const [formattedAddress, setFormattedAddress] = useState("")
  const [addressComponent, setAddressComponent] = useState({
    formattedAddress: "",
    latitude: parseFloat(DEFAULT_LOCATION.latitude),
    longitude: parseFloat(DEFAULT_LOCATION.longitude),
    zipcode: "",
    city: "",
    state: "",
  });
  const [geoLocationJson, setGeoLocationJson] = useState(null)

  function extractShortNames(data) {
    const results = data;
    const shortNames = [];

    for (const result of results) {
      const addressComponents = result.address_components;
      for (const component of addressComponents) {
        const types = component.types;
        if (types.includes("locality")) {
          shortNames.push(component.short_name);
        }
      }
    }
  
    return shortNames[0];
  }

  // Google Map Container Styling
  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: addressComponent.latitude,
    lng: addressComponent.longitude,
  };

  const getAddressByLatLong = (lat, long) => {
    fromLatLng("" + lat, "" + long)
      .then(
        (response) => {
            setGeoLocationJson(response)
          const geolocationRes = response.results[0];
          // console.log("Step : 2");

        //   console.log(geolocationRes);
          let addressComponentsArray = [];
          addressComponentsArray = geolocationRes.address_components;
          let zipcode = "";
          let state = "";
          let city = "";
          for (let i = 0; i < addressComponentsArray.length; i++) {
            let addObjtypes = addressComponentsArray[i].types;
            if (addObjtypes.includes("postal_code")) {
              zipcode = `${addressComponentsArray[i].long_name}`;
              // console.log("addObjtypes: ", addressComponentsArray[i].long_name);
              // break;
            }

            if (addObjtypes.includes("administrative_area_level_1")) {
              state = addressComponentsArray[i].long_name;
              // console.log("addObjtypes: ", addressComponentsArray[i].long_name);
              // break;
            }

            if (addObjtypes.includes("locality")) {
              city = addressComponentsArray[i].long_name;
            }
            // console.log("addObjtypes: ", addObjtypes);
          }
          setAddressComponent({
            ...addressComponent,
            latitude: parseFloat(geolocationRes.geometry.location.lat),
            longitude: parseFloat(geolocationRes.geometry.location.lng),
            formattedAddress: geolocationRes.formatted_address,
            zipcode: zipcode,
            state: state,
            city: city,
          });
          // setAddressComponent({...addressComponent, 'longitude': parseFloat(geolocationRes.geometry.location.lng)})
          // setLongitude(geolocationRes.geometry.location.lng)
          // setAddressComponent({...addressComponent, 'formattedAddress': geolocationRes.formatted_address});
        },
        (error) => {
          console.error(error);
        }
      )
      .catch(() => {
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  const handleDrag = (event) => {
    setAddressComponent({
      ...addressComponent,
      latitude: parseFloat(event.latLng.lat()),
      longitude: parseFloat(event.latLng.lng()),
    });
    // setAddressComponent({...addressComponent, 'longitude': parseFloat(event.latLng.lng())})
    // setLatitude(event.latLng.lat());
    // setLongitude(event.latLng.lng());
    getAddressByLatLong(event.latLng.lat(), event.latLng.lng());
  };

  const handelCurrentLocation = () => {
    // console.log(navigator.geolocation)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log(position)
        handlePermission();
        setAddressComponent({
          ...addressComponent,
          latitude: parseFloat(position.coords.latitude),
          longitude: parseFloat(position.coords.longitude),
        });
        // setAddressComponent({...addressComponent, 'longitude': parseFloat(position.coords.longitude)})

        // setLatitude(position.coords.latitude);
        // setLongitude(position.coords.longitude);
        // console.log("Step : 1");
        // console.log("Latitude : ", position.coords.latitude);
        // console.log("Longitude: ", position.coords.longitude);
        getAddressByLatLong(
          position.coords.latitude,
          position.coords.longitude
        );
      });
    }
  };

  function handlePermission() {
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state == "granted") {
          report(result.state);
          // geoBtn.style.display = 'none';
        } else if (result.state == "prompt") {
          report(result.state);
          // geoBtn.style.display = 'none';
          // navigator.geolocation.getCurrentPosition(revealPosition,positionDenied,geoSettings);
        } else if (result.state == "denied") {
          // setAddressComponent({...addressComponent, 'latitude': parseFloat('43.000000'), 'longitude': parseFloat('-75.000000')})
          report(result.state);
          // geoBtn.style.display = 'inline';
        }
        result.onchange = function () {
          report(result.state);
        };
      });
  }

  function report(state) {
    // console.log('Permission ' + state);
  }

  const handleSetAddress = () => {
    console.log(addressComponent);
    if (addressComponent?.formattedAddress?.length > 0) {
      props?.setValue("address", addressComponent?.formattedAddress);
      props?.setValue("completeAddress", addressComponent);
      // props?.setFormData({...props?.formData, 'address': formattedAddress})
      props?.setCityByMap(extractShortNames(geoLocationJson.results))
      // const cityName = extractShortNames(geoLocationJson.results)
      // props?.setValue('city', cityName)
      props?.handleCloseModal();
    }
  };

  return (
    <div>
      <div className="mb-20">
        {/* <LoadScript
                // googleMapsApiKey={GOOGLE_MAP_KEY}
                > */}
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={14}
          onClick={(event) => handleDrag(event)}
          // onLoad={map => {
          //   const bounds = new window.google.maps.LatLngBounds();
          //   map.fitBounds(bounds);
          // }}
          options={{
            // styles: waterStyle,
            fullscreenControl: false,
            disableDefaultUI: true,
          }}
        >
          <Marker
            draggable={true}
            onDragEnd={(event) => {
              handleDrag(event);
            }}
            position={center}
            onLoad={handelCurrentLocation}
            animation={1}
          />
          <Button>hello</Button>
        </GoogleMap>
        {/* </LoadScript> */}
        {/* <div className={styles['myLocationIcon']}><MyLocation></MyLocation></div> */}
      </div>
      <div className="my-2 p-2">
        <Input
          className="fs-6"
          disabled={true}
          value={addressComponent.formattedAddress}
          contentEditable={false}
        ></Input>
      </div>

      {/* <span className="pl-20"><strong>{t('Please ensure to provide Select your correct address as this will be shown to customer on platform')}</strong></span> */}
      <div className="my-1">
        <Button
          style="fs-6 float-end btn btn-primary"
          onClick={() => handleSetAddress()}
          title={t("Submit")}
          disabled={!addressComponent?.formattedAddress}
        />
        {/* <Button
          style="mx-2 fs-6 float-end btn btn-secondary"
          onClick={() => props?.handleCloseModal()}
          title={t("Cancel")}
        /> */}
      </div>
    </div>
  );
};

export default GoogleMapAddress;

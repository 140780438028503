import React from "react";
import { buyCarBaghdadBanner, buyCarBaghdadGuide } from "../../../utils/paths";
import { t } from "i18next";
import InfoCardsCarousel from "../../Tools/InfoCardsCarousel";
import { stepGuideBuyInBaghdad, whyBuyBaghdad, whyChooseAkoInBaghdad } from "./content";
import FaqSection from "../Home/FaqSection";
import { faq_buy_car_baghdad } from "../../../data/faqContent";
import StepsCarouselWithPicture from "../../Tools/StepsCarouselWithPicture";
import { TopNewCars } from "../NewCars/NewCarsContent";
import Follow from "../Home/Follow";
import PopularModal from "../../Tools/PopularModal";
import { useTranslation } from "react-i18next";
import FourCardCarousel from "../../Tools/FourCardCarousel";

function BuyCarInBaghdad() {
  const { t, i18n } = useTranslation();
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  const filters = { city: "Baghdad" };

  return (
    <section
      className="container d-flex flex-column px-0"
      style={{ gap: "60px" }}
    >
      <div
        class="banner px-4"
        style={{
          backgroundImage: `url(${
            buyCarBaghdadBanner
              ? buyCarBaghdadBanner
              : "/assets/images/bannerhome.jpg"
          })`,
          margin: "0px",
        }}
      >
        <h1
          style={{ textShadow: "1px 1px 5px gray", paddingBlock: "30px" }}
          className=" w-100 text-white text-center display-4 fw-bold"
        >
          {t("Buy your dream car in baghdad with Ako Sayara")}
        </h1>
      </div>
      <div>
        <p className="fs-5 mb-0">
          {t(`Baghdad, the heart of Iraq and a bustling city filled with vibrant culture and life, is also a hub for car enthusiasts. Whether you are looking for a brand-new car or a reliable used vehicle, buying a car in Baghdad has never been easier, thanks to Ako Sayara. Ako Sayara is Iraq's leading platform for buying, selling, and renting cars, connecting buyers and sellers seamlessly to meet their automotive needs.`)}
        </p>
      </div>
      <PopularModal
        page={"normal"}
        rtl={rtl}
        filters={filters}
        title={t("Brand new car deals in Baghdad")}
        buttonTitle={"View All New Car Deals"}
        sectionClassName={"m-0"}
      />
      <div>
        <InfoCardsCarousel
          data={whyChooseAkoInBaghdad}
          title={"Why Choose Ako Sayara to Buy a Car in Baghdad?"}
          cardHeight={310}
          sectionClassName={"m-0"}
        />
      </div>
      <div>
        <StepsCarouselWithPicture
          title={
            "Step-by-Step Guide to Buying a Car in Baghdad with Ako Sayara"
          }
          bigPicture={buyCarBaghdadGuide}
          carouselData={stepGuideBuyInBaghdad}
          rtl={rtl}
        />
      </div>
      <div>
        <FourCardCarousel
          title={"Why Buy a Car in Baghdad?"}
          data={whyBuyBaghdad}
          cardHeight={330}
        />
      </div>
      <div>
        <FaqSection
          faq={faq_buy_car_baghdad}
          type={"buy_car_baghdad"}
          sectionClassName={"m-0"}
        />
      </div>
      <Follow />
    </section>
  );
}

export default BuyCarInBaghdad;

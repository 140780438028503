import React from "react";
import NumberInput from "../../NumberInput";
import { useTranslation } from "react-i18next";
import SelectForm from "../../SelectForm";
import { useSelector } from "react-redux";
import SelectOptions from "../../SelctOptions";
export default function SellPrice({
  register,
  errors,
  finance,
  data,
  language,
  setValue,
}) {
  const { t, i18n } = useTranslation();
  const upload_list = useSelector((state) => state.withoutVin.withoutVin);
  const profileData = useSelector((state) => state.myprofile.profilearr);
  return (
    <div class="other_div other_div2 sp">
      <h3>{t("What’s Your Asking Price?")}</h3>
      <div className="mb-4">
        <h5 style={{ fontSize: "16px", fontWeight: 500 }}>
          {t("Price in dollar")}
        </h5>
        <div class="form-group">
          <div class="form-control" style={{ paddingLeft: "12px" }}>
            <NumberInput
              left={true}
              intype={"text"}
              value={data?.dollorAskPrice}
              event={register}
              name={"dollorPrice"}
              placeholder={"$"}
            />
            {errors.dollorPrice && (
              <p className="priceBoxErrors">{errors.dollorPrice?.message}</p>
            )}
          </div>
        </div>
      </div>
      <div className="mb-4">
        <h5 style={{ fontSize: "16px", fontWeight: 500 }}>
          {t("Price in iraqi dinar")}*
        </h5>
        <div class="form-group">
          <div class="form-control" style={{ paddingLeft: "12px" }}>
            <NumberInput
              left={true}
              intype={"text"}
              value={data?.askPrice}
              event={register}
              name={"asking_price"}
              placeholder={"IQD"}
            />
            {errors.asking_price && (
              <p className="priceBoxErrors">{errors.asking_price?.message}</p>
            )}
          </div>
        </div>
      </div>
      <div className="mb-4">
        <h3>{t("Finance")}</h3>
        <SelectForm
          style={"form-control"}
          event={register}
          name={"finance"}
          selectedOption={data?.properties?.finance}
          values={finance}
        />
        {errors.finance && <p>{errors.finance.message}</p>}
      </div>
      {profileData?.userType === "brand" && (
        <div className="mb-4">
          <h3>{t("Ad Label")}</h3>
          {/* <SelectOptions
            noAll
            onChange={(e) => {
              if (e?.target?.value == "None") {
                setValue("adLabel", "");
              } else {
                setValue("adLabel", e?.target?.value);
              }
            }}
            style={"form-control"}
            event={register}
            name={"adLabel"}
            selectedOption={data?.properties?.adLabel}
            values={upload_list?.adLabels}
          /> */}
          <SelectForm
            style={"form-control"}
            event={register}
            name={"adLabel"}
            selectedOption={data?.properties?.adLabel}
            values={upload_list?.adLabels}
          />
          {errors.adLabel && <p>{errors.adLabel.message}</p>}
        </div>
      )}
    </div>
  );
}

import { t } from "i18next";
import React, { useEffect } from "react";

function UnderMaintenance() {
  useEffect(() => {
    document.title = "الموقع تحت الصيانة";
  });

  return (
    <section class="login d-flex justify-content-center">
      <div class="">
        <div className=" w-100 text-center" style={{ paddingBlock: "80px" }}>
          <div className="d-flex justify-content-center ">
            <img
              src={`../assets/images/Logo_ar.png`}
              style={{ objectFit: "contain", width: "60%" }}
            />
          </div>
          <div className="display-1">
            <img
              src="assets/images/under-maintenance.svg"
              height={400}
              style={{ objectFit: "contain" }}
            />
          </div>
          <div
            style={{ fontSize: "35px", color: "#334660" }}
            className="fw-bold"
          >
            {t("الموقع تحت الصيانة")}
          </div>
          <p style={{ fontSize: "28px" }}>
            {t("الموقع يخضع حاليًا لصيانة دورية. سنعود قريبًا.")}
          </p>
        </div>
      </div>
    </section>
  );
}

export default UnderMaintenance;

import React from "react";
import { useTranslation } from "react-i18next";
import ListItem from "../ListItem";
function FilterCheckBox({ name, title, options, allFilters, setAllFilters }) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const handleChange = (e) => {
    const value = e.target.name;
    if (e.target.checked) {
      setAllFilters({ ...allFilters, [name]: [...allFilters?.[name], value] });
    } else {
      setAllFilters({
        ...allFilters,
        [name]: allFilters?.[name]?.filter((v) => v !== value),
      });
    }
  };

  if (!options || options?.length == 0) return;
  return (
    <>
      <label className="colorr">{t(title)}</label>
      <ul
        class="fuel-new  row"
        style={{ listStyleType: "none", padding: "0px" }}
      >
        {options &&
          options?.map((item, index) => {
            return (
              <ListItem
                innerhtmlType={"html"}
                style={" col-6 col-md-3 d-flex align-items-start gap-2 mb-2"}
                inlineStyle={{
                  height: "fit-content",
                }}
                value={
                  <>
                    <input
                      type="checkbox"
                      id={`${name}Box${index}`}
                      onChange={handleChange}
                      checked={allFilters?.[name]?.includes(
                        item?.name?.[i18n.language] ||
                          item?.name?.[lang] ||
                          item?.name
                      )}
                      name={
                        item?.name?.[i18n.language] ||
                        item?.name?.[lang] ||
                        item?.name
                      }
                    />{" "}
                    <label className="mt-0" htmlFor={`${name}Box${index}`}>
                      {t(item?.name?.[i18n.language]) ||
                        t(item?.name?.[lang]) ||
                        t(item?.name)}
                    </label>
                  </>
                }
              />
            );
          })}
      </ul>
    </>
  );
}

export default FilterCheckBox;

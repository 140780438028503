import { t } from "i18next";
import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import {
  sendResetPasswordOTP,
  sendVerificationOTP,
  sendWhatsappOtp,
  verifyOtp,
} from "../../../Reducer/api";
import { Icon } from "@iconify/react/dist/iconify.js";
import { convertDigitsToEnglish } from "../../../utils/constants";
import ReCAPTCHA from "react-google-recaptcha";

export default function VerifyWithOtp({
  modal,
  handleClose,
  data,
  setVerifyErrors,
  presetCode,
  setPresetCode,
}) {
  const isDev = process.env.REACT_APP_PROCESS_MODE === "development";
  const [sentOn, setSentOn] = useState(
    data?.type === "Email" ? "Email" : "Whatsapp"
  );

  useEffect(() => {
    setSentOn(data?.type === "Email" ? "Email" : "Whatsapp");
    if (modal && isDev) {
      if (data?.type === "Email") {
        resendOtp("");
      } else {
        resendWhatsappOtp("");
      }
    }
  }, [data]);

  const [otp, setOtp] = useState("");
  const [initialSent, setInitialSent] = useState(false);
  const lang = localStorage.getItem("cl");
  const [error, setError] = useState(data?.defaultAlert);
  const [loading, setLoading] = useState(false);
  const [timers, setTimers] = useState({ text: 0, whatsapp: 0 });
  const [captchaCode, setCaptchaCode] = useState("");
  const langCaptcha =
    lang === "English" ? "en" : lang === "Arabic" ? "ar" : "ckb";
  useEffect(() => {
    setOtp("");
    setError("");
    setLoading(false);
  }, [handleClose]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimers((prev) => ({
        text: prev.text > 0 ? prev.text - 1 : 0,
        whatsapp: prev.whatsapp > 0 ? prev.whatsapp - 1 : 0,
      }));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const handleSubmit = () => {
    setError("");
    if (otp.length < 6) {
      setError(t("Enter code"));
      return;
    }
    setLoading(true);
    const otpEnDigits = convertDigitsToEnglish(otp);
    verifyOtp({
      otp: otpEnDigits,
      type: sentOn,
      identifier: data.identifier,
    }).then(async (res) => {
      if (res.status) {
        localStorage.setItem(`${data.type}-verified`, true);
        if (data?.callBackSuccess) {
          await data?.callBackSuccess();
        }
        handleClose();
      } else {
        setError(res.message);
      }
      setLoading(false);
    });
  };

  const resendOtp = (code) => {
    const tmp = data?.type === "Email" ? "Email" : "Text";
    let formData = {};
    setTimers((prev) => ({ ...prev, ["text"]: 60 }));
    if (data.type === "Email") {
      formData = { email: data.identifier };
    } else {
      formData = { phoneNumber: data.identifier };
    }
    formData = { ...formData, type: 3, token: code || captchaCode };
    setOtp("");
    setError("");
    sendVerificationOTP(formData).then((res) => {
      if (res.status) {
        if (!code) setError(t("otp sent again"));
        setSentOn(tmp);
      } else {
        setError(res.message);
        setPresetCode?.(null);
      }
      if (!presetCode) {
        setInitialSent(true);
      }
    });
  };

  const resendWhatsappOtp = (code) => {
    setTimers((prev) => ({ ...prev, ["text"]: 60 }));
    const formData = {
      phoneNumber: data.identifier,
      token: code || captchaCode,
    };
    setOtp("");
    setError("");
    sendWhatsappOtp(formData).then((res) => {
      if (res.status) {
        if (!code) setError(t("otp sent again"));
        setSentOn("Whatsapp");
      } else {
        setError(res.message);
        setPresetCode?.(null);
      }
      if (!presetCode) {
        setInitialSent(true);
      }
    });
  };

  const handleCaptureCaptcha = (code) => {
    console.log(code);
    setCaptchaCode(code);
    if (code) {
      handleChangeIsHuman(true);
      if (!initialSent) {
        if (sentOn === "Email") {
          resendOtp(code);
        } else {
          resendWhatsappOtp(code);
        }
      }
    } else {
      handleChangeIsHuman(false);
    }
  };
  const [isHuman, setIsHuman] = useState(false);

  const handleChangeIsHuman = (bool) => {
    if (isDev) {
      setIsHuman(true);
    } else {
      setIsHuman(bool);
    }
  };

  useEffect(() => {
    if (modal && presetCode && data) {
      if (data?.type === "Email") {
        resendOtp(presetCode);
      } else {
        resendWhatsappOtp(presetCode);
      }
      handleChangeIsHuman(true);
    } else {
      handleChangeIsHuman(false);
    }
  }, [modal, presetCode]);

  return (
    <div>
      <Modal
        isOpen={modal}
        // toggle={handleClose}
        size="md"
        centered
      >
        <ModalHeader className="align-items-start" toggle={handleClose}>
          <h4 className="d-flex justify-content-between w-100">
            {data.type === "Email" ? t("Verify email") : t("Verify phone")}
            <span></span>
          </h4>
          {!isDev && !presetCode && (
            <div className={`w-100 d-flex justify-content-start`}>
              <ReCAPTCHA
                size="normal"
                hl={langCaptcha}
                sitekey={process.env.REACT_APP_WEBSITE_KEY}
                onChange={handleCaptureCaptcha}
              />
            </div>
          )}
        </ModalHeader>
        <ModalBody style={{ minHeight: "100px" }}>
          {isHuman ? (
            <div className="d-flex flex-column gap-4">
              <h6 className="text-center m-0">
                {sentOn === "Email"
                  ? t("A one time code has been sent to your email")
                  : ""}
                {sentOn === "Whatsapp"
                  ? t("A Otp has been sent to your whatsapp")
                  : ""}
                {sentOn === "Text"
                  ? t("A Otp has been sent to your phone")
                  : ""}
              </h6>
              <h4 className="m-0">{t("Enter code")}</h4>
              <div
                className="d-flex justify-content-center"
                style={{ direction: "ltr" }}
              >
                <OTPInput
                  inputStyle={{
                    width: "100%",
                    height: "35px",
                    borderRadius: "5px",
                  }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span className="mx-1"></span>}
                  renderInput={(props) => {
                    return (
                      <input
                        {...props}
                        style={{
                          boxShadow: props?.value
                            ? "none"
                            : `0 1.5px 0 0 #989898`,
                          border: "0px",
                          borderRadius: "0px",
                          width: "100%",
                          outline: "none",
                          textAlign: "center",
                          fontWeight: "500",
                          fontSize: "20px",
                          color: "#5b5b5b",
                        }}
                      />
                    );
                  }}
                />
              </div>
              {error && (
                <p
                  className="fs-6 text-danger m-0"
                  style={{ marginInline: "10px" }}
                >
                  {error}
                </p>
              )}
              <div className="row">
                <div className="col-sm-6 col-12 d-flex flex-column justify-content-end">
                  <div
                    style={{ fontSize: "13px", fontWeight: 500, color: `var(--main-blue)` }}
                  >
                    {timers?.text
                      ? `(00:${
                          timers?.text?.toString().padStart(2, "0") || "00"
                        })`
                      : ""}
                  </div>
                  {data?.type !== "Email" && (
                    <p
                      className={`fs-6 m-0 text-${
                        lang === "English" ? "start" : "end"
                      }`}
                      style={{
                        cursor: "pointer",
                        color: `var(${
                          timers?.text > 0 ? "--text-black-2" : "--main-blue"
                        })`,
                      }}
                      onClick={() =>
                        timers?.text > 0
                          ? null
                          : resendWhatsappOtp(presetCode || "")
                      }
                    >
                      {t("Resend on Whatsapp")}{" "}
                      {/* {timers?.whatsapp
                        ? `(00:${
                            timers?.whatsapp?.toString().padStart(2, "0") ||
                            "00"
                          })`
                        : ""} */}
                    </p>
                  )}
                  <p
                    className={`fs-6 m-0 text-${
                      lang === "English" ? "start" : "end"
                    }`}
                    style={{
                      cursor: "pointer",
                      color: `var(${
                        timers?.text > 0 ? "--text-black-2" : "--main-blue"
                      })`,
                    }}
                    onClick={() =>
                      timers?.text > 0 ? null : resendOtp(presetCode || "")
                    }
                  >
                    {data?.type === "Email"
                      ? t("Resend Verification Code")
                      : t("Resend as SMS")}{" "}
                  </p>
                </div>
                <div className="col-sm-6 col-12 d-flex justify-content-end align-items-end gap-3">
                  <Button
                    outline={true}
                    style={{
                      // marginInline: "10px",
                      // width: "100px",
                      fontSize: "14px",
                      height: "50px",
                      borderRadius: "4px",
                    }}
                    onClick={() => {
                      handleChangeIsHuman(false);
                      setInitialSent(false);
                      handleClose();
                    }}
                  >
                    {t("Verify later")}
                  </Button>
                  <Button
                    className="themeButton"
                    disabled={loading}
                    color="primary"
                    style={{
                      // marginInline: "10px",
                      // width: "100px",
                      height: "50px",
                      fontSize: "14px",
                      minWidth: "unset",
                    }}
                    onClick={() => handleSubmit()}
                  >
                    {loading ? (
                      <Spinner size={"sm"}>Loading...</Spinner>
                    ) : (
                      t("Submit")
                    )}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-100 d-flex justify-content-center align-items-center">
              <span className="fs-6">{t("Verify captcha to proceed")}</span>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}

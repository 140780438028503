import { t } from "i18next";
import i18n from "../i18n";

export const bannerCheck = `/assets/screens/CheckCar.jpg`;
export const bannerRent = `/assets/screens/RentCar.png`;
export const bannerNew = `/assets/screens/NewCars.jpg`;
export const bannerBuy = `/assets/screens/HomePage.jpg`;
export const bannerElectric = `/assets/screens/Electric.jpg`;
export const bannerCheap = `/assets/screens/Cheap.jpg`;
export const bannerUsed = `/assets/screens/UsedCars.jpg`;
export const bannerClassic = `/assets/screens/Classics.jpg`;
export const placeAdvertScreen = `/assets/screens/Advertise.png`;

export const buyCarBaghdadBanner = `/assets/images/shadow/buy-car-in-baghdad/Buy_Car_in_Baghdad_1.jpeg`
export const buyCarBaghdadGuide = `/assets/images/shadow/buy-car-in-baghdad/Buy_Car_in_Baghdad_2.jpeg`

export const paths = {
  home: `/`,
  rentCar: `/Search-results/Rent-a-car`,
  checkYourCars: `/Check-your-car`,
  sellYourCar: `/Sell-your-car`,

  rentYourCar: `/Rent-your-car`,
  
  aboutSayara: `/About-us`,
  contact: `/Contact-us`,
  closeAccount: `/Close-my-account`,
  careers: `/Careers`,
  policies: `/Privacy-policies`,
  conditions: `/Terms-and-conditions`,
  cookies: `/Cookies`,
  newCarDeals: `/New-car-deals`,
  classicCars: `/Classic-cars`,
  electricCars: `/Electric-cars`,
  rentPerfectCars: `/Rent-your-perfect-car`,
  articles: `/Articles`,
  advancedSearch: `/Advanced-search`,
  searchResult: `/Search-results`,
  buyCar: `/Search-results/Buy-a-car`,
  rentalSearchResult: `/RentalSearchResult`,

  dealerRental: `/Dealer-rental`,
  dealerCarView: `/Dealer-cars`,
  dealerSearchResults: `/Dealer-search-results`,
  enquiry: `/Enquiry`,
  whyAkoSayara: `/Why-ako-sayara`,

  dealershipDocument: `/Dealership-document`,
  myAccount: `/My-account`,
  myVehicles: `/My-account/Vehicles`,
  myTermsConditions: `/My-account/Terms-and-conditions`,
  myWhyAkoSayara: `/My-account/Why-ako-sayara`,
  myPrivacyPolicies: `/My-account/Privacy-policy`,
  myContactUs: `/My-account/Contact-us`,
  myCloseAccount: `/My-account/Close-my-account`,
  editCar: `/My-account/Edit-car`,
  editProfile: `/My-account/Edit-profile`,
  security: `/My-account/Security`,
  editSecurity: `/My-account/Edit-security`,
  transactions: `/My-account/Transactions`,
  messages: `/My-account/Messages`,
  perfomance: `/My-account/Perfomance`,
  myProfile: `/My-account/My-profile`,
  editWorkHour: `/My-account/Edit-work-hours`,
  myCookies: `/My-account/Cookies`,
  login: `/Login`,
  forgotPassword: `/Forgot-password`,
  enterOTP: `/Forgot-password/Enter-otp`,
  signup: `/Sign-up`,
  basicDetails: `/Basic-details`,
  saved: `/Saved`,
  comparing: `/Comparing`,
  viewImage: `/View-image`,
  plan: `/Ad-plan`,
  renew: `/Renew-ad`,
  payment: `/Payment`,
  viewMap: `/View-on-map`,
  cheapCars: `/Cheap-cars`,
  singleCar: `/Single-car`,
  singleRental: `/Single-rental`,
  search: `/Search`,
  rentalSearch: `/Rental-search`,
  singleCarCheck: `/Single-car-check`,
  singleBrand: `/Brand-results`,
  brandSearchResults: `/Brand-search-results`,
  faqPage: `/Frequently-asked-questions`,
  usedCarDeals: `/Used-car-deals`,

  singleBlog: `/Blog`,
  singleGuide: `/Guide`,
  searchResultPage: `/Dealer-results`,
  myProfile1: `/My-account`,

  storeDetect: `/market/download`,


  sellYourCarPage: `/Sell-your-car/Hero`,
  rentYourCarPage: `/Rent-your-car/Hero`,
  
  buyCarInBaghdad: `/buy-car-in-baghdad`,

  serviceDown: `/service-down`
};
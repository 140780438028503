import withoutVin from "../data/withoutVin.json";
import filter from "../data/filter.json";
import {
  getAdFiltersList,
  getAdFiltersList1,
  getGlobalSettings,
  getMake,
} from "../Reducer/api";
import { t } from "i18next";
import moment from "moment";
import { fromAddress } from "react-geocode";
import { apiManager } from "../api/connectAPI";

const user =
  localStorage.getItem("user") !== "undefined"
    ? JSON.parse(localStorage.getItem("user"))
    : "";
const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : "";

export const DEFAULT_LOCATION = {
  //baghdad latitude and longitude
  latitude: "33.312805",
  longitude: "44.361488",
};

export const majorUserTypes = ["dealer", "brand"];

export const exPh = ["918874227666", "919818140563", "442080640337"];

export const itemLimit = 24;

export const highlightColor = "#F8EFC3";

export const timeData12 = [
  "00:00 AM",
  "00:30 AM",
  "01:00 AM",
  "01:30 AM",
  "02:00 AM",
  "02:30 AM",
  "03:00 AM",
  "03:30 AM",
  "04:00 AM",
  "04:30 AM",
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "08:30 AM",
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
  "08:30 PM",
  "09:00 PM",
  "09:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

export const timeData24 = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export const formatTime12to24 = (time) => {
  const i = timeData12.indexOf(time);
  return timeData24[i];
};
export const formatTime24to12 = (time) => {
  const i = timeData24.indexOf(time);
  return timeData12[i];
};

export function convertTime(time, format) {
  if (time.toLowerCase().includes("am") || time.toLowerCase().includes("pm")) {
    format = "24-hour";
  } else {
    format = "12-hour";
  }
  if (format === "24-hour") {
    // Convert from 12-hour to 24-hour format
    let [timePart, modifier] = time.split(" ");
    modifier = modifier.toUpperCase();
    let [hours, minutes] = timePart.split(":");
    hours = parseInt(hours, 10);
    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0;
    }
    return `${hours.toString().padStart(2, "0")}:${minutes}`;
  } else if (format === "12-hour") {
    // Convert from 24-hour to 12-hour format
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    let modifier = "AM";
    if (hours >= 12) {
      modifier = "PM";
    }
    if (hours > 12) {
      hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }
    return `${hours.toString().padStart(2, "0")}:${minutes} ${modifier}`;
  } else {
    throw new Error('Invalid format specified. Use "12-hour" or "24-hour".');
  }
}

export function removeAmPm(timeString) {
  // Regular expression to match "am" or "pm" in the time string
  var regex = /\b(?:am|pm)\b/gi;
  // Replace "am" or "pm" with an empty string
  return `${timeString.replace(regex, "")}${
    timeString.toLowerCase().includes("pm")
      ? " " + t("PM") + " "
      : " " + t("AM") + " "
  }`;
}

export const staticWorkingHourData = [
  {
    index: 0,
    day: "Sunday",
    startTime: "00:00 am",
    endTime: "00:00 am",
    active: false,
  },
  {
    index: 1,
    day: "Monday",
    startTime: "00:00 am",
    endTime: "00:00 am",
    active: false,
  },
  {
    index: 2,
    day: "Tuesday",
    startTime: "00:00 am",
    endTime: "00:00 am",
    active: false,
  },
  {
    index: 3,
    day: "Wednesday",
    startTime: "00:00 am",
    endTime: "00:00 am",
    active: false,
  },
  {
    index: 4,
    day: "Thursday",
    startTime: "00:00 am",
    endTime: "00:00 am",
    active: false,
  },
  {
    index: 5,
    day: "Friday",
    startTime: "00:00 am",
    endTime: "00:00 am",
    active: false,
  },
  {
    index: 6,
    day: "Saturday",
    startTime: "00:00 am",
    endTime: "00:00 am",
    active: false,
  },
];

export const showTimeUnixTranslated = (time) => {
  const tm = moment.unix(time);
  return `${tm.format("hh:mm")} ${t(tm.format("a").toUpperCase())}`;
};

export function camelToNormal(inputString) {
  if (!inputString) return "";
  // Use a regular expression to add spaces before uppercase letters
  const readableString = inputString.replace(/([A-Z])/g, " $1");

  // Convert the first character to uppercase and remove leading space if any
  return (
    readableString.charAt(0).toUpperCase() + readableString.slice(1).trim()
  );
}
export const handleDownload = (url, fileName) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName || "file";
  a.target = "_blank";
  a.click();
};
export const getFeatureName = (name) => {
  const objBasic = withoutVin.data.basicDetails.filter(
    (v) => v.value === name
  )[0];
  const objSpeci = withoutVin.data.specifications.filter(
    (v) => v.value === name
  )[0];
  const objFeatures = withoutVin.data.features.filter(
    (v) => v.value === name
  )[0];
  return objBasic?.name || objSpeci?.name || objFeatures?.name || null;
};

export function getYearsFrom1950ToCurrent() {
  const currentYear = new Date().getFullYear();
  const startYear = 1950;

  const years = [];
  let i = 1;
  for (let year = currentYear + 1; year >= startYear; year--, i++) {
    years.push({ id: i, name: { en: year, ar: year, ku: year }, value: year });
  }
  return years;
}

export const getAllFiltersData = async (
  page,
  ownerId,
  allFilters,
  selectedFilters
) => {
  let makeData = null;
  let allFiltersList = null;
  let yearsList = null;
  const allApiData = await Promise.all([
    getMake({
      isLive: true,
      limit: 200,
      ownerId: ownerId,
      page: page,
      filters: allFilters,
    }).then((res) => (makeData = res)),
    getAdFiltersList(page, ownerId, allFilters, selectedFilters).then(
      (res) => (allFiltersList = res)
    ),
    apiManager("GET", `admin/getAllYears?lang=en&limit=200`),
  ]);

  makeData = allApiData[0];
  allFiltersList = allApiData[1];
  yearsList = allApiData[2]?.data?.years;

  let filters = withoutVin.data;
  let moreFilters = filter.data.sideBar;
  const sizes = filters.size.map((v) => ({
    ...v,
    name: {
      en: `${v?.value} ${t("Litre")}`,
      ar: `${v?.value} ${t("Litre")}`,
      ku: `${v?.value} ${t("Litre")}`,
    },
  }));
  const powers = filters.power.map((v) => ({
    ...v,
    name: {
      en: `${v?.value} ${t("bhb")}`,
      ar: `${v?.value} ${t("bhb")}`,
      ku: `${v?.value} ${t("bhb")}`,
    },
  }));
  const batterySizes = filters.batterySize.map((v) => ({
    ...v,
    name: {
      en: `${v?.value} ${t("kWh")}`,
      ar: `${v?.value} ${t("kWh")}`,
      ku: `${v?.value} ${t("kWh")}`,
    },
  }));

  moreFilters = {
    ...moreFilters,
    min_max_year: {
      min: {
        ...moreFilters.min_max_year.min,
        data: yearsList,
      },
      max: {
        ...moreFilters.min_max_year.max,
        data: [
          {
            id: 1,
            name: {
              en: "Brand new",
              ar: "Brand new",
              ku: "Brand new",
            },
            value: 9999,
          },
          ...yearsList,
        ],
        dataRental: yearsList,
      },
    },
    regional_space: allFiltersList?.regional,
    body: allFiltersList?.body,
    paint: allFiltersList?.paint,
    gearbox: allFiltersList?.transmission,
    colour: allFiltersList?.colour,
    fuel: allFiltersList?.fuel,
    cities: allFiltersList?.cities,
    body_type: allFiltersList?.bodyType?.map((v, i) => ({ ...v, id: i + 1 })),
    min_max_engine: {
      min: { ...moreFilters.min_max_engine.min, data: sizes },
      max: { ...moreFilters.min_max_engine.max, data: sizes },
    },
    min_max_battery: {
      min: { ...moreFilters.min_max_battery.min, data: batterySizes },
      max: { ...moreFilters.min_max_battery.max, data: batterySizes },
    },
    min_max_engine_power: {
      min: { ...moreFilters.min_max_engine_power.min, data: powers },
      max: { ...moreFilters.min_max_engine_power.max, data: powers },
    },
    cylinders: {
      min: { ...moreFilters.cylinders.min, data: filters.cylinder },
      max: { ...moreFilters.cylinders.max, data: filters.cylinder },
    },
    doors: {
      min: { ...moreFilters.doors.min, data: withoutVin.data.doors },
      max: { ...moreFilters.doors.max, data: withoutVin.data.doors },
    },
    seats: {
      min: { ...moreFilters.seats.min, data: withoutVin.data.seats },
      max: { ...moreFilters.seats.max, data: withoutVin.data.seats },
    },
    make: [
      {
        name: t("Any"),
        value: "Any",
      },
      ...makeData?.makes,
    ],
    // model: modelData?.map((v) => ({ label: v, value: v })),
  };

  filters = { ...filters, ...moreFilters };

  return filters;
};
export const getAllFiltersData1 = (allFiltersList) => {
  let filters = {};
  let moreFilters = { ...filter.data.sideBar };
  const sizes = withoutVin.data.size.map((v) => ({
    ...v,
    name: {
      en: `${v?.value} ${t("Litre")}`,
      ar: `${v?.value} ${t("Litre")}`,
      ku: `${v?.value} ${t("Litre")}`,
    },
  }));
  const powers = withoutVin.data.power.map((v) => ({
    ...v,
    name: {
      en: `${v?.value} ${t("bhb")}`,
      ar: `${v?.value} ${t("bhb")}`,
      ku: `${v?.value} ${t("bhb")}`,
    },
  }));
  const batterySizes = withoutVin.data.batterySize.map((v) => ({
    ...v,
    name: {
      en: `${v?.value} ${t("kWh")}`,
      ar: `${v?.value} ${t("kWh")}`,
      ku: `${v?.value} ${t("kWh")}`,
    },
  }));
  if (allFiltersList?.cities) {
    moreFilters["cities"] = allFiltersList?.cities;
  }
  if (allFiltersList?.plateTypes) {
    moreFilters["plateTypes"] = allFiltersList?.plateTypes;
  }
  if (allFiltersList?.condition) {
    moreFilters["body"] = allFiltersList?.condition;
  }
  if (allFiltersList?.paint) {
    moreFilters["paint"] = allFiltersList?.paint;
  }
  if (allFiltersList?.colour) {
    moreFilters["colour"] = allFiltersList?.colour;
  }
  if (allFiltersList?.interiorColor) {
    moreFilters["interiorColor"] = allFiltersList?.interiorColor;
  }
  if (allFiltersList?.regional) {
    moreFilters["regional_space"] = allFiltersList?.regional;
  }
  if (allFiltersList?.gearbox) {
    moreFilters["transmission"] = allFiltersList?.gearbox;
    moreFilters["gearbox"] = allFiltersList?.gearbox;
  }
  if (allFiltersList?.fuel) {
    moreFilters["fuel"] = allFiltersList?.fuel;
  }
  if (allFiltersList?.bodyType) {
    moreFilters["body_type"] = allFiltersList?.bodyType?.map((v, i) => ({
      ...v,
      id: i + 1,
    }));
  }
  if (allFiltersList?.modelTrim) {
    moreFilters["modelTrim"] = allFiltersList?.modelTrim;
  }
  if (allFiltersList?.makes) {
    moreFilters["make"] = [
      {
        name: t("Any"),
        value: "Any",
      },
      ...(allFiltersList?.makes || []),
    ];
  }
  if (allFiltersList?.yearsList?.years) {
    moreFilters["min_max_year"] = {
      min: {
        name: {
          English: "Min Year",
          Kurdish: "Min Year",
          Arabic: "Min Year",
        },
        data: allFiltersList?.yearsList?.years,
      },
      max: {
        name: {
          English: "Max Year",
          Kurdish: "Max Year",
          Arabic: "Max Year",
        },
        data: [
          {
            id: 1,
            name: {
              en: "Brand new",
              ar: "Brand new",
              ku: "Brand new",
            },
            value: 9999,
          },
          ...(allFiltersList?.yearsList?.years || []),
        ],
        dataRental: allFiltersList?.yearsList?.years,
      },
    };
  }
  if (allFiltersList?.cityPlates) {
    moreFilters["cityPlates"] = allFiltersList?.cityPlates;
  }
  moreFilters = {
    ...moreFilters,
    min_max_engine: {
      min: { ...moreFilters.min_max_engine.min, data: sizes },
      max: { ...moreFilters.min_max_engine.max, data: sizes },
    },
    min_max_battery: {
      min: { ...moreFilters.min_max_battery.min, data: batterySizes },
      max: { ...moreFilters.min_max_battery.max, data: batterySizes },
    },
    min_max_engine_power: {
      min: { ...moreFilters.min_max_engine_power.min, data: powers },
      max: { ...moreFilters.min_max_engine_power.max, data: powers },
    },
    cylinders: {
      min: { ...moreFilters.cylinders.min, data: withoutVin.data.cylinder },
      max: { ...moreFilters.cylinders.max, data: withoutVin.data.cylinder },
    },
    doors: {
      min: { ...moreFilters.doors.min, data: withoutVin.data.doors },
      max: { ...moreFilters.doors.max, data: withoutVin.data.doors },
    },
    seats: {
      min: { ...moreFilters.seats.min, data: withoutVin.data.seats },
      max: { ...moreFilters.seats.max, data: withoutVin.data.seats },
    },
  };

  filters = { ...filters, ...moreFilters };

  return filters;
};

export function checkNullUndefineReturnEmpty(value, returnType) {
  if (value) {
    if (value !== "N/A" && value !== "") {
      if (returnType === "text") return value;
      if (returnType === "boolean") return "true";
    }
  }

  if (returnType === "text") return value;
  if (returnType === "boolean") return false;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function formatPrice(lang, price, dollor) {
  if (isNaN(price)) price = 0;
  if (parseInt(price) === NaN) return t("Any");

  if (dollor && dollor !== "0" && dollor > 0) {
    if (isNaN(dollor)) dollor = 0;
    const exchange = JSON.parse(localStorage.getItem("global"))?.[
      "exchangeRateDollarToIqd"
    ];
    price = exchange * dollor;
    price = parseInt(price) || exchange * dollor;
  }

  const fprice =
    lang === "en"
      ? `${t("IQD")} ${numberWithCommas(price)}`
      : `${numberWithCommas(price)} ${t("IQD")}`;

  return fprice;
}

export const getNameFromVal = (obj, val, lang) => {
  return withoutVin?.data?.[obj]?.filter((v) => v.value == val)?.[0]?.name?.[
    lang
  ];
};

export const makeTagText = (data, lang) => {
  const tm = {
    makeYear: data?.properties?.basicDetails?.makeYear || "",
    bodyType: data?.properties?.specifications?.bodyType?.[lang] || "",
    km: data?.properties?.specifications?.km || "",
    size:
      data?.properties?.specifications?.engineSize ||
      data?.properties?.specifications?.batterySize ||
      "",
    sizeUnit: data?.properties?.specifications?.engineSize
      ? t("Litre")
      : t("kWh"),
    power: data?.properties?.specifications?.enginePower || "",
    gearbox: data?.properties?.specifications?.gearbox?.[lang] || "",
    fuel: data?.properties?.specifications?.fuelType?.[lang] || "",
  };

  const tagText = `${tm?.makeYear ? t(tm?.makeYear) + " | " : ""}`
    .concat(`${tm?.bodyType ? t(tm?.bodyType) + " | " : ""}`)
    .concat(
      `${tm?.km ? numberWithCommas(tm?.km) + " " + t("KMs") + " | " : ""}`
    )
    .concat(`${tm?.size ? `${tm?.size} ${t(tm?.sizeUnit)} | ` : ""}`)
    .concat(
      `${tm?.power ? `${numberWithCommas(tm?.power)} ${t("bhb")} ` : ""} | `
    )
    .concat(`${tm?.gearbox ? t(tm?.gearbox) + " | " : ""}`)
    .concat(`${tm?.fuel ? t(tm?.fuel) : ""}`);

  return tagText;
};

export function isImageOrVideo(url) {
  // Extract the file extension from the URL
  const extension = url?.split?.(".").pop().toLowerCase();

  // Check if the extension corresponds to an image or video format
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  const videoExtensions = ["mp4", "avi", "mkv", "mov", "webm"];

  if (imageExtensions.includes(extension)) {
    return "image";
  } else if (videoExtensions.includes(extension)) {
    return "video";
  } else {
    return "unknown";
  }
}

const filterSkip = [t("Any"), "$0", t("Brand new")];
export const showFilteredValues = (type, fullData, value, minOrMax) => {
  // console.log(value)
  if (!value) return fullData;
  if (filterSkip.includes(value)) return fullData;

  if (minOrMax === "min") {
    return fullData?.filter((v) => v.value < parseFloat(value));
  }
  if (minOrMax === "max") {
    return fullData?.filter((v) => v.value > parseFloat(value));
  }

  return fullData;
};

export const userActivityLocal = (id) => {
  let saved = localStorage.getItem("viewedAds");
  console.log(saved);
  if (saved?.includes(id)) {
    delete saved[saved?.indexOf(id)];
  }
  saved = [id, ...saved];
  localStorage.setItem("viewedAds", saved);
};

export function beginWithImage(urls) {
  if (!urls || urls?.length == 0) {
    return urls;
  }
  urls = urls.map((v) => v.s3URL);
  const isImageUrl = (url) => {
    if (!url) return false;
    // You can implement additional logic here to check if the URL is an image
    // For simplicity, let's assume it's an image if it ends with common image file extensions
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp"];
    return imageExtensions.some((ext) => url?.toLowerCase()?.endsWith(ext));
  };

  const isFirstElementImage = isImageUrl(urls[0]);

  if (isFirstElementImage) {
    return urls;
  } else {
    const indexOfImage = urls.findIndex(isImageUrl);

    if (indexOfImage !== -1) {
      // Swap the first element with the image URL
      [urls[0], urls[indexOfImage]] = [urls[indexOfImage], urls[0]];
    }

    return urls;
  }
}

export const createFiltersString = (filters) => {
  let str = "";
  if (filters?.myAds) {
    str = str.concat("&myAds=true");
  }
  if (filters?.owner) {
    str = str.concat(`&ownerId=${filters?.owner?._id}`);
  }
  if (filters?.make && filters?.make !== t("Any") && filters?.make !== "All") {
    str = str.concat(`&makeNames=${filters?.make}`);
  }
  if (filters?.adUserType) {
    str = str.concat(`&userType=${filters?.adUserType}`);
  }
  if (
    filters?.model &&
    filters?.model !== t("Any") &&
    filters?.model !== "All"
  ) {
    str = str.concat(`&model=${filters?.model}`);
  }
  if (
    filters?.modelTrim &&
    filters?.modelTrim !== t("Any") &&
    filters?.modelTrim !== "All"
  ) {
    str = str.concat(`&modelTrims=${filters?.modelTrim}`);
  }
  if (
    filters?.city?.length > 0 &&
    !filters?.city.includes(t("National")) &&
    !filters?.city.includes("National")
  ) {
    str = str.concat(`&city=${filters?.city?.toString()}`);
  }
  if (
    filters?.cityPlate?.length > 0 &&
    !filters?.cityPlate.includes(t("National")) &&
    !filters?.cityPlate.includes("National")
  ) {
    str = str.concat(`&cityPlates=${filters?.cityPlate?.toString()}`);
  }
  if (
    filters?.regional?.length > 0 &&
    !filters?.regional?.includes(t("Any")) &&
    !filters?.regional?.includes("All")
  ) {
    str = str.concat(`&regionalSpecs=${filters?.regional?.toString()}`);
  }
  if (
    filters?.condition?.length > 0 &&
    !filters?.condition?.includes(t("Any"))
  ) {
    str = str.concat(`&conditions=${filters?.condition?.toString()}`);
  }
  if (filters?.paint?.length > 0 && !filters?.paint?.includes(t("Any"))) {
    str = str.concat(`&paints=${filters?.paint?.toString()}`);
  }
  if (filters?.colour?.length > 0) {
    str = str.concat(`&colors=${filters?.colour?.toString()}`);
  }
  if (filters?.interiorColor?.length > 0) {
    str = str.concat(`&interiorColors=${filters?.interiorColor?.toString()}`);
  }
  if (filters?.gearbox?.length > 0 && !filters?.gearbox?.includes(t("Any"))) {
    str = str.concat(`&gearboxs=${filters?.gearbox?.toString()}`);
  }
  if (filters?.fuel?.length > 0) {
    str = str.concat(`&fuelTypes=${filters?.fuel?.toString()}`);
  }
  if (filters?.bodyType?.length > 0 && !filters?.bodyType?.includes(t("Any"))) {
    str = str.concat(`&bodyTypes=${filters?.bodyType?.toString()}`);
  }
  if (
    filters?.plateType?.length > 0 &&
    !filters?.plateType?.includes(t("Any"))
  ) {
    str = str.concat(`&plateTypes=${filters?.plateType?.toString()}`);
  }
  if (filters?.price) {
    let rt = "";
    switch (filters?.rentType) {
      case "weekly":
        rt = "1";
        break;
      case "monthly":
        rt = "2";
        break;
    }
    if (filters?.price?.min !== t("Any")) {
      if (filters?.price?.min === "$0") {
        str = str.concat(`&minAskPrice${rt}=${"0"}`);
      } else {
        str = str.concat(`&minAskPrice${rt}=${filters?.price?.min}`);
      }
    }
    if (filters?.price?.max !== t("Any")) {
      str = str.concat(`&maxAskPrice${rt}=${filters?.price?.max}`);
    }
  }
  if (filters?.year) {
    if (filters?.year?.min !== t("Any")) {
      str = str.concat(`&minMakeYear=${filters?.year?.min}`);
    }
    if (filters?.year?.max !== t("Any")) {
      str = str.concat(`&maxMakeYear=${filters?.year?.max}`);
    }
  }
  if (filters?.km) {
    if (filters?.km?.min !== t("Any")) {
      str = str.concat(`&minKm=${filters?.km?.min}`);
    }
    if (filters?.km?.max !== t("Any")) {
      str = str.concat(`&maxKm=${filters?.km?.max}`);
    }
  }
  if (filters?.size) {
    if (filters?.size?.min !== t("Any")) {
      str = str.concat(`&minEngineSize=${filters?.size?.min}`);
    }
    if (filters?.size?.max !== t("Any")) {
      str = str.concat(`&maxEngineSize=${filters?.size?.max}`);
    }
  }
  if (filters?.batterySize) {
    if (filters?.batterySize?.min !== t("Any")) {
      str = str.concat(`&minBatterySize=${filters?.batterySize?.min}`);
    }
    if (filters?.batterySize?.max !== t("Any")) {
      str = str.concat(`&maxBatterySize=${filters?.batterySize?.max}`);
    }
  }
  if (filters?.power) {
    if (filters?.power?.min !== t("Any")) {
      str = str.concat(`&minEnginePower=${filters?.power?.min}`);
    }
    if (filters?.power?.max !== t("Any")) {
      str = str.concat(`&maxEnginePower=${filters?.power?.max}`);
    }
  }
  if (filters?.cylinders) {
    if (filters?.cylinders?.min !== t("Any")) {
      str = str.concat(`&minCylinders=${filters?.cylinders?.min}`);
    }
    if (filters?.cylinders?.max !== t("Any")) {
      str = str.concat(`&maxCylinders=${filters?.cylinders?.max}`);
    }
  }
  if (filters?.doors) {
    if (filters?.doors?.min !== t("Any")) {
      str = str.concat(`&minDoors=${filters?.doors?.min}`);
    }
    if (filters?.doors?.max !== t("Any")) {
      str = str.concat(`&maxDoors=${filters?.doors?.max}`);
    }
  }
  if (filters?.seats) {
    if (filters?.seats?.min !== t("Any")) {
      str = str.concat(`&minSeats=${filters?.seats?.min}`);
    }
    if (filters?.seats?.max !== t("Any")) {
      str = str.concat(`&maxSeats=${filters?.seats?.max}`);
    }
  }
  if (filters?.brandNew) {
    str = str.concat(`&brandNew=Yes`);
  }
  if (filters?.brandNew === false) {
    str = str.concat(`&brandNew=No`);
  }
  if (filters?.price?.finance) {
    str = str.concat(`&finance=Yes`);
  }
  if (filters?.sort) {
    str = str.concat(`&sort=${filters?.sort}`);
  }
  return str;
};

export const numWithZero = (num) => {
  return parseFloat(num).toLocaleString("en", {
    useGrouping: false,
    minimumFractionDigits: 1,
  });
};
export const numWithZeroDec = (num, t) => {
  return parseFloat(num).toLocaleString("en", {
    useGrouping: false,
    minimumFractionDigits: t,
  });
};

export const openAddressOnMap = (address) => {
  if (!address) return;
  fromAddress(address)
    .then(({ results }) => {
      const { lat, lng } = results[0].geometry.location;
      console.log(lat, lng);
      window.open("https://maps.google.com?q=" + lat + "," + lng);
    })
    .catch(console.error);
};

export const twoDig = (num) => {
  console.log(num);
  return num.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export function convertDigitsToEnglish(text) {
  if (!text) return "";
  // Define a mapping of Arabic/Kurdish digits to English digits
  const digitMap = {
    "٠": "0",
    "١": "1",
    "٢": "2",
    "٣": "3",
    "٤": "4",
    "٥": "5",
    "٦": "6",
    "٧": "7",
    "٨": "8",
    "٩": "9",
  };

  const regex = /[٠١٢٣٤٥٦٧٨٩]/g;

  const convertedText = text.replace(
    regex,
    (match) => digitMap[match] || match
  );

  return convertedText;
}

// ٨٥٢٨٥٢٨٥٢٨

export const beforeRefresh = () => {
  console.log("byeeeeeeeeeeeeeee");
  localStorage.removeItem("adTypeData");
  localStorage.removeItem("entityTypeData");
  localStorage.removeItem("openInApp");
};

export const findUploads = (data) => {
  if (
    data?.uploadsInOrder?.uploads &&
    data?.uploadsInOrder?.uploads?.length > 0
  ) {
    return data?.uploadsInOrder?.uploads;
  } else if (data?.uploads && data?.uploads?.length > 0) {
    return data?.uploads;
  } else {
    return [];
  }
};

export function getVideoCover(file, seekTo = 1) {
  console.log("getting video cover for file: ", file);
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener("error", (ex) => {
      reject("error when loading video file", ex);
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject("video is too short.");
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        console.log("video is now paused at %ss.", seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.75 /* quality */
        );
      });
    });
  });
}

export function blobToFile(blob, fileName) {
  return new File([blob], fileName, {
    type: blob.type,
    lastModified: Date.now(),
  });
}

export function generateFileFromPath(filePath) {
  return new Promise((resolve, reject) => {
    fetch(filePath)
      .then((response) => response.blob())
      .then((blob) => {
        const fileName = filePath.split("/").pop(); // Extract filename from the path
        const file = new File([blob], fileName, { type: blob.type });
        resolve(file);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function checkImageResolution(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = url;
  });
}

export const addWatermark = (compressedBlob, watermarkSrc) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(compressedBlob);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const watermark = new Image();
        watermark.src = watermarkSrc; // Path to watermark image

        watermark.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw the compressed image
          ctx.drawImage(img, 0, 0);

          // Draw the watermark (adjust position/size as needed)
          const watermarkWidth = watermark.width;
          const watermarkHeight = watermark.height;
          ctx.globalAlpha = 1; // Adjust opacity of watermark
          ctx.drawImage(
            watermark,
            img.width - watermarkWidth - 10, // Margin from right
            img.height - watermarkHeight - 10, // Margin from bottom
            watermarkWidth,
            watermarkHeight
          );

          // Return the final image as a Blob (avoiding re-encoding at higher quality)
          canvas.toBlob(
            (finalBlob) => {
              resolve(finalBlob);
            },
            "image/jpeg",
            0.7
          ); // Adjust quality to prevent file size increase
        };
      };
    };
  });
};

export const getMyLocation = () => {
  const location = window.navigator && window.navigator.geolocation;

  if (location) {
    location.getCurrentPosition(
      (position) => {
        return;
      },
      (error) => null
    );
  }
};

export const onPageLoaded = () => {
  // getGlobalSettings();
};

export const checkVal = (val) => {
  return val && val !== "0";
};
export const checkProfileComplete = (data, checkMessage) => {
  if (!data) return false;
  const messages = data?.unreadMessages || 0;
  if (checkMessage && messages > 0) {
    return false;
  }
  const emailVerified = data?.emailVerified;
  const phoneNumberVerified = data?.phoneNumberVerified;
  const kycDocuments = data?.additionalInfo?.dealerKycDocuments || null;
  const workingHours = data?.additionalInfo?.dealerWorkingHours || null;
  const coverPicture =
    data?.coverPicture !== "N/A" ? data?.coverPicture || null : null;
  if (data?.userType === "dealer" || data?.userType === "brand") {
    if (
      emailVerified === false ||
      phoneNumberVerified === false ||
      kycDocuments === null ||
      workingHours === null ||
      coverPicture === null
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    if (data?.phoneNumberVerified === false || data?.emailVerified === false) {
      return false;
    } else {
      return true;
    }
  }
};

const keyToParam = {
  city: "city",
  regional: "regionalSpecs",
  colour: "colors",
  interiorColor: "interiorColors",
  paint: "paints",
  condition: "conditions",
  bodyType: "bodyTypes",
  make: "makes",
  fuel: "fuelTypes",
  plateType: "plateType",
  modelTrim: "modelTrim",
  cityPlate: "cityPlates",
};
const skipValues = ["cities", ["All cities"], "All cities"];
const skipKeys = ["make", "model"];
function isNotInSkip(value) {
  // If value is a string, check directly
  if (typeof value === "string") {
    return !skipValues.includes(value);
  }

  // If value is an array, check if it's equal to the array ["All cities"]
  if (Array.isArray(value)) {
    return !skipValues.some(
      (item) =>
        Array.isArray(item) && JSON.stringify(item) === JSON.stringify(value)
    );
  }

  // If value is neither string nor array, return true (it's not in skip)
  return true;
}
export const getSelectedFilters = (
  selectedFilters,
  setSelectedFilters,
  allFilters,
  filtersRef,
  setCurrentPage
) => {
  const prevFilters = filtersRef.current;
  let newSel = selectedFilters;

  Object.keys(allFilters).forEach((key) => {
    if (allFilters[key] !== prevFilters[key]) {
      const keyOnParam = keyToParam[key];

      if (setCurrentPage) {
        setTimeout(() => {
          setCurrentPage(1);
        }, 200);
      }

      if (skipKeys.includes(key)) {
        return;
      }

      if (
        allFilters[key] &&
        allFilters[key]?.length > 0 &&
        isNotInSkip(allFilters[key])
      ) {
        if (!newSel.includes(keyOnParam)) newSel.push(keyOnParam);
      } else {
        newSel = newSel.filter((v) => v !== keyOnParam);
      }
    }
  });
  setSelectedFilters(newSel);
  filtersRef.current = allFilters;

  return newSel;
};

export function isPositiveWholeNumber(value) {
  return (
    !isNaN(value) &&
    Number.isInteger(parseFloat(value)) &&
    parseFloat(value) > 0
  );
}

export const renderPhoneNumber = (owner, Component) => {
  let phone = "";
  if (owner?.userType === "brand") {
    phone = owner?.additionalInfo?.phoneNumber1 || owner?.phoneNumber;
  } else {
    phone = owner?.phoneNumber;
  }

  if (phone && phone?.length > 0 && phone !== "N/A") {
    return <Component phone={phone} />;
  }
};

export const setCourierFilters = (filters, key) => {
  if (filters) {
    localStorage.setItem("courierFilters", JSON.stringify(filters));
    localStorage.setItem("courierKey", key);
  } else {
    localStorage.setItem("courierFilters", JSON.stringify(null));
    localStorage.setItem("courierKey", "");
  }
};

export const getCourierFilters = () => {
  const filters = JSON.parse(localStorage.getItem("courierFilters"));
  return filters || null;
};

export const setAdsArray = (arr) => {
  if (arr?.length > 0) {
    localStorage.setItem("adsArray", JSON.stringify({ adsArray: arr }));
  } else {
    localStorage.setItem("adsArray", JSON.stringify(null));
  }
};

export function formatUnixTimeXtimeAgo(unixTime) {
  const now = new Date();
  const time = new Date(unixTime * 1000);
  const diff = (now - time) / 1000; // Difference in seconds

  // Calculate time differences
  const minutes = Math.floor(diff / 60);
  const hours = Math.floor(diff / 3600);
  const days = Math.floor(diff / 86400);
  const months =
    now.getMonth() -
    time.getMonth() +
    12 * (now.getFullYear() - time.getFullYear());
  const years = now.getFullYear() - time.getFullYear();

  // Format date and time
  const options = { month: "long", day: "numeric" };
  const formattedDate = time.toLocaleDateString();

  // Return the appropriate string
  if (diff < 60) return t("just now");
  if (minutes < 60) {
    if (minutes === 1) return `1 ${t("minute ago")}`;
    if ([2, 5, 10, 20, 30].includes(minutes))
      return `${minutes} ${t("minutes ago")}`;
    return `${minutes} ${t("minutes ago")}`;
  }
  if (hours < 24) {
    if ([1, 2, 3, 5, 8].includes(hours)) return `${hours} ${t("hours ago")}`;
    return `${hours} ${t("hours ago")}`;
  }
  if (days === 0) return t("text_today");
  if (days === 1) return t("yesterday");
  if (days < 30) return formattedDate;
  if (months === 1) return t("one month ago");
  if (years === 0) return time.toLocaleDateString(undefined, { month: "long" });
  return years === 1 ? `1 ${"year ago"}` : `${years} ${t("years ago")}`;
}

// just now
// minute ago
// minutes ago
// hours ago
// today
// yesterday
// 1 month ago
// year ago
// years ago
// January
// February
// March
// April
// May
// June
// July
// August
// September
// October
// November
// December

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ComparePages } from "../../../data/content";
import { useDispatch, useSelector } from "react-redux";
import Fact from "./fect";
import ImageView from "./imageView";
import $ from "jquery";
import MapView from "./mapView";
import { fetchSaved } from "../../../Reducer/savedSlice";
import { fetchFilteredSearchResults } from "../../../Reducer/api";
import { paths } from "../../../utils/paths";

export default function Compare() {
  const data = useSelector((state) => state.auth.cars);
  const [datas, setDatas] = useState([]);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const cars = location.state.cars;
    setDatas(
      cars.map((v) => ({
        ...v,
        properties: {
          ...v?.properties,
          basicDetails: {
            ...v?.properties?.basicDetails,
            cityPlate: v?.properties?.cityPlate,
          },
        },
      }))
    );
  }, []);

  // const clickTab = (text) => {
  //   switch (text) {
  //     case 0:
  //       $(".Viewdet").removeClass("w-auto");
  //       break;
  //     case 1:
  //       $(".Viewdet").addClass("w-auto");
  //       break;
  //     case 2:
  //       $(".Viewdet").addClass("w-auto");
  //       break;
  //   }
  // };

  const onChange = (index) => {
    let ds = [
      ...datas.slice(0, index),
      ...datas.slice(index + 1, datas.length),
    ];
    if (ds?.length === 0) navigate(paths.saved);
    dispatch(fetchSaved(ds));
    setDatas(ds);
  };
  console.log(datas);
  return (
    <>
      <section class="key_facts pt-4 mb-5">
        <div class="container">
          <h6 class="text-center" style={{ position: "relative" }}>
            <h6 style={{ borderBottom: "unset", position: "absolute" }}>
              <NavLink to={paths.saved}>
                <img src="assets/images/nearrow.png" />{" "}
                <p className="d-none d-sm-inline ">{t("Back to list")}</p>
              </NavLink>{" "}
            </h6>
            {t("Comparing")} {datas?.length} {t("vehicles now")}
          </h6>
          <Tabs style={{ position: "relative" }}>
            <div class="row">
              <div class="col-md-12  text-end">
                <div class="Viewdet" style={{ width: "100% !important" }}>
                  <TabList
                    className="nav nav-tabs my-4"
                    style={{
                      float: i18n.language === "English" ? "right" : "left",
                    }}
                    id="myTab"
                    role="tablist"
                  >
                    {ComparePages.map((item, index) => {
                      return (
                        <Tab
                          className={"nav-item w-50"}
                          // onClick={() => clickTab(index)}
                          key={index}
                        >
                          <a className={"nav-link"}>{t(item.title)}</a>
                        </Tab>
                      );
                    })}
                  </TabList>
                </div>
              </div>
              <TabPanel>
                <Fact data={datas} onChange={onChange} />
              </TabPanel>
              <TabPanel>
                <ImageView data={datas} onChange={onChange} />
              </TabPanel>
              {/* <TabPanel>
                <MapView data={datas} onChange={onChange} />
              </TabPanel> */}
            </div>
          </Tabs>
        </div>
      </section>
    </>
  );
}

import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { NavLink, useNavigate } from "react-router-dom";
import FormInput from "../../../../Tools/FormInput";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiManager } from "../../../../../api/connectAPI";
import { getAllGenders, title } from "../../../../../data/content";
import SelectForm from "../../../../Tools/SelectForm";
import PhoneNumberInput from "../../../../Tools/PhoneNumberInput";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import GoogleMapAddress from "../../../../Tools/MapInput";
import { useEffect } from "react";
import TextAreaForm from "../../../../Tools/TextAreaForm";
import {
  getAllCities,
  sendWelcomeMail,
  translateInTwoLanguages,
} from "../../../../../Reducer/api";
import { useSelector } from "react-redux";
import {
  blobToFile,
  convertDigitsToEnglish,
  handleDownload,
  majorUserTypes,
  twoDig,
} from "../../../../../utils/constants";
import CustomPhoneInput from "../../../../Tools/CustomPhoneInput";
import CustomDatepicker from "../../../../Tools/Datepicker";
import { utils } from "react-modern-calendar-datepicker";
import moment, { months } from "moment";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Button from "../../../../Tools/Button";
import Cropper from "react-easy-crop";
// import { Slider } from "@mui/material";
import getCroppedImg from "./image-crop-utils";

import "rc-slider/assets/index.css";
import Slider from "rc-slider";
import { paths } from "../../../../../utils/paths";
import ReactSelectForm from "../../../../Tools/ReactSelectForm";

export default function EditProfile() {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const { t, i18n } = useTranslation();

  const userType = localStorage.getItem("userType");
  const meta = location?.state?.data;

  // states
  const [profiledata, setProfileData] = useState(meta);
  const [submitting, setSubmitting] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [genders, setGenders] = useState([]);
  const [cityByMap, setCityByMap] = useState(meta?.city);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [imageToCrop, setImageToCrop] = useState();
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cities, setCitites] = useState([]);

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleCloseModal = () => {
    setTimeout(() => {
      setModalData(null);
      setImageToCrop("");
      setZoom(1);
      setRotation(1);
    }, 300);
    setModal(false);
  };
  const openModal = (data) => {
    setModalData(data);
    setModal(true);
  };

  useEffect(() => {
    getDropDownData();
  }, []);

  const handleImageChange = (event) => {
    // if (event.target.files && event.target.files.length > 0) {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(event.target.files[0]);
    //   console.log(event.target.files)
    //   reader.onload = () => setImageToCrop(reader.result);
    //   openModal({ type: "crop", title: "Crop profile picture" });
    // }

    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      // setSelectedImage(file);
      setImageToCrop(URL.createObjectURL(file));
      reader.readAsDataURL(file);
      openModal({ type: "crop", title: t("Editing") });
    }
  };

  const schema = Yup.object().shape({
    // phoneNumber: Yup.string().required(t("phone number is required")),
    fullName: Yup.string().required(t("Full name is required")),
    email: Yup.string(),
    gender: Yup.string()
      .test("match", t("Gender is required"), (v) => v != t("Select"))
      .required(),
    city: Yup.object().required(t("City is required")),
    address: majorUserTypes.includes(userType)
      ? Yup.string()
          .min(6, t("Address must be at least 6 characters"))
          .required(t("Address is a required field"))
      : Yup.string(),
    dealerShipName: majorUserTypes.includes(userType)
      ? Yup.string().required(
          userType === "dealer"
            ? t("Dealership is required")
            : t("Brand name is required")
        )
      : Yup.string(),
    website: Yup.string(),
    about: Yup.mixed().nullable(),
    phoneNumber: Yup.string()
      .min(9, t("Phone number is not valid"))
      .max(16, t("Phone number is not valid")),
    phoneNumber1:
      // userType === "brand"
      //   ? Yup.string()
      //       .min(9, t("Brand phone number is not valid"))
      //       .max(16, t("Brand phone number is not valid"))
      //   : 
        Yup.string(),
    dob: Yup.mixed()
      .required(t("This field is requried"))
      .label(t("Date of birth")),
  });

  const tryPrefill = (val) => {
    return val && val !== "N/A" ? val : "";
  };

  const {
    register,
    control,
    setValue,
    setError,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumber: tryPrefill(profiledata?.phoneNumber),
      phoneNumber1: tryPrefill(profiledata?.additionalInfo?.phoneNumber1),
      fullName: tryPrefill(profiledata?.fullName),
      email: tryPrefill(profiledata?.email),
      address: tryPrefill(profiledata?.address),
      dealerShipName: tryPrefill(profiledata?.dealerShipName),
      website: tryPrefill(profiledata?.additionalInfo?.website),
      about: tryPrefill(profiledata?.additionalInfo?.about?.[lang]),
      dob:
        profiledata?.dateOfBirth && profiledata?.dateOfBirth !== "N/A"
          ? {
              day: moment.unix(profiledata.dateOfBirth).toDate().getDate(),
              month:
                moment.unix(profiledata.dateOfBirth).toDate().getMonth() + 1,
              year: moment.unix(profiledata.dateOfBirth).toDate().getFullYear(),
            }
          : "",
    },
    mode: "onBlur",
  });
  const onError = (errors) => {
    console.log(errors);
  };
  const onSubmitform = async (data) => {
    if (!submitting) {
      // if (phoneNumberErrors) {
      //   return;
      // }

      data.city = data.city.value;
      data.dateOfBirth = moment(
        twoDig(data.dob.month) +
          "/" +
          twoDig(data.dob.day) +
          "/" +
          data.dob.year
      ).valueOf();
      if (selectedImage) {
        const formData = new FormData();
        formData.append("files", selectedImage);

        apiManager(
          "POST",
          "storage/upload?lang=en&type=3",
          formData,
          null,
          "multipart/form-data"
        )
          .then((res) => {
            if (res.status) {
              data["profilePicture"] = res.data[0].s3URL;
              handleSaveProfile(data);
            } else {
              toast.error(res.message);
            }
          })
          .catch((err) => {
            console.error(err);
            toast.error(err.message);
          });
      } else {
        handleSaveProfile(data);
      }
    }
    // if()
    // {
    // let formData=new FormData();
    // formData.append('name',data.name);
    // formData.append('phone',data.phone);
    // formData.append('address',data.address);
    // formData.append('companyname',data.companyname);
    // formData.append('city',data.city);
    // formData.append('address',data.address);
    // formData.append('language',i18n.language);
    // formData.append('profile',selectedImage ? selectedImage : []);
    // formData.append('about',data.about);
    // formData.append('website',data.website);
    // dispatch(saveprofileForm(formData));
    // console.log('formData',formData);
    // toast.success('Form Submit successfully');
    // }
  };

  const getDropDownData = async () => {
    const apiCalls = await Promise.all([getAllGenders(), getAllCities()]);
    const [gendersData, citiesData] = [apiCalls[0], apiCalls[1]];
    if (gendersData) {
      const data = gendersData;
      for (let i = 0; i <= data.length; i++) {
        if (data?.[i]?.["name"]?.["en"] === profiledata?.gender["en"]) {
          setValue("gender", data?.[i]?.["name"]?.[lang]);
          break;
        }
      }
      setGenders(gendersData);
    }
    if (citiesData) {
      if (citiesData?.status) {
        setCitites(citiesData?.data?.map((v) => ({ name: v })));
        setValue("city", {
          label: profiledata?.city,
          value: profiledata?.city,
        });
      } else {
        setCitites([]);
      }
    }
  };

  const handleSaveProfile = async (data) => {
    const genderId = genders.filter((v) => v.name[lang] === data.gender);
    data["genderId"] = genderId?.[0]?._id;

    if (!submitting) {
      setSubmitting(true);
      const allLangAbout = await translateInTwoLanguages(lang, data.about);
      data.about = allLangAbout;

      let websiteTemp =
        data?.website?.length != 0
          ? data?.website?.startsWith?.("https")
            ? data.website
            : "https://".concat(data.website)
          : "";

      data.additionalInfo = {
        ...profiledata?.additionalInfo,
        website: websiteTemp,
        about: data.about,
      };

      if (profiledata?.email !== data.email) data.emailVerified = false;
      if (profiledata?.phoneNumber !== data.phoneNumber)
        data.phoneNumberVerified = false;
      if (profiledata?.additionalInfo?.phonenumber1 !== data?.phoneNumber1) {
        data.additionalInfo.phoneNumber1Verified = false;
        data.additionalInfo.phoneNumber1 = data?.phoneNumber1;
      }

      const checkChanged = (curr, prev) => {
        return curr !== prev && curr && curr !== "N/A";
      };
      const getSuccessPromise = () => {
        return Promise.resolve({
          status: true,
        });
      };
      const check = [];

      if (checkChanged(data?.email, profiledata?.email)) {
        check.push(
          apiManager(
            "GET",
            `general/checkAvailability?lang=${lang}&attribute=email&value=${data.email}`
          )
        );
      } else {
        check.push(getSuccessPromise());
      }
      if (checkChanged(data?.phoneNumber, profiledata?.phoneNumber)) {
        check.push(
          apiManager(
            "GET",
            `general/checkAvailability?lang=${lang}&attribute=phoneNumber&value=${data.phoneNumber}`
          )
        );
      } else {
        check.push(getSuccessPromise());
      }
      if (
        userType === "brand" &&
        checkChanged(
          data.additionalInfo.phoneNumber1,
          profiledata?.additionalInfo?.phoneNumber1
        )
      ) {
        check.push(
          apiManager(
            "GET",
            `general/checkAvailability?lang=${lang}&attribute=phoneNumber&value=${data.additionalInfo.phoneNumber1}`
          )
        );
      } else {
        check.push(getSuccessPromise());
      }

      const allApiCalls = await Promise.all(check);
      let isEmailAvailable = allApiCalls[0];
      let isPhoneAvailable = allApiCalls[1];
      let isPhone1Available = allApiCalls[2];

      if (
        isEmailAvailable?.status &&
        isPhoneAvailable?.status &&
        isPhone1Available?.status
      ) {
        apiManager("PUT", `user/profile?lang=${lang}`, data)
          .then((res) => {
            if (res.status) {
              // toast.success(res.message);
              if (!profiledata?.isWelcomeMailSent) {
                sendWelcomeMail();
              }
              navigate(paths.myProfile);
              setSubmitting(false);
            } else {
              toast.error(res.message);
              setSubmitting(false);
            }
          })
          .catch((err) => {
            console.error(err);
            toast.error("Something went wrong!");
            setSubmitting(false);
          });
      } else {
        setSubmitting(false);
        if (!isEmailAvailable?.status) {
          setError("email", {
            message: isEmailAvailable?.message || t("Something went wrong"),
          });
        }
        if (!isPhoneAvailable?.status) {
          setError("phoneNumber", {
            message: isPhoneAvailable?.message || t("Something went wrong"),
          });
        }
        if (!isPhone1Available?.status) {
          setError("phoneNumber1", {
            message: isPhone1Available?.message || t("Something went wrong"),
          });
        }
      }
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const setFinalImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageToCrop,
        croppedAreaPixels,
        rotation
      );
      const result = blobToFile(croppedImage, "cropped_image");
      // handleDownload(URL.createObjectURL(result), result.name);
      setSelectedImage(result);
      handleCloseModal();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <div class="my_profile">
        <form onSubmit={handleSubmit(onSubmitform, onError)}>
          <h3 class="pt-0">
            {t("Edit Basic Details")}{" "}
            <span class="d-none d-sm-block">
              <button
                class="save"
                style={{ width: "auto" }}
                disabled={submitting}
                type="submit"
              >
                {submitting ? t("Please wait...") : t("Save Changes")}
              </button>
              <NavLink to={paths.myProfile}>{t("Cancel")}</NavLink>
            </span>
          </h3>
          <div class="row">
            <div class="col-sm-3 col-xl-2 d-flex flex-column align-items-center ">
              <div className="position-relative">
                <div class="imgs rounded-circle" style={{ background: "none" }}>
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg, image/webp"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  {selectedImage ? (
                    <img
                      style={{ objectFit: "cover", verticalAlign: "unset" }}
                      class="w-100"
                      src={URL.createObjectURL(selectedImage)}
                      align="img"
                    />
                  ) : profiledata?.profilePicture &&
                    profiledata?.profilePicture != "N/A" ? (
                    <img
                      style={{ objectFit: "cover", verticalAlign: "unset" }}
                      class="w-100"
                      src={profiledata?.profilePicture}
                      align="img"
                    />
                  ) : (
                    <img
                      style={{ objectFit: "cover", verticalAlign: "unset" }}
                      class="w-100 bg-light"
                      src="/assets/images/ap2.png"
                      align="img"
                    />
                  )}
                </div>
                <span
                  className="d-flex justify-content-center align-items-center bg-light rounded-circle border"
                  style={{
                    height: "30px",
                    width: "30px",
                    position: "absolute",
                    top: "70px",
                    [lang === "en" ? "right" : "left"]: "0px",
                  }}
                >
                  <img
                    src="../assets/images/cmra.png"
                    height={12}
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      document.getElementById("fileInput").click();
                    }}
                  />
                </span>
              </div>
            </div>
            <div class="col-sm-9 col-xl-10">
              <div class="row">
                <div class="form-group col-sm-6 ">
                  <span style={{ position: "relative" }}>
                    <h6>
                      {userType === "brand" ? t("Managers name") : t("Name")}*
                    </h6>
                    <FormInput
                      style={"form-control"}
                      intype={"text"}
                      event={register}
                      value={profiledata?.fullName}
                      name={"fullName"}
                      placeholder={t("Full Name")}
                    />
                    {errors.fullName && (
                      <p
                        className={`helperText w-100 text-${
                          lang === "en" ? "start" : "end"
                        }`}
                      >
                        {t(errors.fullName.message)}
                      </p>
                    )}
                  </span>
                </div>

                {/* for dealer */}
                {majorUserTypes.includes(userType) ? (
                  <div class="form-group col-sm-6 col-lg-6 ">
                    <span style={{ position: "relative" }}>
                      {userType === "dealer" && (
                        <h6>{t("Dealership Name")}*</h6>
                      )}
                      {userType === "brand" && <h6>{t("Brand Name")}*</h6>}
                      <FormInput
                        style={"form-control"}
                        intype={"text"}
                        event={register}
                        // value={profiledata.companyname[i18n.language]}
                        name={"dealerShipName"}
                        placeholder={t("Dealership Name")}
                      />
                      {errors.dealerShipName && (
                        <p
                          className={`helperText w-100 text-${
                            lang === "en" ? "start" : "end"
                          }`}
                        >
                          {t(errors.dealerShipName.message)}
                        </p>
                      )}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {/* end */}
                <div class="form-group col-sm-6  form-group">
                  <span style={{ position: "relative" }}>
                    <h6>{t("Gender")}</h6>
                    <SelectForm
                      style={"form-control"}
                      event={register}
                      name={"gender"}
                      selectedOption={profiledata?.gender?.[lang] || ""}
                      values={genders}
                    />
                    {errors.gender && (
                      <p
                        className={`helperText w-100 text-${
                          lang === "en" ? "start" : "end"
                        }`}
                      >
                        {t(errors.gender.message)}
                      </p>
                    )}
                  </span>
                </div>

                {/* for dealer */}
                {majorUserTypes.includes(userType) ? (
                  <div class="form-group col-sm-6 col-lg-6 ">
                    <span style={{ position: "relative" }}>
                      <h6>{t("Website")}</h6>
                      <FormInput
                        style={"form-control"}
                        intype={"text"}
                        event={register}
                        value={profiledata?.website}
                        name={"website"}
                        placeholder={t("Website")}
                      />
                      {errors.website && (
                        <p
                          className={`helperText w-100 text-${
                            lang === "en" ? "start" : "end"
                          }`}
                        >
                          {t(errors.website.message)}
                        </p>
                      )}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {/* end */}

                <div class="form-group col-sm-6 col-lg-6 ">
                  <span style={{ position: "relative" }}>
                    <h6>{t("Email")}</h6>

                    <FormInput
                      style={"form-control"}
                      intype={"email"}
                      event={register}
                      value={profiledata?.email}
                      name={"email"}
                      placeholder={t("Email")}
                    />
                    {errors.email && (
                      <p
                        className={`helperText w-100 text-${
                          lang === "en" ? "start" : "end"
                        }`}
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {t(errors.email.message)}
                      </p>
                    )}
                  </span>
                </div>
                <div
                  class="col-sm-6 col-lg-6 form-group mb-2"
                  style={{ position: "relative" }}
                >
                  <h6>{t("Phone")}*</h6>
                  <CustomPhoneInput
                    event={register}
                    name={"phoneNumber"}
                    setValue={setValue}
                    getValues={getValues}
                  />
                  {errors.phoneNumber ? (
                    <p
                      style={{
                        top: "68px",
                        left: lang === "en" ? "12px" : "-12px",
                      }}
                      className={`helperText w-100 text-${
                        lang === "en" ? "start" : "end"
                      }`}
                    >
                      {t(errors.phoneNumber.message)}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                {userType === "brand" && (
                  <div
                    class="col-sm-6 col-lg-6 form-group mb-2"
                    style={{ position: "relative" }}
                  >
                    <h6>{t("Brand phone")}</h6>
                    <CustomPhoneInput
                      event={register}
                      name={"phoneNumber1"}
                      setValue={setValue}
                      getValues={getValues}
                    />
                    {errors.phoneNumber1 ? (
                      <p
                        style={{
                          top: "68px",
                          left: lang === "en" ? "12px" : "-12px",
                        }}
                        className={`helperText w-100 text-${
                          lang === "en" ? "start" : "end"
                        }`}
                      >
                        {t(errors.phoneNumber1.message)}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                )}

                <div class="form-group col-sm-6 col-lg-6 ">
                  <span style={{ position: "relative" }}>
                    <h6>{t("City National")}*</h6>
                    {/* <SelectForm
                      style={"form-control"}
                      event={register}
                      name={"city"}
                      selectedOption={cityByMap}
                      values={cities}
                    /> */}
                    <Controller
                      control={control}
                      name="city"
                      render={({ field }) => {
                        return (
                          <ReactSelectForm
                            selectedOption={t("Select")}
                            value={field.value}
                            items={cities?.map((v) => ({
                              label: v?.name?.en,
                              value: v?.name?.en,
                            }))}
                            onChange={(op) => {
                              setValue("city", op);
                            }}
                            showDropdownIcon
                            width={"100%"}
                            placeholder={t("Select")}
                          />
                        );
                      }}
                    />
                    {errors.city && (
                      <p
                        className={`helperText w-100 text-${
                          lang === "en" ? "start" : "end"
                        }`}
                      >
                        {t(errors.city.message)}
                      </p>
                    )}
                  </span>
                </div>
                <div class="form-group d-flex flex-column  col-sm-6 col-lg-6">
                  <h6>{t("Date of birth")}</h6>
                  <Controller
                    control={control}
                    name="dob"
                    render={({ field }) => (
                      <CustomDatepicker
                        onChange={(date) => field.onChange(date)}
                        value={field.value}
                        placeholder={t("Select")}
                        maxDate={utils().getToday()}
                        inputClassName={`form-control text-${
                          lang === "en" ? "start" : "end"
                        }`}
                      />
                    )}
                  />
                  {errors.dob && (
                    <p className="helperText" style={{ bottom: "-1px" }}>
                      {t(errors.dob.message)}
                    </p>
                  )}
                </div>
                <div
                  class="col-xl-12 form-group"
                  onClick={() =>
                    userType === "dealer"
                      ? openModal({
                          type: "address",
                          title: t("Point the map to your location"),
                        })
                      : null
                  }
                >
                  <span style={{ position: "relative" }}>
                    <h6>{t("Address")}*</h6>
                    <FormInput
                      style={"form-control"}
                      intype={"textarea"}
                      event={register}
                      name={"address"}
                      placeholder={t("Address")}
                      value={profiledata?.address}
                    />
                    {errors.address && (
                      <p
                        className={`helperText w-100 text-${
                          lang === "en" ? "start" : "end"
                        }`}
                      >
                        {t(errors.address.message)}
                      </p>
                    )}
                  </span>
                </div>

                {/* for dealer */}
                {majorUserTypes.includes(userType) ? (
                  <div class="col-sm-12 form-group">
                    <span style={{ position: "relative" }}>
                      {userType === "dealer" && <h6>{t("About")}</h6>}
                      {userType === "brand" && <h6>{t("About your brand")}</h6>}
                      <TextAreaForm
                        event={register}
                        name={"about"}
                        style={"form-control"}
                        value={profiledata?.website}
                        placeholder={
                          userType === "dealer"
                            ? t("About")
                            : t("About your brand")
                        }
                      />
                      {errors.about && (
                        <p
                          className={`helperText w-100 text-${
                            lang === "en" ? "start" : "end"
                          }`}
                        >
                          {t(errors.about.message)}
                        </p>
                      )}
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {/* end */}
              </div>
            </div>
            <div class="col-12 d-block d-sm-none albtn">
              <button class="save my-2" disabled={submitting} type="submit">
                {submitting ? t("Please wait...") : t("Save Changes")}
              </button>
              <NavLink className=" my-2" to={paths.myProfile}>
                {t("Cancel")}
              </NavLink>
              {/* <button type="submit">{t('Save')}</button>
        <button type="button" class="w-100 mb-34" >{t('Cancel')}</button> */}
            </div>
          </div>
        </form>
      </div>
      <Modal isOpen={modal} size="lg" centered toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>{modalData?.title}</ModalHeader>
        <ModalBody>
          {modalData?.type === "address" && (
            <GoogleMapAddress
              handleCloseModal={handleCloseModal}
              setValue={setValue}
              setCityByMap={setCityByMap}
            />
          )}
          {modalData?.type === "crop" && (
            <>
              <>
                {imageToCrop && (
                  <>
                    <div className="w-100" style={{ height: "500px" }}>
                      <Cropper
                        image={imageToCrop}
                        crop={crop}
                        rotation={rotation}
                        showGrid={false}
                        cropShape="round"
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                      />
                    </div>
                  </>
                )}
              </>
              {/* <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                circularCrop
                aspect={1}
              >
                <img src={imageToCrop ? imageToCrop : ""} />
              </ReactCrop>
              <div className="my-1">
                <Button
                  style="fs-6 float-end btn btn-primary"
                  onClick={() => setFinalImage()}
                  title={t("Submit")}
                  disabled={!crop}
                />
              </div> */}
            </>
          )}
        </ModalBody>
        {modalData?.type === "crop" && (
          <>
            <ModalFooter className="d-block">
              <div className="row">
                <div className="mb-3">
                  <p className="fs-5 ">{t("Zoom")}</p>
                  <Slider
                    reverse={lang === "en" ? false : true}
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(zoom) => setZoom(zoom)}
                  />
                </div>
                <div className="mb-3">
                  <p className="fs-5 ">{t("Rotate")}</p>
                  <Slider
                    reverse={lang === "en" ? false : true}
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="Rotation"
                    onChange={(rotation) => setRotation(rotation)}
                  />
                </div>
              </div>
              <div className="my-1">
                <Button
                  style="fs-6 float-end themeButton border"
                  onClick={() => setFinalImage()}
                  title={t("Submit")}
                  disabled={!crop}
                />
              </div>
            </ModalFooter>
          </>
        )}
      </Modal>
    </div>
  );
}

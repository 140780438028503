import { useSelector } from "react-redux";
import { GuideToSellData } from "./data";
import InfoCardsCarousel from "../../Tools/InfoCardsCarousel";
import FourCardCarousel from "../../Tools/FourCardCarousel";

export const GuideToSell = () => {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const data = GuideToSellData?.[lang] || [];
  const title = GuideToSellData?.title?.[lang] || [];

  return <FourCardCarousel title={title} data={data} />;
};

export const WhySellOnAko = () => {
  return <div>wow</div>;
};

import { t } from "i18next";
import React, { useEffect } from "react";
import { cities } from "../../../../data/content";
import { useSelector } from "react-redux";
import SelectForm from "../../SelectForm";
import FormInput from "../../FormInput";
import SellPrice from "../SellPrice";
import RentPrice from "../RentPrice";
import Media from "../../Media";
import { useDidMountEffect } from "../../../../utils/hooks";
import ReactSelectForm from "../../ReactSelectForm";
import { isFieldRequired } from "./addFormUtils";

export default function Price({
  data,
  register,
  errors,
  page,
  back,
  inputFile,
  setInputFile,
  InputFileError,
  setInputFileError,
  uploadsInOrder,
  setUploadsInOrder,
  uploading,
  setUploading,
  uploadedFiles,
  setUploadedFiles,
  watch,
  setValue,
}) {
  const upload_list = useSelector((state) => state.withoutVin.withoutVin);
  const language = useSelector((state) => state.auth.language);
  const lang2 =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  const exchangeRate = JSON.parse(
    localStorage.getItem("global")
  )?.exchangeRateDollarToIqd;

  useDidMountEffect(() => {
    let dollorPrice = watch("dollorPrice");
    dollorPrice = dollorPrice == 0 ? "" : dollorPrice;
    if (exchangeRate) {
      if (dollorPrice) {
        setValue(
          "asking_price",
          parseInt(dollorPrice * exchangeRate).toString()
        );
      } else {
        setValue("asking_price", "");
      }
    }
  }, [watch("dollorPrice")]);

  useDidMountEffect(() => {
    let dollorPrice = watch("dollorDaily");
    dollorPrice = dollorPrice == 0 ? "" : dollorPrice;
    if (exchangeRate) {
      if (dollorPrice) {
        setValue(
          "daily_price",
          parseInt(dollorPrice * exchangeRate).toString()
        );
      } else {
        setValue("daily_price", "");
      }
    }
  }, [watch("dollorDaily")]);

  useDidMountEffect(() => {
    let dollorPrice = watch("dollorWeekly");
    dollorPrice = dollorPrice == 0 ? "" : dollorPrice;
    if (exchangeRate) {
      if (dollorPrice) {
        setValue(
          "weekly_price",
          parseInt(dollorPrice * exchangeRate).toString()
        );
      } else {
        setValue("weekly_price", "");
      }
    }
  }, [watch("dollorWeekly")]);

  useDidMountEffect(() => {
    let dollorPrice = watch("dollorMonthly");
    dollorPrice = dollorPrice == 0 ? "" : dollorPrice;
    if (exchangeRate) {
      if (dollorPrice) {
        setValue(
          "monthly_price",
          parseInt(dollorPrice * exchangeRate).toString()
        );
      } else {
        setValue("monthly_price", "");
      }
    }
  }, [watch("dollorMonthly")]);

  return (
    <>
      {isFieldRequired("plateCityPlateNumber", page) && (
        <div className="other_div other_div2">
          <div class="col-lg-12">
            <div class="form-group">
              <h5>{t("Plate city")}</h5>
              <ReactSelectForm
                selectedOption={watch("cityPlate")}
                items={upload_list?.plateCities?.map((v) => ({
                  label: v?.name?.en,
                  value: v?.name?.en,
                }))}
                onChange={(op) => setValue("cityPlate", op?.value)}
                showDropdownIcon
                width={"100%"}
              />
              {errors.cityPlate && <p>{t(errors.cityPlate.message)}</p>}
            </div>
          </div>
          <div class="col-lg-12 mt-4">
            <div class="form-group">
              <h5>{t("Plate type")}</h5>
              <SelectForm
                style={"form-control"}
                event={register}
                name={"plateType"}
                selectedOption={data?.properties?.plateType?.[lang2]}
                values={upload_list?.plateTypes || []}
              />
              {errors.plateType && <p>{t(errors.plateType.message)}</p>}
            </div>
          </div>
          <div className="d-lg-flex">
            <div class="col-lg-12  mt-4">
              <div class="form-group">
                <h5>{t("Plate Number")}</h5>
                <FormInput
                  style={"form-control"}
                  value={data?.properties?.registrationNumber}
                  event={register}
                  name={"registrationNumber"}
                />
                {errors?.registrationNumber && (
                  <p>{errors?.registrationNumber.message}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!back && (
        <Media
          setInputFileError={setInputFileError}
          inputFile={inputFile}
          register={register}
          InputFileError={InputFileError}
          uploadsInOrder={uploadsInOrder}
          setUploadsInOrder={setUploadsInOrder}
          setInputFile={setInputFile}
          // handleUploadDocument={handleUploadDocument}
          uploading={uploading}
          setUploading={setUploading}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          storedList={data?.uploads}
          type={6}
          adId={data?._id}
          adData={data}
          hasOrder
        />
      )}
      {(page == "SellYourCar" || page == "Editvehicle") && (
        <SellPrice
          register={register}
          errors={errors}
          finance={upload_list.finance ? upload_list.finance : []}
          data={data}
          language={language}
          setValue={setValue}
        />
      )}
      {page == "RentYourCar" && (
        <RentPrice
          register={register}
          errors={errors}
          data={data}
          back={back}
          setValue={setValue}
        />
      )}
    </>
  );
}

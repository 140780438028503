export const page_titles = {
  home: {
    English: "New and used cars for sale and rent in Iraq - Ako Sayara",
    Arabic: "سيارات جديدة ومستعملة للبيع والايجار في العراق - اكو سيارة",
    Kurdish: "ئۆتۆمبێلی نوێ و بەکارهاتوو بۆ فرۆشتن و کرێ لە عێراق -ئەکو سەیارە",
  },
  rentCar: {
    English: "Find your perfect rental in Iraq - Ako Sayara",
    Arabic: "ابحث عن الإيجار المثالي في العراق - اكو سيارة",
    Kurdish: "کرێی نموونەی بدۆزەرەوە لە عێراق -ئەکو سەیارە",
  },
  checkYourCars: {
    English: "Check your car in Iraq - Ako Sayara",
    Arabic: "فحص سيارتك في العراق - اكو سيارة",
    Kurdish: " لە عێراق  ئۆتۆمبێلەکەت بپشکنە -ئەکو سەیارە",
  },
  sellYourCar: {
    English: "Sell your car in Iraq - Ako Sayara",
    Arabic: "بيع سيارتك في العراق - اكو سيارة",
    Kurdish: "لە عێراق ئۆتۆمبێلەکەت بفرۆشە -ئەکو سەیارە",
  },
  rentYourCar: {
    English: "Rent your car In Iraq - Ako Sayara",
    Arabic: "تأجير سيارتك في العراق - اكو سيارة",
    Kurdish: "ئۆتۆمبێلەکەت لە عێراق بەکرێ بدە -ئەکو سەیارە",
  },
  sellYourCarPage: {
    English: "Sell your car in Iraq - Ako Sayara",
    Arabic: "بيع سيارتك في العراق - اكو سيارة",
    Kurdish: "لە عێراق ئۆتۆمبێلەکەت بفرۆشە -ئەکو سەیارە",
  },
  rentYourCarPage: {
    English: "Rent your car In Iraq - Ako Sayara",
    Arabic: "تأجير سيارتك في العراق - اكو سيارة",
    Kurdish: "ئۆتۆمبێلەکەت لە عێراق بەکرێ بدە -ئەکو سەیارە",
  },
  aboutSayara: {
    English: "About us - Ako Sayara",
    Arabic: "معلومات عنا - اكو سيارة",
    Kurdish: "دەربارەی ئێمە - ئەکو سەیارە",
  },
  contact: {
    English: "Contact us - Ako Sayara",
    Arabic: "اتصل بنا - اكو سيارة",
    Kurdish: "پەیوەندیمان پێوەبکە ـ ئەکو سەیارە",
  },
  closeAccount: {
    English: "Close my account - Ako Sayara",
    Arabic: "اغلاق حسابي - اكو سيارة",
    Kurdish: "داخستنی هەژمارەکەم ـ ئەکو سەیارە",
  },
  careers: {
    English: "Careers - Ako Sayara",
    Arabic: "الوظائف - اكو سيارة",
    Kurdish: "هەلی کارەکان ـ ئەکو سەیارە",
  },
  policies: {
    English: "Privacy policy  - Ako Sayara",
    Arabic: "سياسة الخصوصية - اكو سيارة",
    Kurdish: "سیاسەتی پاراستنی نهێنی -ئەکو سەیارە",
  },
  conditions: {
    English: "Terms and conditions  - Ako Sayara",
    Arabic: "الشروط والأحكام - اكو سيارة",
    Kurdish: "مەرج و ڕێساکان - ئەکو سەیارە",
  },
  cookies: {
    English: "Cookies  - Ako Sayara",
    Arabic: "ملفات تعريف الارتباط - اكو سيارة",
    Kurdish: "کوکیز -ئەکو سەیارە",
  },
  newCarDeals: {
    English: "New car deals in Iraq - Ako Sayara",
    Arabic: "عروض السيارات الجديدة في العراق - اكو سيارة",
    Kurdish: "ئۆفەری ئۆتۆمبێلی نوێ لە عێراق -ئەکو سەیارە",
  },
  classicCars: {
    English: "Classic cars deals in Iraq - Ako Sayara",
    Arabic: "عروض السيارات الكلاسيكية في العراق - اكو سيارة",
    Kurdish: "گرێبەستی ئۆتۆمبێلی کلاسیک لە عێراق -ئەکو سەیارە",
  },
  electricCars: {
    English: "Electric car deals in Iraq - Ako Sayara",
    Arabic: "عروض السيارات الكهربائية في العراق - اكو سيارة",
    Kurdish: "گرێبەستی ئۆتۆمبێلی کارەبایی لە عێراق - ئەکو سەیارە",
  },
  rentPerfectCars: {
    English: "Rent your perfect car in Iraq - Ako Sayara",
    Arabic: "استأجر سيارتك المثالية في العراق - اكو سيارة",
    Kurdish: "ئۆتۆمبێلە نمونەییەکەت بە کرێ بگرە لە عێراق ـ ئەکو سەیارە",
  },
  articles: {
    English: "Articles and news - Ako Sayara",
    Arabic: "مقالات وأخبار - اكو سيارة",
    Kurdish: "بابەت و هەواڵ -ئەکو سەیارە",
  },
  advancedSearch: {
    English: "Advanced search - Ako Sayara",
    Arabic: "بحث متقدم - اكو سيارة",
    Kurdish: "گەڕانی پێشکەوتوو - ئەکو سەیارە",
  },
  searchResult: {
    English: "Search results - Ako Sayara",
    Arabic: "نتائج البحث - اكو سيارة",
    Kurdish: "ئەنجامی گەڕان -ئەکو سەیارە",
  },
  buyCar: {
    English: "Buy a car in Iraq -  Ako Sayara",
    Arabic: "شراء سيارة في العراق - اكو سيارة",
    Kurdish: "ئۆتۆمبێلێک لە عێراق بکڕە - ئەکو سەیارە",
  },
  rentalSearchResult: {
    English: "Rent a car - Ako Sayara",
    Arabic: "تأجير سيارة - اكو سيارة",
    Kurdish: "بەکرێدانی ئۆتۆمبێل -ئەکو سەیارە",
  },
  dealerRental: {
    English: "Dealer rentals - Ako Sayara",
    Arabic: "سيارات للأيجار - اكو سيارة",
    Kurdish: "ئۆتۆمبێل بۆ کرێ - ئەکو سەیارە",
  },
  dealerCarView: {
    English: "Dealer car view - Ako Sayara",
    Arabic: "عرض سيارة التاجر - اكو سيارة",
    Kurdish: "پیشاندانی ئۆتۆمبێلی بازرگانەکە ـ ئەکو سەیارە",
  },
  undefined: {},
  dealerSearchResults: {
    English: "Dealer search result - Ako Sayara",
    Arabic: "نتيجة البحث عن تاجر- اكو سيارة",
    Kurdish: "ئەنجامی گەڕان بۆ بریکارێک -ئەکو سەیارە",
  },
  enquiry: {
    English: "Enquire now - Ako Sayara",
    Arabic: "استفسر الآن - اكو سيارة",
    Kurdish: "ئێستا پرسیار بکە - ئەکو سەیارە",
  },
  whyAkoSayara: {
    English: "Why Ako Sayara - My account - Ako Sayara",
    Arabic: "لماذا أكو سايارا - حسابي - اكو سيارة",
    Kurdish: "بۆچیئەکو سەیارە - ئەکاونتەکەم - ئەکو سەیارە",
  },
  dealershipDocument: {
    English: "Dealership documents - Ako Sayara",
    Arabic: "مستندات الوكالة - اكو سيارة",
    Kurdish: "بەڵگەنامەکانی دەزگا - ئەکو سەیارە",
  },
  myAccount: {
    English: "My account - Ako Sayara",
    Arabic: "حسابي - اكو سيارة",
    Kurdish: "ئەکاونتەکەم - ئەکو سەیارە",
  },
  myVehicles: {
    English: "Vehicles - My account - Ako Sayara",
    Arabic: "السيارات - حسابي - اكو سيارة",
    Kurdish: "ئۆتۆمبێل - ئەکاونتەکەم -ئەکو سەیارە",
  },
  myTermsConditions: {
    English: "Terms & Conditions - My account - Ako Sayara",
    Arabic: "الشروط والأحكام - حسابي - اكو سيارة",
    Kurdish: "مەرج و ڕێساکان - ئەکاونتەکەم -ئەکو سەیارە",
  },
  myPrivacyPolicies: {
    English: "Privacy policy - My account - Ako Sayara",
    Arabic: "سياسة الخصوصية - حسابي - اكو سيارة",
    Kurdish: "سیاسەتی پاراستنی نهێنی -  ئەکاونتەکەم  -ئەکو سەیارە",
  },
  myContactUs: {
    English: "Contact us - My account - Ako Sayara",
    Arabic: "اتصل بنا - حسابي - اكو سيارة",
    Kurdish: "پەیوەندیمان پێوە بکەن -ئەکاونتەکەم  -ئەکو سەیارە",
  },
  myCloseAccount: {
    English: "Close my account - My account Ako Sayara",
    Arabic: "إغلاق حسابي - حسابي - اكو سيارة",
    Kurdish: "ئەکاونتەکەم دابخە - ئەکاونتەکەم -ئەکو سەیارە",
  },
  editCar: {
    English: "Edit car - My account - Ako Sayara",
    Arabic: "تعديل السيارة - حسابي - اكو سيارة",
    Kurdish: "دەستکاریکردنی ئۆتۆمبێل - ئەکاونتەکەم - ئەکو سەیارە",
  },
  editProfile: {
    English: "Edit profile - My account - Ako Sayara",
    Arabic: "تعديل الملف الشخصي - حسابي - اكو سيارة",
    Kurdish: "دەستکاری پڕۆفایل - ئەکاونتەکەم - ئەکو سەیارە",
  },
  security: {
    English: "Security - My account - Ako Sayara",
    Arabic: "الأمان - حسابي - اكو سيارة",
    Kurdish: "ئاسایش - ئەکاونتەکەم - ئەکو سەیارە",
  },
  editSecurity: {
    English: "Edit security - My account - Ako Sayara",
    Arabic: "تعديل الأمان - حسابي - اكو سيارة",
    Kurdish: "دەستکاریکردنی ئاسایش - ئەکاونتەکەم -ئەکو سەیارە",
  },
  transactions: {
    English: "Transactions - My account - Ako Sayara",
    Arabic: "المعاملات - حسابي - اكو سيارة",
    Kurdish: "مامەڵەکان - ئەکاونتەکەم -ئەکو سەیارە",
  },
  messages: {
    English: "Messages - My account - Ako Sayara",
    Arabic: "الرسائل - حسابي - اكو سيارة",
    Kurdish: "نامەەکان - ئەکاونتەکەم -ئەکو سەیارە",
  },
  perfomance: {
    English: "Performance - My account - Ako Sayara",
    Arabic: "الأداء - حسابي - اكو سيارة",
    Kurdish: "ئەدا - ئەکاونتەکەم - ئەکو سەیارە",
  },
  myProfile: {
    English: "My profile - My account - Ako Sayara",
    Arabic: "الملف الشخصي الخاص بي - حسابي - اكو سيارة",
    Kurdish: "پڕۆفایلی من - ئەکاونتەکەم -ئەکو سەیارە",
  },
  editWorkHour: {
    English: "Edit work hours - My account - Ako Sayara",
    Arabic: "تعديل ساعات العمل - حسابي - اكو سيارة",
    Kurdish: "دەستکاریکردنی کاتی دەوام - ئەکاونتەکەم - ئەکو سەیارە",
  },
  myCookies: {
    English: "Cookies - My account - Ako Sayara",
    Arabic: "ملفات تعريف الارتباط - حسابي - اكو سيارة",
    Kurdish: "کوکیز - ئەکاونتەکەم  - ئەکو سەیارە",
  },
  login: {
    English: "Login - Ako Sayara",
    Arabic: "تسجيل الدخول - اكو سيارة",
    Kurdish: "چوونەژوورەوە -ئەکو سەیارە",
  },
  forgotPassword: {
    English: "Forgot password? - Ako Sayara",
    Arabic: "هل نسيت كلمة السر؟ - اكو سيارة",
    Kurdish: "پاسۆردەکەت لەبیر چووە؟ ئەکو سەیارە",
  },
  enterOTP: {
    English: "Forgot password? - Ako Sayara",
    Arabic: "هل نسيت كلمة السر؟ - اكو سيارة",
    Kurdish: "پاسۆردەکەت لەبیر چووە؟ ئەکو سەیارە",
  },
  signup: {
    English: "Sign up - Ako Sayara",
    Arabic: "قم بالتسجيل - اكو سيارة",
    Kurdish: "تۆمارکردن - ئەکو سەیارە",
  },
  basicDetails: {
    English: "Basic details - Ako Sayara",
    Arabic: "التفاصيل الأساسية - اكو سيارة",
    Kurdish: "وردەکارییە بنەڕەتییەکان -ئەکو سەیارە",
  },
  saved: {
    English: "Saved - Ako Sayara",
    Arabic: "المحفوظة - اكو سيارة",
    Kurdish: "پاشەکەوت کراوە -ئەکو سەیارە",
  },
  comparing: {
    English: "Comparing - Ako Sayara",
    Arabic: "مقارنة - اكو سيارة",
    Kurdish: "بەراورد بکە - ئەکو سەیارە",
  },
  viewImage: {
    English: "View image - Ako Sayara",
    Arabic: "عرض الصورة - اكو سيارة",
    Kurdish: "بینینی وێنە -ئەکو سەیارە",
  },
  plan: {
    English: "Ad plan - Ako Sayara",
    Arabic: "نوع الإعلان - اكو سيارة",
    Kurdish: "جۆری ڕێکلام -ئەکو سەیارە",
  },
  renew: {
    English: "Renew / Upgrade - Ako Sayara",
    Arabic: "تجديد / تحديث - اكو سيارة",
    Kurdish: "نوێکردنەوە-ئەکو سەیارە",
  },
  payment: {
    English: "Payment - Ako Sayara",
    Arabic: "الدفع - اكو سيارة",
    Kurdish: "پارەدان -ئەکو سەیارە",
  },
  viewMap: {
    English: "View on map - Ako Sayara",
    Arabic: "عرض على الخريطة - اكو سيارة",
    Kurdish: "لەسەر نەخشە پیشان بدە - ئەکو سەیارە",
  },
  cheapCars: {
    English: "Cheap cars - Ako Sayara",
    Arabic: "سيارات رخيصة - اكو سيارة",
    Kurdish: "ئۆتۆمبێلی هەرزان -ئەکو سەیارە",
  },
  singleCar: {
    English: "Single car - Ako Sayara",
    Arabic: "سيارة واحدة - اكو سيارة",
    Kurdish: "یەک ئۆتۆمبێل - ئەکو سەیارە",
  },
  singleRental: {
    English: "Single car - Ako Sayara",
    Arabic: "سيارة - اكو سيارة",
    Kurdish: "ئۆتۆمبێل -ئەکو سەیارە",
  },
  search: {
    English: "Search - Ako Sayara",
    Arabic: "بحث - اكو سيارة",
    Kurdish: "گەڕان -ئەکو سەیارە",
  },
  rentalSearch: {
    English: "Rental search - Ako Sayara",
    Arabic: "البحث عن سيارات للإيجار - اكو سيارة",
    Kurdish: "گەڕان بەدوای ئۆتۆمبێل بۆ کرێ -ئەکو سەیارە",
  },
  singleCarCheck: {
    English: "Single car check - Ako Sayara",
    Arabic: "التحقق من السيارة - اكو سيارة",
    Kurdish: "ئۆتۆمبێلەکە بپشکنە -ئەکو سەیارە",
  },
  singleBrand: {
    English: "Brands in Iraq - Ako Sayara",
    Arabic: "شركات في العراق - اكو سيارة",
    Kurdish: "کۆمپانیاکان لە عێراق - ئەکو سەیارە",
  },
  brandSearchResults: {
    English: "Find cars by brand in Iraq - Ako Sayara",
    Arabic: "البحث عن السيارات حسب الشركة في العراق - اكو سيارة",
    Kurdish: "گەڕان بەدوای ئۆتۆمبێل بەپێی کۆمپانیا لە عێراق -ئەکو سەیارە",
  },
  faqPage: {
    English: "Frequently ask questions - Ako Sayara",
    Arabic: "الأسئلة الشائعة - اكو سيارة",
    Kurdish: "پرسیارە باوەکان - ئەکو سەیارە",
  },
  usedCarDeals: {
    English: "Used car deals in Iraq - Ako Sayara",
    Arabic: "عروض السيارات المستعملة في العراق - اكو سيارة",
    Kurdish: "ئۆفەری ئۆتۆمبێلی بەکارهاتوو لە عێراق -ئەکو سەیارە",
  },
};

export const guide_titles = {
  1: {
    English: "Buying guide",
    Arabic: "دليل الشراء",
    Kurdish: "ڕێنمایی کڕین",
  },
  2: {
    English: "Selling guide",
    Arabic: "دليل البيع",
    Kurdish: "ڕێنمایی فرۆشتن",
  },
  3: {
    English: "Renting guide",
    Arabic: "دليل التأجير",
    Kurdish: "ڕێنمایی كڕێ",
  },
  4: {
    English: "Rent your car guide",
    Arabic: "دليل تأجير السيارات",
    Kurdish: "ڕێنمایی کرێی ئۆتۆمبێل",
  },
};

export const meta_des_all = {
  home: {
    English:
      "Ako Sayara is Iraq's newest platform for buying, selling, and renting cars. Discover a wide range of vehicles, from economy to luxury models, with seamless transactions and reliable customer service.",
    Arabic:
      "اكو سيارة هي أحدث منصة في العراق لبيع وشراء وتأجير السيارات. اكتشف مجموعة واسعة من السيارات الاقتصادية إلى الفاخرة، مع معاملات سلسة وخدمة عملاء موثوقة.",
    Kurdish:
      "ئەکو سەیارە نوێترین پلاتفۆرمی عێراقە بۆ کڕین و فرۆشتن و بەکرێدانی ئۆتۆمبێل. کۆمەڵێک ئابووری تا ئۆتۆمبێلی لوکس بدۆزەرەوە، لەگەڵ مامەڵەی بێ کێشە و خزمەتگوزاری متمانەپێکراو بۆ کڕیاران.",
  },
  rentCar: {
    English:
      "Find your perfect rental in Iraq with our easy to use platform. Explore a wide range of vehicles to suit your needs and budget. Book online for convenient, reliable, and affordable car rentals today!",
    Arabic:
      "ابحث عن سيارتك المثالية للإيجار في العراق من خلال منصتنا السهلة الاستخدام. استكشف مجموعة واسعة من المركبات التي تناسب احتياجاتك وميزانيتك. احجز عبر الإنترنت اليوم لتأجير سيارات مريحة وموثوقة وبأسعار معقولة!",
    Kurdish:
      "ئۆتۆمبێلێکی نمونەیەکەت لە عێراق بۆ کرێ بدۆزەرەوە لەگەڵ پلاتفۆرمی ئاسان بۆ بەکارهێنانمان. بەدوای کۆمەڵێک ئۆتۆمبێلدا بگەڕێ کە لەگەڵ پێداویستی و بودجەی خۆتدا بگونجێت. ئەمڕۆ بە شێوەی ئۆنلاین حجز بکە بۆ کرێی ئۆتۆمبێل بە ئاسوودە و متمانەپێکراو و گونجاو!",
  },
  checkYourCars: {
    English:
      "Check your car's history and details with our VIN number lookup service in Iraq. Get comprehensive reports on accidents, mileage and more to make informed decisions before buying or selling.",
    Arabic:
      "تحقق من تاريخ سيارتك وتفاصيلها من خلال خدمة البحث عن رقم شاصي السيارة (VIN) في العراق. احصل على تقارير شاملة عن الحوادث والمسافة المقطوعة والمزيد لاتخاذ قرارات مستنيرة قبل الشراء أو البيع.",
    Kurdish:
      "لە ڕێگەی خزمەتگوزاری گەڕانی ژمارەی شاسی ئۆتۆمبێل (VIN) لە عێراق مێژوو و وردەکاری ئۆتۆمبێلەکەت بزانە. ڕاپۆرتی گشتگیر لەسەر ڕووداوەکان، مایل و زۆر شتی تر بەدەست بهێنە بۆ ئەوەی پێش کڕین یان فرۆشتن بڕیاری ئاگادارانە بدەیت.",
  },
  sellYourCar: {
    English:
      "Sell your car quickly and easily in Iraq with our trusted platform. Connect with potential buyers, set your price hassle-free. Start selling your car today!",
    Arabic:
      "بيع سيارتك بسرعة وسهولة في العراق من خلال منصتنا الموثوقة. تواصل مع المشترين المحتملين، وحدد سعر سيارتك دون أي متاعب. ابدأ ببيع سيارتك اليوم!",
    Kurdish:
      "ئۆتۆمبێلەکەت بە خێرایی و بە ئاسانی لە عێراق بفرۆشە لە ڕێگەی پلاتفۆرمی متمانەپێکراوەکەمانەوە. پەیوەندی لەگەڵ کڕیارە ئەگەرییەکان بکە و نرخی ئۆتۆمبێلەکەت بەبێ هیچ کێشەیەک. ئەمڕۆ دەست بکە بە فرۆشتنی ئۆتۆمبێلەکەت!",
  },
  rentYourCar: {
    English:
      "Sell your car quickly and easily in Iraq with our trusted platform. Connect with potential buyers, set your price hassle-free. Start selling your car today!",
    Arabic:
      "قم بتأجير سيارتك في العراق بكل سهولة من خلال منصتنا الآمنة. احصل على دخل إضافي من خلال إدراج سيارتك وتحديد شروطك والتواصل مع مستأجرين موثوقين. ابدأ بتأجير سيارتك اليوم!",
    Kurdish:
      "ئۆتۆمبێلەکەت لە عێراق بە کرێ بگرە بە ئاسانی لە ڕێگەی پلاتفۆرمە پارێزراوەکەمانەوە. داهاتی زیادە بەدەستبهێنە بە لیستکردنی ئۆتۆمبێلەکەت و دانانی مەرجەکانت و پەیوەندیکردن لەگەڵ کرێچییە متمانەپێکراوەکان. ئەمڕۆ دەست بکە بە بەکرێدانی ئۆتۆمبێلەکەت!",
  },
  aboutSayara: {
    English:
      "Ako Sayara is Iraq's newest automotive marketplace for buying, selling, renting, and checking cars. We offer a seamless online experience for car shoppers and retailers, promoting eco-friendly choices and inclusivity.",
    Arabic:
      "اكو سيارة  هي أحدث سوق رقمي للسيارات في العراق، حيث يمكن شراء، بيع، تأجير، وفحص السيارات. نحن نقدم تجربة سلسة عبر الإنترنت لمتسوقي السيارات وتجارها، ونعزز الخيارات الصديقة للبيئة والشمولية.\n\n",
    Kurdish:
      "ئەکو سەیارە نوێترین بازاڕی ئۆتۆمبێلی دیجیتاڵییە لە عێراق، کە دەتوانیت ئۆتۆمبێل بکڕیت و بیفرۆشیت و بەکرێ بگرێت و پشکنینی ئۆتۆمبێلەکان بکەیت. ئێمە ئەزموونێکی بێ کێشەی ئۆنلاین بۆ کڕیاران و بریکارەکانی ئۆتۆمبێل دابین دەکەین، پەرە بە هەڵبژاردنی دۆستی ژینگە و گشتگیر دەدەین.",
  },
  contact: {
    English:
      "If you have any questions or need any assistance, the Ako Sayara team is here to help! Whether you're looking to buy, sell, or hire a car, our customer service team is ready to provide support. Contact us by phone, email, or through our online contact form, and we’ll respond promptly. Your satisfaction is our priority!",
    Arabic:
      "إذا كانت لديك أي أسئلة أو تحتاج إلى مساعدة، فإن فريق اكو سيارة هنا لمساعدتك! سواء كنت تبحث عن شراء أو بيع أو استئجار سيارة، فإن خدمة العملاء لدينا جاهزة لتقديم الدعم. تواصل معنا عبر الهاتف أو البريد الإلكتروني أو من خلال نموذج الاتصال عبر الإنترنت، وسنرد عليك بسرعة. رضاك هو أولويتنا!",
    Kurdish:
      "ئەگەر هەر پرسیارێکتان هەیە یان پێویستتان بە هاوکاری هەیە، تیمی ئەکو سەیارە لێرەن بۆ یارمەتیدان! جا تۆ بەدوای کڕین، فرۆشتن یان بەکرێدانی ئۆتۆمبێل دەگەڕێیت، خزمەتگوزاری کڕیارەکانمان ئامادەیە بۆ پشتگیریکردن. پەیوەندیمان پێوە بکەن لە ڕێگەی تەلەفۆن، ئیمەیڵ یان لە ڕێگەی فۆڕمی پەیوەندی ئۆنلاینمانەوە و ئێمەش بە خێرایی وەڵام دەدەینەوە. ڕەزامەندی ئێوە لە پێشینەی کارەکانمانە!",
  },
  closeAccount: {
    English:
      "If you wish to close your Ako Sayara account, we are here to assist you. Simply contact our support team via email or through our online contact form with your account details, and we will guide you through the process. Please note that once your account is closed, all your saved data, listings, and preferences will be permanently deleted.",
    Arabic:
      "إذا كنت ترغب في إغلاق حسابك في اكو سيارة، نحن هنا لمساعدتك. ببساطة، اتصل بفريق الدعم لدينا عبر البريد الإلكتروني أو من خلال نموذج الاتصال عبر الإنترنت مع تفاصيل حسابك، وسنقوم بإرشادك خلال العملية. يُرجى ملاحظة أنه بمجرد إغلاق حسابك، سيتم حذف جميع بياناتك المحفوظة والإعلانات والتفضيلات بشكل دائم.",
    Kurdish:
      "ئەگەر دەتەوێت ئەکاونتەکەت لە ئەکو سەیارە دابخەیت، ئێمە لێرەین بۆ یارمەتیدانت. تەنها لە ڕێگەی ئیمەیڵەوە یان لە ڕێگەی فۆڕمی پەیوەندی ئۆنلاینمانەوە پەیوەندی بە تیمی پشتگیریمانەوە بکە لەگەڵ وردەکارییەکانی ئەکاونتەکەت، ئێمەش ڕێنماییت دەکەین لە پرۆسەکەدا. تکایە ئاگاداربن کاتێک ئەکاونتەکەت دادەخەیت، هەموو داتا و ڕێکلام و هەڵبژاردنە پاشەکەوتکراوەکانت بۆ هەمیشە دەسڕدرێنەوە.",
  },
  careers: {
    English:
      "Join our dynamic team at Ako Sayara and build your career in the automotive industry! We are always looking for passionate individuals who are keen to contribute to our mission of revolutionising the car buying, selling, and hiring experience. Explore exciting opportunities across various departments, from customer service to tech development. At Ako Sayara, you can grow professionally while making a difference in the automotive world.",
    Arabic:
      "انضم إلى فريقنا الديناميكي في اكو سيارة وابنِ مستقبلك المهني في صناعة السيارات! نحن دائماً نبحث عن أفراد شغوفين يرغبون في المساهمة في مهمتنا لتغيير تجربة شراء وبيع واستئجار السيارات. استكشف فرصًا مثيرة في مختلف الأقسام، من خدمة العملاء إلى تطوير التكنولوجيا. في اكو سيارة، يمكنك أن تنمو مهنيًا بينما تحدث فرقًا في عالم السيارات.",
    Kurdish:
      "بەشداری تیمە دینامیکیەکەمان بکە لە ئەکو سەیارە و پیشەکەت لە پیشەسازی ئۆتۆمبێلدا بنیات بنێ! ئێمە هەمیشە بەدوای کەسانی بەسۆزدا دەگەڕێین کە دەیانەوێت بەشداری لە ئەرکەکەماندا بکەن بۆ گۆڕینی ئەزموونی کڕین و فرۆشتن و بەکرێدانی ئۆتۆمبێل. دەرفەتە سەرنجڕاکێشەکان لە سەرانسەری بەشەکاندا بکۆڵەرەوە، لە خزمەتگوزاری کڕیارەوە تا پەرەپێدانی تەکنەلۆژیا. لە ئیکۆ کار دەتوانیت بە شێوەیەکی پیشەیی گەشە بکەیت لە هەمان کاتدا جیاوازی لە جیهانی ئۆتۆمبێلدا دروست بکەیت.",
  },
  policies: {
    English:
      "At Ako Sayara, your privacy is important to us. Our Privacy Policy outlines how we collect, use, and protect your personal information when you use our platform. We are committed to ensuring that your data is secure and used responsibly, in compliance with industry standards. By using our services, you agree to the terms outlined in this policy, which are designed to safeguard your privacy and enhance your experience.",
    Arabic:
      "في اكو سيارة، خصوصيتك تهمنا. توضح سياسة الخصوصية الخاصة بنا كيفية جمع واستخدام وحماية معلوماتك الشخصية عند استخدامك لمنصتنا. نحن ملتزمون بضمان أمان بياناتك واستخدامها بمسؤولية، وفقًا للمعايير الصناعية. باستخدامك لخدماتنا، فإنك توافق على الشروط الموضحة في هذه السياسة، المصممة لحماية خصوصيتك وتحسين تجربتك.",
    Kurdish:
      "لە ئەکو سەیارە، پاراستنی نهێنی تۆ بۆ ئێمە گرنگە. سیاسەتی پاراستنی نهێنی ئێمە ڕوونی دەکاتەوە کە ئێمە چۆن زانیارییە کەسییەکانی تۆ کۆدەکەینەوە، بەکاریان دەهێنین و دەیپارێزین کاتێک تۆ پلاتفۆرمەکەمان بەکاردەهێنیت. ئێمە پابەندین بە دڵنیابوون لەوەی کە داتاکانتان پارێزراون و بە شێوەیەکی بەرپرسیارانە بەکاردەهێنرێن، بەپێی ستانداردەکانی پیشەسازی. بە بەکارهێنانی خزمەتگوزارییەکانمان، تۆ ڕەزامەندیت لەسەر ئەو مەرجانەی کە لەم سیاسەتەدا باسکراون، کە بۆ پاراستنی نهێنی تۆ و باشترکردنی ئەزموونەکەت داڕێژراوە.",
  },
  conditions: {
    English:
      "The Terms & Conditions of  Ako Sayara outline the rules and guidelines for using our platform. By accessing and using our services, you agree to these terms, which cover account usage, transactions, privacy, and other important aspects of our website. These terms are designed to ensure a safe, fair, and transparent experience for all users, whether you are buying, selling, renting your car, renting a car, or checking your car.",
    Arabic:
      "تحدد الشروط والأحكام الخاصة بـ اكو سيارة القواعد والإرشادات لاستخدام منصتنا. من خلال الوصول إلى خدماتنا واستخدامها، فإنك توافق على هذه الشروط التي تغطي استخدام الحساب، والمعاملات، والخصوصية، والجوانب المهمة الأخرى لموقعنا. تم تصميم هذه الشروط لضمان تجربة آمنة وعادلة وشفافة لجميع المستخدمين، سواء كنت تشتري، تبيع، تؤجر سيارتك، تستأجر سيارة، أو تفحص سيارتك.",
    Kurdish:
      "مەرج و ڕێساکانی ئەکو سەیارە یاسا و ڕێنماییەکانی بەکارهێنانی پلاتفۆرمەکەمان دیاری کردووە. بە دەستگەیشتن و بەکارهێنانی خزمەتگوزارییەکانمان، تۆ ڕەزامەندیت لەسەر ئەم مەرجانە کە بەکارهێنانی ئەکاونت، مامەڵە، نهێنی و لایەنە گرنگەکانی تری ماڵپەڕەکەمان دەگرێتەوە. ئەم مەرجانە بۆ دڵنیابوون لە ئەزموونێکی سەلامەت و دادپەروەرانە و شەفاف بۆ هەموو بەکارهێنەران، جا تۆ کڕین، فرۆشتن، بەکرێدان، بەکرێدان یان پشکنینی ئۆتۆمبێلەکەت داڕێژراون.",
  },
  cookies: {
    English:
      "At  Ako Sayara, we use cookies to enhance your experience of buying, selling, renting, and checking cars. Cookies help personalise content and ads and enable key features. By using our site, you consent to our cookie policy.",
    Arabic:
      "في اكو سيارة نستخدم ملفات تعريف الارتباط لتحسين تجربتك في شراء، بيع، تأجير، وفحص السيارات. تساعد ملفات تعريف الارتباط على تخصيص المحتوى والإعلانات وتفعيل الميزات الأساسية. باستخدام موقعنا، فإنك توافق على سياسة ملفات تعريف الارتباط الخاصة بنا.\n\n",
    Kurdish:
      "لە ئەکو سەیارە ئێمە کووکیز بەکاردەهێنین بۆ باشترکردنی ئەزموونی تۆ لە کاتی کڕین، فرۆشتن، بەکرێدان و پشکنینی ئۆتۆمبێل کووکی یارمەتیدەرە بۆ کەسایەتیکردنی ناوەڕۆک و ڕێکلامەکان و چالاککردنی تایبەتمەندییە گرنگەکان.",
  },
  newCarDeals: {
    English:
      "Discover the latest New car deals at Ako Sayara! We offer exclusive discounts and promotions on a wide range of brand-new vehicles, from economy to luxury models. Whether you're looking for a sleek saloon or a family SUV, our deals help you find the perfect car at unbeatable prices. Don't miss out—explore our new car deals today!",
    Arabic:
      "اكتشف أحدث عروض السيارات الجديدة في اكو سيارة! نحن نقدم خصومات وعروض حصرية على مجموعة واسعة من السيارات الجديدة، من السيارات الاقتصادية إلى السيارات الفاخرة. سواء كنت تبحث عن سيارة سيدان أنيقة أو سيارة SUV عائلية، تساعدك عروضنا في العثور على السيارة المثالية بأسعار لا تقبل المنافسة. لا تفوت الفرصة – استكشف عروض السيارات الجديدة لدينا اليوم!",
    Kurdish:
      "نوێترین ئۆفەری ئۆتۆمبێلی نوێ لە ئەکو سەیارە بدۆزەرەوە! داشکاندن و ئۆفەری تایبەت پێشکەش دەکەین لەسەر کۆمەڵێک ئۆتۆمبێلی نوێ، لە ئابوورییەوە تا ئۆتۆمبێلی لوکس. جا تۆ بەدوای ئۆتۆمبێلێکی سیدان شیکدا دەگەڕێیت یان ئۆتۆمبێلێکی جۆری SUVی خێزانیی، ئۆفەرەکانمان یارمەتیت دەدەن بۆ دۆزینەوەی ئۆتۆمبێلی تەواو بە نرخێکی بێ ڕکابەر. لەدەست مەدە - ئەمڕۆ ئۆفەرە نوێیەکانی ئۆتۆمبێلەکەمان بگەڕێ!",
  },
  classicCars: {
    English:
      "Explore a unique selection of Classic Cars in Iraq at Ako Sayara. Whether you're a collector or simply a fan of vintage automobiles, we offer a range of timeless classics that embody elegance and history. From restored models to rare finds, our collection provides a glimpse into automotive heritage. Discover and own a piece of history today!",
    Arabic:
      "استكشف مجموعة فريدة من السيارات الكلاسيكية في العراق لدى اكو سيارة. سواء كنت جامعًا للسيارات أو مجرد معجب بالسيارات القديمة، نقدم لك مجموعة من السيارات الكلاسيكية الخالدة التي تجسد الأناقة والتاريخ. من النماذج المجددة إلى الاكتشافات النادرة، توفر مجموعتنا لمحة عن التراث السياراتي. اكتشف وامتلك قطعة من التاريخ اليوم!",
    Kurdish:
      "کۆمەڵەیەکی ناوازەی ئۆتۆمبێلی کلاسیک لە عێراق لە ئەکو سەیارە بگەڕێ. جا تۆ کۆکەرەوەی ئۆتۆمبێل بیت یان تەنها هەواداری ئۆتۆمبێلە کۆنەکان، ئێمە کۆمەڵێک ئۆتۆمبێلی کلاسیکی بێ کاتتان پێشکەش دەکەین کە ڕەنگینیی و مێژوو بەرجەستە دەکەن. لە مۆدێلە نۆژەنکراوەکانەوە تا دەگمەنە دۆزراوەکان، کۆمەڵەکەمان وردەکارییەک لە میراتی ئۆتۆمبێل دەدات. ئەمڕۆ پارچەیەک لە مێژوو بدۆزەرەوە و ببێتە خاوەنی!",
  },
  electricCars: {
    English:
      "At Ako Sayara, you can easily buy, sell, or rent electric cars in Iraq. As the demand for eco-friendly vehicles grows, we offer a wide range of electric cars that combine cutting-edge technology with sustainability. Whether you're looking to purchase, list your electric car for sale, or rent one for short-term use, our platform provides a seamless experience. Join the green revolution and explore electric car options today!",
    Arabic:
      "في اكو سيارة، يمكنك بسهولة بيع, شراء أو تأجير السيارات الكهربائية في العراق. مع تزايد الطلب على المركبات الصديقة للبيئة، نقدم مجموعة واسعة من السيارات الكهربائية التي تجمع بين التكنولوجيا المتقدمة والاستدامة. سواء كنت تبحث عن شراء، عرض سيارتك الكهربائية للبيع، أو استئجار واحدة للاستخدام القصير الأمد، فإن منصتنا توفر تجربة سلسة. انضم إلى الثورة الخضراء واستكشف خيارات السيارات الكهربائية اليوم!",
    Kurdish:
      "لە ئەکو سەیارە، دەتوانیت بە ئاسانی ئۆتۆمبێلی کارەبایی لە عێراق بفرۆشیت، بکڕیت یان بەکرێ بگرێت. لەگەڵ زیادبوونی خواست لەسەر ئۆتۆمبێلی دۆستی ژینگە، ئێمە کۆمەڵێک ئۆتۆمبێلی کارەبایی بەرفراوان پێشکەش دەکەین کە تەکنەلۆژیای پێشکەوتوو و بەردەوامیی تێکەڵ دەکەن. جا تۆ بەدوای کڕیندا دەگەڕێیت، ئۆتۆمبێلی کارەبایی خۆت بۆ فرۆشتن لیست بکەیت، یان یەکێکیان بەکرێ بگرێت بۆ بەکارهێنانی کورتخایەن، پلاتفۆرمەکەمان ئەزموونێکی بێ کێشە پێشکەش دەکات. ئەمڕۆ بەشداری شۆڕشی سەوز بکە و بژاردەکانی ئۆتۆمبێلی کارەبایی بکۆڵەرەوە!",
  },
  rentPerfectCars: {
    English:
      "Looking to Rent your perfect car in Iraq? Ako Sayara offers a wide variety of rental cars to suit every need, from economy to luxury models. Whether it's for a business trip, family outing, or special occasion, find the ideal vehicle with ease. With flexible options and reliable service, Ako Sayara makes car rentals simple and convenient. Rent your perfect car today!",
    Arabic:
      "تبحث عن استئجار سيارتك المثالية في العراق؟ تقدم اكو سيارة مجموعة واسعة من السيارات للإيجار لتناسب كل الاحتياجات، من السيارات الاقتصادية إلى السيارات الفاخرة. سواء كان ذلك لرحلة عمل، نزهة عائلية، أو مناسبة خاصة، اعثر على السيارة المثالية بسهولة. مع خيارات مرنة وخدمة موثوقة، تجعل اكو سيارة استئجار السيارات بسيطًا ومريحًا. استأجر سيارتك المثالية اليوم!\n\n",
    Kurdish:
      " بەدوای ئۆتۆمبێلی کرێی نمونەی دەگەڕێیت لە عێراق؟ .بە بژاردەی نەرم و نیان بە خزمەتگوزارییەکی متمانەپێکراو، ئیکۆ کار کرێی ئۆتۆمبێل سادە و گونجاو دەکات ئەمڕۆ ئۆتۆمبێلی نمونەیەکەت بە کرێ بگرە!",
  },
  articles: {
    English:
      "Stay informed with Our Articles & News at Ako Sayara. Explore the latest updates on the automotive industry, car maintenance tips, market trends, and exclusive insights into buying, selling, and renting cars in Iraq and the UAE. Our expert-written articles keep you up-to-date and help you make informed decisions for all your automotive needs.\n\n",
    Arabic:
      "ابقَ على اطلاع مع مقالاتنا وأخبارنا في اكو سيارة. استكشف آخر التحديثات حول صناعة السيارات، نصائح صيانة السيارات، اتجاهات السوق، ورؤى حصرية حول شراء، بيع، وتأجير السيارات في العراق والإمارات. مقالاتنا المكتوبة من قبل خبراء تبقيك على اطلاع دائم وتساعدك في اتخاذ قرارات مستنيرة لجميع احتياجاتك المتعلقة بالسيارات.\n\n",
    Kurdish:
      "ئاگاداربن لەگەڵ بابەت و هەواڵەکانمان لە ئەکو سەیارەدا دوایین نوێکارییەکانی پیشەسازی ئۆتۆمبێل، ئامۆژگارییەکانی چاککردنەوەی ئۆتۆمبێل، ڕەوتی بازاڕ، و تێگەیشتنە تایبەتەکان لە کڕین و فرۆشتن و بەکرێدانی ئۆتۆمبێل لە عێراق و ئیماراتدا بگەڕێن ئاگادار و یارمەتیت دەدات بڕیاری ئاگادارانە بدەیت بۆ هەموو پێداویستییەکانی ئۆتۆمبێل.",
  },
  advancedSearch: {
    English:
      "Use the Advanced Search feature on Ako Sayara to find your ideal car quickly and easily. Filter results by make, model, price, year, location, and more to customise your search and discover the perfect vehicle for buying, selling, or renting. Our advanced tools help you navigate through a wide selection of cars to match your specific needs effortlessly.",
    Arabic:
      "استخدم ميزة البحث المتقدم في اكو سيارة للعثور على سيارتك المثالية بسرعة وسهولة. قم بتصفية النتائج حسب النوع، الطراز، السعر، السنة، الموقع، والمزيد لتخصيص بحثك واكتشاف السيارة المثالية للشراء، البيع أو التأجير. تساعدك أدواتنا المتقدمة في التنقل بين مجموعة واسعة من السيارات لتلبية احتياجاتك الخاصة بكل سهولة.\n\n",
    Kurdish:
      "تایبەتمەندی گەڕانی پێشکەوتووی ئەکو سەیارە بەکاربهێنە بۆ دۆزینەوەی ئۆتۆمبێلە تەواوەکەت بە خێرایی و بە ئاسانی ئەنجامەکان بەپێی مارکە، مۆدێل، نرخ، ساڵ، شوێن و زۆر شتی تر فلتەر بکە بۆ ئەوەی گەڕانەکەت بەپێی خواستی خۆت و دۆزینەوەی ئۆتۆمبێلی تەواو بۆ کڕین، فرۆشتن، یان بەکرێدانی ئامرازە پێشکەوتووەکانمان یارمەتیت دەدات لە گەشتکردن بە کۆمەڵێک ئۆتۆمبێلی بەرفراوان بۆ دابینکردنی پێداویستییە تایبەتەکانت بە ئاسانی.",
  },
  searchResult: {},
  buyCar: {
    English:
      "Looking to Buy cars in Iraq? Ako Sayara offers a vast selection of vehicles to suit every budget and preference, from economy models to luxury cars. Our user-friendly platform makes it easy to browse listings, compare options, and find the perfect car. Whether you're searching for a brand-new vehicle or a reliable used car, Ako Sayara provides a seamless buying experience. Start your search today!\n\n",
    Arabic:
      "تبحث عن شراء سيارات في العراق؟ يقدم اكو سيارة مجموعة واسعة من السيارات لتناسب كل ميزانية وتفضيل، من النماذج الاقتصادية إلى السيارات الفاخرة. تسهل منصتنا سهلة الاستخدام تصفح القوائم، مقارنة الخيارات، والعثور على السيارة المثالية. سواء كنت تبحث عن سيارة جديدة تمامًا أو سيارة مستعملة موثوقة، يوفر اكو سيارة تجربة شراء سلسة. ابدأ البحث اليوم!\n",
    Kurdish:
      'بەدوای کڕینی ئۆتۆمبێل لە عێراق؟ ئەکو سەیارەهەڵبژاردنی بەرفراوانی ئۆتۆمبێل پێشکەش دەکات کە لەگەڵ هەموو بودجە و ئارەزوویەکدا بگونجێت، لە مۆدێلی ئابوورییەوە تا ئۆتۆمبێلی لوکس. پلاتفۆرمی بەکارهێنەر دۆستانەمان ئاسانکاری دەکات بۆ گەڕان بە لیستەکاندا، بەراوردکردنی بژاردەکان، و دۆزینەوەی ئۆتۆمبێلی تەواو". جا تۆ بەدوای ئۆتۆمبێلێکی نوێدا دەگەڕێیت یان ئۆتۆمبێلێکی بەکارهێنراوی متمانەپێکراو، ئەکو سەیارە ئەزموونێکی کڕینی بێ کێشەت بۆ دابین دەکات ئەمڕۆ دەست بە گەڕانەکەت بکە!',
  },
  rentalSearchResult: {},
  dealerRental: {},
  dealerCarView: {},
  undefined: {},
  dealerSearchResults: {},
  enquiry: {},
  whyAkoSayara: {},
  dealershipDocument: {},
  myAccount: {},
  myVehicles: {},
  myTermsConditions: {},
  myPrivacyPolicies: {},
  myContactUs: {},
  myCloseAccount: {},
  editCar: {},
  editProfile: {},
  security: {},
  editSecurity: {},
  transactions: {},
  messages: {},
  perfomance: {},
  myProfile: {},
  editWorkHour: {},
  myCookies: {},
  login: {},
  forgotPassword: {},
  enterOTP: {},
  signup: {},
  basicDetails: {},
  saved: {},
  comparing: {},
  viewImage: {},
  plan: {},
  renew: {},
  payment: {},
  viewMap: {},
  cheapCars: {},
  singleCar: {
    English:
      "Discover all the details of your desired vehicle with Single Car listings on Ako Sayara. Each car page provides comprehensive information, including features, specifications, price, and high-quality images, helping you make an informed decision. Whether you're looking to buy, sell, or rent, explore every aspect of the car in one convenient place.",
    Arabic:
      "اكتشف جميع تفاصيل سيارتك المطلوبة مع قوائم السيارات الفردية في اكو سيارة. توفر كل صفحة سيارة معلومات شاملة، بما في ذلك الميزات، المواصفات، السعر، وصور عالية الجودة، مما يساعدك على اتخاذ قرار مستنير. سواء كنت تبحث عن الشراء، البيع، أو التأجير، استكشف كل جانب من جوانب السيارة في مكان واحد مريح.\n",
    Kurdish:
      "هەموو وردەکارییەکانی ئۆتۆمبێلی دڵخوازت بدۆزەرەوە لەگەڵ لیستەی ئۆتۆمبێلی تاکەکەسی لە ئەکو سەیارە هەر لاپەڕەیەکی ئۆتۆمبێل زانیاری گشتگیر دەدات، لەوانەش تایبەتمەندی، تایبەتمەندی، نرخ، و وێنەی کوالیتی بەرز، یارمەتیت دەدات بڕیارێکی ئاگادارانە بدەیت یان نا. فرۆشتن، یان کرێ، هەموو لایەنەکانی ئۆتۆمبێلەکەت لە یەک شوێنی گونجاودا بگەڕێ.",
  },
  singleRental: {
    English:
      "Discover all the details of your desired vehicle with Single Car listings on Ako Sayara. Each car page provides comprehensive information, including features, specifications, price, and high-quality images, helping you make an informed decision. Whether you're looking to buy, sell, or rent, explore every aspect of the car in one convenient place.",
    Arabic:
      "اكتشف جميع تفاصيل سيارتك المطلوبة مع قوائم السيارات الفردية في اكو سيارة. توفر كل صفحة سيارة معلومات شاملة، بما في ذلك الميزات، المواصفات، السعر، وصور عالية الجودة، مما يساعدك على اتخاذ قرار مستنير. سواء كنت تبحث عن الشراء، البيع، أو التأجير، استكشف كل جانب من جوانب السيارة في مكان واحد مريح.\n",
    Kurdish:
      "هەموو وردەکارییەکانی ئۆتۆمبێلی دڵخوازت بدۆزەرەوە لەگەڵ لیستەی ئۆتۆمبێلی تاکەکەسی لە ئەکو سەیارە هەر لاپەڕەیەکی ئۆتۆمبێل زانیاری گشتگیر دەدات، لەوانەش تایبەتمەندی، تایبەتمەندی، نرخ، و وێنەی کوالیتی بەرز، یارمەتیت دەدات بڕیارێکی ئاگادارانە بدەیت یان نا. فرۆشتن، یان کرێ، هەموو لایەنەکانی ئۆتۆمبێلەکەت لە یەک شوێنی گونجاودا بگەڕێ.",
  },
  search: {},
  rentalSearch: {},
  singleCarCheck: {
    English:
      "With Single Car Check on Ako Sayara, you can verify important details about any car before buying or renting. Access key information such as the car's history, condition, mileage, and more to ensure you're making a confident and informed decision. Get peace of mind with our reliable car check service.",
    Arabic:
      "مع فحص السيارة الفردية على اكو سيارة، يمكنك التحقق من التفاصيل المهمة لأي سيارة قبل الشراء أو التأجير. احصل على معلومات رئيسية مثل تاريخ السيارة، حالتها، الأميال، والمزيد لضمان اتخاذ قرار واثق ومستنير. احصل على راحة البال مع خدمة فحص السيارات الموثوقة لدينا.",
    Kurdish:
      "لەگەڵ پشکنینی تاکەکەسی ئۆتۆمبێل لەسەر ئەکو سەیارە، دەتوانیت پێش کڕین یان بەکرێدانی وردەکارییە گرنگەکانی هەر ئۆتۆمبێلێک بزانیت. زانیاری سەرەکی وەک مێژووی ئۆتۆمبێل، بارودۆخ، میل و زۆر شتی تر بەدەستبهێنە بۆ ئەوەی دڵنیا بیت کە بڕیارێکی متمانەپێکراو و ئاگادارانە دەدەیت. ئارامی دەروونی بەدەست بهێنە لەگەڵ خزمەتگوزاری پشکنینی ئۆتۆمبێلی متمانەپێکراومان.",
  },
  singleBrand: {
    English:
      "Discover the best car brands and models in Iraq with Ako Sayara. Browse through top manufacturers and find the perfect vehicle that fits your needs and budget.",
    Arabic:
      "اكتشف أفضل ماركات وموديلات السيارات في العراق مع اكو سيارة. تصفح أفضل الشركات المصنعة وابحث عن السيارة المثالية التي تناسب احتياجاتك وميزانيتك.",
    Kurdish:
      "باشترین مارکە و مۆدێلەکانی ئۆتۆمبێل لە عێراق بدۆزەرەوە لەگەڵ ئەکو سەیارە. بەدوای باشترین بەرهەمهێنەرەکاندا بگەڕێ و ئۆتۆمبێلێکی تەواو بدۆزەرەوە کە لەگەڵ پێداویستی و بودجەی خۆتدا بگونجێت.",
  },
  brandSearchResults: {
    English:
      "Search for your favorite car brands in Iraq with Ako Sayara. Explore a wide selection of vehicles from top brands at competitive prices. Start your search now!",
    Arabic:
      "ابحث عن شركات السيارات المفضلة لديك في العراق مع اكو سارة. استكشف مجموعة واسعة من المركبات من أفضل الشركات بأسعار تنافسية. ابدأ بحثك الآن!",
    Kurdish:
      "کۆمپانیاکانی ئۆتۆمبێلی دڵخوازت لە عێراق بدۆزەرەوە لەگەڵ ئەکو سەیارە. گەڕان بەدوای کۆمەڵێک ئۆتۆمبێلی بەرفراوان لە کۆمپانیا سەرەکییەکان بە نرخێکی کێبڕکێکار. هەر ئێستا دەست بە گەڕانەکەت بکە!",
  },
  faqPage: {
    English:
      "Find answers to common questions about buying, selling, and renting cars with Ako Sayara in Iraq. Get all the information you need in one place!",
    Arabic:
      "اعثر على إجابات للأسئلة الشائعة حول شراء وبيع وتأجير السيارات مع اكو سيارة في العراق. احصل على كل المعلومات التي تحتاجها في مكان واحد!",
    Kurdish:
      "وەڵامی پرسیارە باوەکان بدۆزەرەوە سەبارەت بە کڕین و فرۆشتن و بەکرێدانی ئۆتۆمبێل لەگەڵ کۆمپانیای ئەکو سەیارە لە عێراق. هەموو ئەو زانیاریانەی کە پێویستتانە لە یەک شوێن بەدەست بهێنە!",
  },
  usedCarDeals: {
    English:
      "Explore a wide range of quality used cars at unbeatable prices in Iraq. Buy your dream car today with Ako Sayara's trusted platform. Check out the latest deals!",
    Arabic:
      "اكتشف مجموعة واسعة من السيارات المستعملة عالية الجودة بأسعار لا تقبل المنافسة في العراق. اشترِ سيارة أحلامك اليوم مع منصة اكو سيارة الموثوقة. اطلع على أحدث الصفقات!",
    Kurdish:
      "کۆمەڵێک ئۆتۆمبێلی بەکارهاتووی کوالێتی بەرز بدۆزەرەوە بە نرخێکی بێ ڕکابەر لە عێراق. ئەمڕۆ ئۆتۆمبێلی خەونەکانت بکڕە بە پلاتفۆرمی متمانەپێکراوی ئەکو سەیارە. نوێترین گرێبەستەکان ببینە!",
  },
};

// remaining

// {
//   login: `/Login`,
//   forgotPassword: `/Forgot-password`,
//   enterOTP: `/Forgot-password/Enter-otp`,
//   signup: `/Sign-up`,
//   basicDetails: `/Basic-details`,
//   saved: `/Saved`,
//   comparing: `/Comparing`,
//   viewImage: `/View-image`,
//   plan: `/Ad-plan`,
//   renew: `/Renew-ad`,
//   payment: `/Payment`,
//   viewMap: `/View-on-map`,
//   cheapCars: `/Cheap-cars`,
//   singleBlog: `/singleBlog`,
//   singleGuide: `/singleGuide`,
//   dealershipDocument: `/Dealership-document`,
//   search: `/Search`,
//   rentalSearch: `/Rental-search`,
//   singleCarCheck: `/Single-car-check`,
//   searchResult: `/Search-results`,
//   rentalSearchResult: `/RentalSearchResult`,
//   dealerRental: `/Dealer-rental`,
//   dealerCarView: `/Dealer-cars`,
//   searchResultPage: `/Dealer-results`,
//   dealerSearchResults: `/Dealer-search-results`,
//   enquiry: `/Enquiry`,
//   myAccount: `/My-account`,
//   myVehicles: `/My-account/Vehicles`,
//   editCar: `/My-account/Edit-car`,
//   editProfile: `/My-account/Edit-profile`,
//   security: `/My-account/Security`,
//   editSecurity: `/My-account/Edit-security`,
//   transactions: `/My-account/Transactions`,
//   messages: `/My-account/Messages`,
//   perfomance: `/My-account/Perfomance`,
//   myProfile: `/My-account/My-profile`,
//   myProfile1: `/My-account`,
//   editWorkHour: `/My-account/Edit-work-hours`,
// };

import { Icon } from "@iconify/react";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select from "react-select";
export default function SelectMultiple({
  values,
  placeholder,
  name,
  onChange,
  showDropdownIcon,
  selectedOption,
  isSearchable,
}) {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  let options = [];

  if (values) {
    options = values.map((v) => {
      const name = v.name?.[language] || v.name?.[lang] || v.name || v?.label;
      return {
        label: t(name),
        value: v?.value || v?.value === "" ? v?.value : name,
      };
    });
  }
  if (placeholder === t("National") || placeholder === t("Cities")) {
    options.unshift({ label: t("National"), value: t("National") });
  }
  // console.log([selectedOption?.map(v => ({label: v, value: v}))])
  const customStyles = {
    menu: (base) => ({
      ...base,
      width: `100% !important`,
    }),
  };

  const filterOption = (option, val) => {
    const label = t(option.label);

    return label.toLowerCase().includes(val.toLowerCase());
  };

  const selectInputRef = useRef();
  useEffect(() => {
    if (!selectedOption || selectedOption.length == 0) {
      selectInputRef?.current?.clearValue();
    }
  }, [selectedOption]);

  return (
    <div style={{ position: "relative" }}>
      <Select
        ref={selectInputRef}
        // defaultValue={selectedOption?.map((v) => ({ label: t(v), value: v }))}
        defaultValue={selectedOption}
        // value={{ label: t('Akre'), value: 'Akre' }}
        isSearchable={isSearchable || false}
        // isMulti
        // menuIsOpen
        name="colors"
        filterOption={filterOption}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          NoOptionsMessage: () => null,
          LoadingIndicator: () => null,
        }}
        className="basic-multi-select"
        classNamePrefix="selectCustom "
        styles={customStyles}
        menuPlacement="auto"
        // menuPosition="fixed"
        classNames={{
          control: (state) =>
            state.isFocused ? "form-control" : "form-control",
          menu: (state) =>
            state.isFocused ? "selectCustomMenu" : "selectCustomMenu",
        }}
      />
      {showDropdownIcon && (
        <Icon
          icon="icon-park-outline:down"
          width={17}
          className="fw-bolder"
          style={{
            color: "#404040",
            zIndex: 9999,
            position: "absolute",
            top: "15px",
            right: i18n.language === "English" ? "5px" : "unset",
            left: i18n.language !== "English" ? "5px" : "unset",
          }}
        />
      )}
    </div>
  );
}

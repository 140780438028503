import React from "react";
import SelectCustomItems from "../../SelectCustomItems";
import SelectForm from "../../SelectForm";
import { useSelector } from "react-redux";
import { t } from "i18next";
import ReactSelectCustom from "../../ReactSelectCustom";
import ReactSelectForm from "../../ReactSelectForm";
import { Controller } from "react-hook-form";
import { isFieldRequired } from "./addFormUtils";

export default function Basic({
  watch,
  control,
  data,
  selectedMake,
  setSelectedMake,
  register,
  errors,
  models,
  trims,
  setValue,
  getValues,
  page,
}) {
  const upload_list = useSelector((state) => state.withoutVin.withoutVin);
  const language = useSelector((state) => state.auth.language);
  const lang2 =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  return (
    <div class="other_div other_div2">
      <h3>{t("Basic Details")}</h3>
      <div class="row">
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Make")}*</label>
            <SelectCustomItems
              selectedOption={selectedMake}
              // items1={upload_list?.make?.slice(0, 10)}
              items2={upload_list?.make}
              // title1={"Most searched for"}
              title2={"All makes"}
              placeholder={t("Select")}
              handelChange={(e) => {
                setValue("make", e?.value);
                setSelectedMake(e?.item);
              }}
              showDropdownIcon
              width={"100%"}
              hasSelected={data?.properties?.basicDetails?.make?.[lang2]}
            />
            {errors.make && getValues("make") === t("Select") && (
              <p>{errors.make.message}</p>
            )}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Model")}*</label>
            <Controller
              control={control}
              name="model"
              render={({ field }) => {
                return (
                  <ReactSelectForm
                    value={field.value}
                    // selectedOption={watch("model")}
                    items={models}
                    onChange={(op) => {
                      if (op) {
                        field.onChange(op);
                      }
                    }}
                    showDropdownIcon
                    width={"100%"}
                    disabled={!models || models?.length == 0}
                  />
                );
              }}
            />
            {errors.model && <p>{errors.model.message}</p>}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Model Variant")}</label>
            <Controller
              control={control}
              name="modelTrim"
              render={({ field }) => {
                return (
                  <ReactSelectForm
                    canCreate
                    value={field.value}
                    items={trims}
                    onChange={(op) => {
                      if (op) {
                        field.onChange(op);
                      }
                    }}
                    showDropdownIcon
                    width={"100%"}
                  />
                );
              }}
            />
            {errors.modelTrim && <p>{errors.modelTrim.message}</p>}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Year")}*</label>
            <SelectForm
              style={"form-control"}
              selectedOption={parseInt(
                data?.properties?.basicDetails?.makeYear
              )}
              event={register}
              name={"makeYear"}
              values={upload_list.yearsList ? upload_list.yearsList : []}
            />
            {errors?.makeYear && <p>{errors?.makeYear.message}</p>}
          </div>
        </div>
        {isFieldRequired("regional", page) && (
          <div class="col-sm-6 col-lg-4">
            <div class="form-group">
              <label>{t("Regional Specs")}*</label>
              <SelectForm
                style={"form-control"}
                selectedOption={
                  data?.properties?.basicDetails?.regionalSpecs?.[lang2]
                }
                event={register}
                name={"regional"}
                values={upload_list.regional ? upload_list.regional : []}
              />
              {errors.regional && <p>{errors.regional.message}</p>}
            </div>
          </div>
        )}
        {isFieldRequired("condition", page) && (
          <div class="col-sm-6 col-lg-4">
            <div class="form-group">
              <label>{t("Body Condition")}*</label>
              <SelectForm
                style={"form-control"}
                selectedOption={
                  data?.properties?.basicDetails?.condition?.[lang2]
                }
                event={register}
                name={"condition"}
                values={upload_list.condition ? upload_list.condition : []}
              />
              {errors.condition && <p>{errors.condition.message}</p>}
            </div>
          </div>
        )}
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Exterior color")}*</label>
            <SelectForm
              type={"color"}
              style={"form-control"}
              selectedOption={data?.properties?.basicDetails?.color}
              event={register}
              name={"colour"}
              values={upload_list.colour ? upload_list.colour : []}
            />
            {errors.colour && <p>{errors.colour.message}</p>}
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="form-group">
            <label>{t("Interior color")}*</label>
            <SelectForm
              type={"color"}
              style={"form-control"}
              selectedOption={data?.properties?.basicDetails?.interiorColor}
              event={register}
              name={"interiorColor"}
              values={
                upload_list.interiorColor ? upload_list.interiorColor : []
              }
            />
            {errors.interiorColor && <p>{errors.interiorColor.message}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

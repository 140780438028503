import React, { useEffect, useState } from "react";
import ListItem from "../ListItem";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { serachFilterSearch } from "../../../Reducer/api";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import { Input } from "reactstrap";
export default function Select({
  type,
  title,
  data,
  id,
  handleChange,
  allFilters,
  setAllFilters,
  setSelected,
}) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const helperData = data;
  const [serchedlist, setSerachedList] = useState([]);
  const [Alllist, setAllList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  useEffect(() => {
    if (data) {
      console.log(data);
      setSerachedList(data?.slice(0, 5));
      let temp = data;
      if (typeof data?.[0]?.name === "object") {
        temp = data?.map((v) => ({ name: v?.name?.en }));
      }
      setAllList(temp);
    }
  }, [data]);
  const handleSearch = (value) => {
    let keyName = "";
    switch (type) {
      case "make":
        keyName = "name";
        break;
      case "model":
        keyName = "label";
        break;
      case "modelTrim":
        keyName = "name";
        break;
      default:
        keyName = "name";
    }
    if (value.length > 0) {
      const filteredData = helperData.filter((item) => {
        let label = t(item?.[keyName]);
        if (typeof item?.[keyName] === "object") label = item?.[keyName]?.en;
        return label?.toLowerCase().includes(value.toLowerCase());
      });
      setAllList(filteredData);
    } else {
      setAllList(helperData);
    }
  };

  const handleChangeInput = (event) => {
    const inputValue = event.target.value;
    setSearchValue(inputValue);
    handleSearch(inputValue);
  };

  const setTitle = (type) => {
    switch (type) {
      case "make":
        return t("Select Make");
      case "model":
        return t("Select Model");
      case "modelTrim":
        return t("Select Model variant");
      case "cityPlates":
        return t("Select plate city");
    }
  };

  const setSubHeading = (type) => {
    switch (type) {
      case "make":
        return t("All makes");
      case "model":
        return t("All models");
      case "modelTrim":
        return t("All model variants");
    }
  };
  const handleSelectMakeModel = (item) => {
    switch (type) {
      case "make":
        setAllFilters({ ...allFilters, make: item?.name });
        break;
      case "model":
        setAllFilters({ ...allFilters, model: item?.value });
        break;
      case "modelTrim":
        setAllFilters({ ...allFilters, modelTrim: item?.name });
        break;
      case "cityPlates":
        setAllFilters({ ...allFilters, cityPlate: [item?.name] });
        break;
    }
    handleCloseModal();
  };

  const handleCloseModal = () => {
    $(".drp").removeClass("show");
    $(".dropdown").removeClass("show");
    setSelected(null);
  };

  const clearFilter = (type) => {
    switch (type) {
      case "make":
        setAllFilters({ ...allFilters, make: t("Any") });
        break;
      case "model":
        setAllFilters({ ...allFilters, model: t("Any") });
        break;
      case "modelTrim":
        setAllFilters({ ...allFilters, modelTrim: t("Any") });
        break;
      case "cityPlates":
        setAllFilters({ ...allFilters, cityPlate: [] });
        break;
    }
    handleCloseModal();
  };

  return (
    <ul class="dropdown-menu drp" id={id} style={{ position: "relative" }}>
      <h5>
        {setTitle(type)}
        <a onClick={() => handleCloseModal()}>
          <img src="../assets/images/close_img.png" alt="icon" />
        </a>
      </h5>
      <form className="mb-4">
        <i class="fa-solid fa-magnifying-glass"></i>
        <Input
          placeholder={t("Search")}
          aria-label="Search"
          value={searchValue}
          onChange={handleChangeInput}
        />
      </form>

      <div class="setp d-flex flex-column thinScroll">
        <ul>
          {Alllist?.map((item, index) => {
            return (
              <li
                value={item}
                style={{ cursor: "pointer" }}
                onClick={() => handleSelectMakeModel(item)}
              >
                {t(
                  item?.name?.[lang] || item?.label || item?.value || item?.name
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <p
        style={{
          cursor: "pointer",
        }}
        className={`d-flex justify-content-start`}
        onClick={() => {
          if (clearFilter) {
            clearFilter(type);
          }
        }}
      >
        <button
          className="btn-hover-clear rounded-1 px-2 py-1"
          style={{
            backgroundColor: "white",
            border: "1px solid #4C7EE8",
            color: "#4C7EE8",
          }}
        >
          {t("Clear")}
        </button>
      </p>
    </ul>
  );
}

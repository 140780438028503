import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import { fetchpopularModal } from "../../../Reducer/newBrandsSlice";
import { homeOptions } from "../../../data/content";
import { NavLink } from "react-router-dom";
import OwlItem from "../OwlItem";
import OwlItemCars from "../OwlItem/owlItemCars";
import { useState } from "react";
import { paths } from "../../../utils/paths";
import Skeletons from "../Skeletons";

export default function PopularModal({
  rtl,
  title,
  page,
  filters,
  buttonTitle,
  sectionClassName
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const popularmodal = useSelector((state) => state.new_brands.popularmodal);
  const loading = useSelector((state) => state.new_brands.loading);
  const error = useSelector((state) => state.new_brands.error);
  const auth = useSelector((state) => state.auth.auth);
  let data = { id: auth && auth.id ? auth.id : "", page: page };

  useEffect(() => {
    dispatch(fetchpopularModal({ page: page, filters: filters }));
  }, [dispatch]);

  let responce =
    popularmodal.data && popularmodal.data.length > 0 ? popularmodal.data : [];

  const handleClick = (datalist) => {
    if (datalist) {
      dispatch(fetchpopularModal(data));
    }
  };

  const [radioLoading, setRadioLoading] = useState(false);
  const [rentType, setRentType] = useState(page == "normal" ? page : "daily");

  const handleChnage = (e) => {
    setRadioLoading(true);
    setTimeout(() => {
      setRadioLoading(false);
    }, 500);
    setRentType(e.target.value);
  };

  return (
    <section class={`recently_viewed md-5 ${sectionClassName}`}>
      <div class="container">
        <h2 class="hed">{t(title)}</h2>
        {loading ? (
          <Skeletons forPage={"owlItems"} />
        ) : (
          <OwlCarousel
            className={`owl-carousel Recently_carousel owl.carousel.min owl-theme ${
              rtl && "invertButtons"
            }`}
            {...homeOptions}
            rtl={rtl}
            nav
          >
            <OwlItemCars cars={responce} rentType={rentType} />
          </OwlCarousel>
        )}
        <div class="w-100 d-flex justify-content-center align-items-center text-center">
          <NavLink
            className={"viewall"}
            style={{ width: "max-content", paddingInline: "12px" }}
            to={page === "normal" ? paths.buyCar : paths.rentCar}
            state={{ filters: filters || {} }}
          >
            {t(buttonTitle)}
          </NavLink>
        </div>
      </div>
    </section>
  );
}

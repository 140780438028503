import React from "react";
import { useTranslation } from "react-i18next";
import SelectOptions from "../../Tools/SelctOptions";
import SelectWithCategory from "../SelectWithCategory";
import SelectCustomItems from "../SelectCustomItems";
import i18n from "../../../i18n";
import { adUserTypes } from "../../../data/content";
import SelectMultiple from "../SelectMultiple";
import ReactSelectForm from "../ReactSelectForm";
import { isFieldRequired } from "../Forms/AddCar/addFormUtils";
export default function LeftSideBar({
  type,
  title,
  allFilters,
  setAllFilters,
  models,
  data,
  page,
}) {
  const { t } = useTranslation();

  return (
    <div class="set">
      <h4>{title}</h4>
      <div class="row">
        {/* {basic_select_box &&
          basic_select_box.map((item, index) => {
            if(type === 'Rentals' && skip.includes(item.field)){
              return null
            }

            return (
              <div class="col-6">
                <p>{t(item?.name)}</p>
                <SelectOptions
                  style={"form-control"}
                  placeholder={item?.field === 'cities' ? t('National') : t('Any')}
                  selectedOption={""}
                  onChange={(e) => handleChange(e, item.field)}
                  name={item?.field}
                  values={item.field === 'model' ? models : item?.data}
                  // disabled={item.field === 'model' ? !models || models.length == 0 : !item?.data}
                />
              </div>
            );
          })} */}
        {isFieldRequired("sellerType", page) && (
          <div class="col-6">
            <p>{t("Seller type")}</p>
            <SelectOptions
              noAll
              style={"form-control"}
              selectedOption={allFilters?.adUserType || ""}
              onChange={(e) => {
                const v = e?.target.value;
                setAllFilters({
                  ...allFilters,
                  adUserType: v && v !== "0" ? v : "",
                });
              }}
              name={"adUserType"}
              values={adUserTypes}
              // disabled={item.field === 'model' ? !models || models.length == 0 : !item?.data}
            />
          </div>
        )}
        <div class="col-6">
          <p>{t("Cities")}</p>
          <SelectMultiple
            selectedOption={
              allFilters?.city &&
              allFilters?.city?.length > 0 &&
              allFilters?.city !== t("National")
                ? {
                    label: t(allFilters?.city?.[0]),
                    value: allFilters?.city?.[0],
                  }
                : ""
            }
            placeholder={t("National")}
            onChange={(e) =>
              setAllFilters({ ...allFilters, city: e?.value ? [e?.value] : [] })
            }
            values={data?.cities}
            showDropdownIcon
            isSearchable
          />
        </div>
        <div class="col-6">
          <p>{t("Make")}</p>
          {/* <SelectWithCategory
            style={"form-control"}
            selectedOption={t("Any")}
            onChange={(e) => setAllFilters({ ...allFilters, make: e.target.value })}
            name={data?.field}
            values1={data?.make?.slice(0, 8)}
            values2={data?.make}
            boxStyle={{
              padding: '10px'
            }}
            title1={t('Most searched for')}
            title2={t('All makes')}
          /> */}
          <SelectCustomItems
            selectedOption={allFilters.make ? { name: allFilters.make } : ""}
            // items1={data?.make?.slice(0, 5)}
            items2={data?.make}
            // title1={"Most searched for"}
            title2={"All makes"}
            placeholder={t("Any")}
            handelChange={(e) =>
              setAllFilters({ ...allFilters, make: e?.value })
            }
            showDropdownIcon
            width={"100%"}
          />
        </div>
        <div class="col-6">
          <p>{t("Model")}</p>
          {/* <SelectOptions
            style={"form-control"}
            selectedOption={t("Any")}
            onChange={(e) =>
              setAllFilters({ ...allFilters, model: e.target.value })
            }
            name={data?.field}
            values={models}
            disabled={models?.length == 0}
          /> */}
          <ReactSelectForm
            hasAny
            selectedOption={allFilters?.model || t("Any")}
            items={models}
            onChange={(e) => setAllFilters({ ...allFilters, model: e.value })}
            showDropdownIcon
            width={"100%"}
            disabled={!models}
          />
        </div>
        <div class="col-6">
          <p>{t("Model Variant")}</p>
          {/* <SelectOptions
            style={"form-control"}
            selectedOption={t("Any")}
            onChange={(e) =>
              setAllFilters({ ...allFilters, modelTrim: e.target.value })
            }
            name={data?.field}
            values={data?.modelTrim}
            disabled={models?.length == 0}
          /> */}
          <ReactSelectForm
            hasAny
            selectedOption={allFilters?.modelTrim || t("Any")}
            items={data?.modelTrim?.map((v) => ({
              label: v?.name?.en,
              value: v?.name?.en,
            }))}
            onChange={(e) =>
              setAllFilters({ ...allFilters, modelTrim: e.value })
            }
            showDropdownIcon
            width={"100%"}
            disabled={!data?.modelTrim}
          />
        </div>
        {isFieldRequired("regional", page) && (
          <div class="col-6">
            <p>{t("Regional Specs")}</p>
            <SelectOptions
              style={"form-control"}
              // placeholder={t('Any')}
              selectedOption={allFilters?.regional?.[0] || t("Any")}
              onChange={(e) =>
                setAllFilters({ ...allFilters, regional: [e.target.value] })
              }
              name={data?.field}
              values={data?.regional_space}
              // disabled={item.field === 'model' ? !models || models.length == 0 : !item?.data}
            />
          </div>
        )}
        {isFieldRequired("condition", page) && (
          <div class="col-6">
            <p>{t("Body conditon")}</p>
            <SelectOptions
              style={"form-control"}
              selectedOption={allFilters?.condition?.[0] || t("Any")}
              onChange={(e) =>
                setAllFilters({ ...allFilters, condition: [e.target.value] })
              }
              name={data?.field}
              values={data?.body}
              // disabled={item.field === 'model' ? !models || models.length == 0 : !item?.data}
            />
          </div>
        )}
        {isFieldRequired("paint", page) && (
          <div class="col-6">
            <p>{t("Paint")}</p>
            <SelectOptions
              style={"form-control"}
              selectedOption={allFilters?.paint?.[0] || t("Any")}
              onChange={(e) =>
                setAllFilters({ ...allFilters, paint: [e.target.value] })
              }
              name={data?.field}
              values={data?.paint}
              // disabled={item.field === 'model' ? !models || models.length == 0 : !item?.data}
            />
          </div>
        )}
      </div>
    </div>
  );
}

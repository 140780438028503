import React from "react";
import Skeletons from "../Skeletons";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ImageBox = ({
  value,
  style,
  path,
  onClick,
  imageHeight,
  imageClasses,
  inlineStyles,
  loading,
}) => {
  return (
    <div className={style} style={{ inlineStyles }} onClick={onClick}>
      {loading == true ? (
        <Skeletons forPage={"brand-icons"} />
      ) : (
        <div className="brand-icons border rounded-1 h-100 d-flex flex-column gap-3 px-1 py-2 py-sm-3 align-items-center">
          <LazyLoadImage
            src={path}
            alt={`Image`}
            effect="opacity"
            className={` ${imageClasses}`}
            // height={imageHeight}
            style={{ objectFit: "cover" }}
          />
          <h6
            style={{
              textAlign: "center",
            }}
            className="m-0"
          >
            {value}
          </h6>
        </div>
      )}
    </div>
  );
};
export default ImageBox;

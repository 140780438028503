import React, { useEffect, useState } from "react";
import SelectOptions from "../SelctOptions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
export default function CFPrice({
  title,
  data,
  id,
  handleChange,
  handleClick,
  allFilters,
  setAllFilters,
  showFilteredValues,
}) {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const [financeRadio, setFinanceRadio] = useState(allFilters?.price?.finance ? "finance" : "cash");
  const handleChangeFinanceRadio = (e) => {
    setFinanceRadio(e.target.value);
    setAllFilters({
      ...allFilters,
      price: {
        ...allFilters.price,
        finance: e.target.value === "cash" ? false : true,
      },
    });
  };

  return (
    <ul class="dropdown-menu " id={id}>
      {data && data.min && data.max ? (
        <div class="setp">
          <div class="row" style={{ zIndex: "-1" }}>
            <div class="col-12">
              <div
                class="btn-group w-100"
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <div className="col-6">
                  <input
                    type="radio"
                    class="btn-check"
                    value={"cash"}
                    name="btnradio"
                    onChange={handleChangeFinanceRadio}
                    id="btnradio1"
                    autocomplete="off"
                    checked={financeRadio === "cash"}
                  />
                  <label
                    class={`btn rental-price-radio ${
                      financeRadio === "cash" ? "active" : ""
                    }`}
                    style={{
                      height: "43px",
                      borderRadius:
                        language === "English"
                          ? "5px 0px 0px 5px"
                          : "0px 5px 5px 0px",
                      borderTop: "1px solid #606060",
                      borderBottom: "1px solid #606060",
                      borderLeft:
                        language === "English"
                          ? "1px solid #606060"
                          : "0px solid #606060",
                      borderRight:
                        language === "English"
                          ? "0px solid #606060"
                          : "1px solid #606060",
                    }}
                    for="btnradio1"
                  >
                    {t("Cash")}
                  </label>
                </div>
                <div className="col-6">
                  <input
                    type="radio"
                    class="btn-check"
                    value={"finance"}
                    name="btnradio"
                    onChange={handleChangeFinanceRadio}
                    id="btnradio2"
                    autocomplete="off"
                    checked={financeRadio === "finance"}
                  />

                  <label
                    class={`btn rental-price-radio ${
                      financeRadio === "finance" ? "active" : ""
                    }`}
                    for="btnradio2"
                    style={{
                      height: "43px",
                      borderRadius: "0px 5px 5px 0px",
                      borderRadius:
                        language === "English"
                          ? "0px 5px 5px 0px"
                          : "5px 0px 0px 5px",
                      borderTop: "1px solid #606060",
                      borderBottom: "1px solid #606060",
                      borderRight:
                        language === "English"
                          ? "1px solid #606060"
                          : "1px solid #606060",
                      borderLeft:
                        language === "English"
                          ? "0px solid #606060"
                          : "1px solid #606060",
                    }}
                  >
                    {t("Finance")}
                  </label>
                </div>
              </div>
            </div>
            <div
              class={`col-6 text-${language === "English" ? "start" : "end"}`}
            >
              <label>{t(data.min.name[i18n.language])}</label>
              {/* <div class="input-group mb-3">
                <span class="input-group-text">$</span>
                <input
                  type="number"
                  onInput={(e) => {
                    let ch = e.target.value.charAt(e.target.value.length - 1);
                    if (!["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(ch)) {
                      e.target.value = e.target.value.replace(ch, "");
                    }
                  }}
                  class="form-control"
                  onChange={(e) => {setAllFilters({...allFilters, price: {...allFilters.price, min : e.target.value}})}}
                />
              </div> */}
              <SelectOptions
                style={"form-control"}
                selectedOption={
                  data.min.data.filter(
                    (v) => v?.value?.toString?.() == allFilters?.price?.min
                  )?.[0]?.value || t("Any")
                }
                name={data.min.field}
                values={showFilteredValues(
                  "min_max_price",
                  data.min.data,
                  allFilters?.price?.max,
                  "min"
                )}
                onChange={(e) =>
                  setAllFilters({
                    ...allFilters,
                    price: { ...allFilters.price, min: e.target.value },
                  })
                }
              />
            </div>
            <div
              class={`col-6 text-${language === "English" ? "start" : "end"}`}
            >
              <label>{t(data.max.name[i18n.language])}</label>
              {/* <div class="input-group mb-3">
                <span class="input-group-text">$</span>
                <input
                  type="number"
                  onInput={(e) => {
                    let ch = e.target.value.charAt(e.target.value.length - 1);
                    if (!["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(ch)) {
                      e.target.value = e.target.value.replace(ch, "");
                    }
                  }}
                  class="form-control"
                  onChange={(e) => {setAllFilters({...allFilters, price: {...allFilters.price, max : e.target.value}})}}
                />
              </div> */}

              <SelectOptions
                style={"form-control"}
                selectedOption={
                  data.max.data.filter(
                    (v) => v.value == allFilters?.price?.max
                  )?.[0]?.value || t("Any")
                }
                name={data.max.field}
                values={showFilteredValues(
                  "min_max_price",
                  data.max.data,
                  allFilters?.price?.min,
                  "max"
                )}
                onChange={(e) =>
                  setAllFilters({
                    ...allFilters,
                    price: { ...allFilters.price, max: e.target.value },
                  })
                }
              />

            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </ul>
  );
}
import { t } from "i18next";
import React from "react";
import useEmblaCarousel from "embla-carousel-react";
import { DotButton, useDotButton } from "./dot-buttons";
import Autoplay from "embla-carousel-autoplay";

function StepsCarouselWithPicture({ bigPicture, title, carouselData, rtl }) {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, direction: rtl ? "rtl" : "ltr" },
    [Autoplay({ playOnInit: true, delay: 5000 })]
  );

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  return (
    <div className="row m-0 w-100 bg-light rounded-sm overflow-hidden">
      <div className="p-5 col-lg-6 d-flex flex-column justify-content-between gap-3">
        <h3
          className="m-0 fs-4"
          style={{ color: `var(--text-black-2)`, fontWeight: 500 }}
        >
          {t(title)}
        </h3>
        <div className="embla">
          <div className="embla__viewport" ref={emblaRef}>
            <div className="embla__container">
              {carouselData.map((item, index) => (
                <div
                  className="embla__slide d-flex flex-column gap-3"
                  key={index}
                >
                  <h5
                    className="fs-5"
                    style={{ color: `var(--text-black-2)`, fontWeight: 500 }}
                  >
                    {t(item?.title)}
                  </h5>
                  <p
                    className="fw-normal fs-5"
                    style={{ color: `var(--text-black-2)` }}
                  >
                    {t(item?.description)}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-start">
          <div className="embla__dots d-flex gap-2">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={`embla__dot ${
                  index === selectedIndex ? " embla-dot__selected " : ""
                }`}
              ></DotButton>
            ))}
          </div>
        </div>
      </div>
      <div className="p-0 col-lg-6" style={{ height: 450 }}>
        <img
          src={bigPicture}
          alt=""
          className="w-100 h-100"
          style={{ objectFit: "cover", objectPosition: "center" }}
        />
      </div>
    </div>
  );
}

export default StepsCarouselWithPicture;

import { Icon } from "@iconify/react";
import { t } from "i18next";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import Select from "react-select";
import { Button } from "reactstrap";
import { camelToNormal } from "../../../utils/constants";
import i18n from "../../../i18n";
import CreatableSelect from "react-select/creatable";

const ReactSelectForm = ({
  selectedOption,
  placeholder,
  onChange,
  showDropdownIcon,
  width,
  hasSelected,
  loading,
  items,
  disabled,
  value,
  canCreate,
  onChangeAdditionalTask,
  hasAny,
}) => {
  // selectedOption = {...selectedOption, label: selectedOption?.name}
  const language = localStorage.getItem("cl");
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  const anyOption = { label: t("Any"), value: "" };
  const selectInputRef = useRef();
  useEffect(() => {
    if (!selectedOption) {
      selectInputRef?.current?.clearValue();
    }
  }, [selectedOption]);

  if (hasAny && items?.length > 0) {
    items = [anyOption, ...items];
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: loading || disabled ? "#e9ecef" : "",
    }),
    menu: (base) => ({
      ...base,
      width: `${width} !important`,
    }),
    groupHeading: (base) => ({
      ...base,
      textAlign: language === "English" ? "left" : "right",
    }),
  };

  const filterOption = (option, val) => {
    const label = t(option.label);
    return label.toLowerCase().includes(val.toLowerCase());
  };

  const MySelectComponent = (props) => {
    if (props?.canCreate) {
      return <CreatableSelect {...props} ref={selectInputRef} />;
    } else {
      return <Select {...props} ref={selectInputRef} />;
    }
  };

  if (hasSelected && !selectedOption) {
    return <></>;
  }
  return (
    <div style={{ position: "relative" }}>
      <MySelectComponent
        canCreate={canCreate || false}
        formatCreateLabel={(inputValue) => `${t("Add Trim")} "${inputValue}"`}
        isLoading={loading}
        isDisabled={disabled || loading}
        value={value}
        // menuIsOpen={true}
        isSearchable={true}
        {...{
          defaultValue: {
            ...selectedOption,
            label: selectedOption,
            value: selectedOption,
          },
        }}
        placeholder={t(placeholder)}
        onChange={(e) => {
          onChange(e);
          if (onChangeAdditionalTask) {
            onChangeAdditionalTask(e?.value);
          }
        }}
        filterOption={filterOption}
        formatOptionLabel={({ label }) => <div>{t(label)}</div>}
        options={items || []}
        components={{
          // DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          NoOptionsMessage: () => null,
          LoadingIndicator: () => null,
          ClearIndicator: () => null,
        }}
        className="basic-multi-select"
        classNamePrefix="selectCustom "
        styles={customStyles}
        menuPlacement="auto"
        // menuPosition="fixed"
        classNames={{
          control: (state) => `form-control`,
          menu: (state) => "selectCustomMenu",
        }}
      />
      {showDropdownIcon && (
        <Icon
          icon="icon-park-outline:down"
          width={17}
          className="fw-bolder"
          style={{
            color: "#404040",
            zIndex: 9999,
            position: "absolute",
            top: "15px",
            right: i18n.language === "English" ? "5px" : "unset",
            left: i18n.language !== "English" ? "5px" : "unset",
          }}
        />
      )}
    </div>
  );
};

export default ReactSelectForm;

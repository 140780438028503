import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { sideBar } from "./content";
import { rentalContent } from "./rentalContent";
import { useDispatch, useSelector } from "react-redux";
import { fetchDefaultFilterData } from "../../../Reducer/searchFilterSlice";
import SearchItem from "../../Tools/SearchItem";
import Pagination from "../../Tools/Pagination";
import SortBy from "../../Tools/SortBy";
import $ from "jquery";

import FilterSideBar from "../../Tools/FilterSideBar";
import { title } from "../../../data/content";
import { fetchSaved } from "../../../Reducer/savedSlice";
import ListItem from "../../Tools/ListItem";
import SelectOptions from "../../Tools/SelctOptions";
import { itemLimit, setCourierFilters } from "../../../utils/constants";
import sortBy from "../../../data/sortBy.json";
import { getModels } from "../../../Reducer/api";
import ReactSelectCustom from "../../Tools/ReactSelectCustom";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";
import { useDidMountEffect } from "../../../utils/hooks";

export default function SearchResult({ page, back }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const sidebar = useSelector((state) => state.searching.sideBar);
  const search = useSelector((state) => state.searching.search);
  const totalPage = useSelector((state) => state.searching.totalPage);
  const loading = useSelector((state) => state.searching.loading);
  const error = useSelector((state) => state.searching.error);
  // const [currentPage, setCurrentPage] = useState(0);
  // const { currentPage, setCurrentPage } = useContext(mainContext);
  const pageCount = totalPage;
  const SideBarDatSet = page == "normal" ? sideBar : rentalContent;
  const [params] = useSearchParams();
  const brandId = params.get("brandId");
  const ownerId = params.get("ownerId");
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const [sortVal, setSortVal] = useState({
    sort: "",
    order: "",
  });
  const [selectedContent, setSelectedContent] = useState(
    ownerId ? "normal" : page
  );

  const currentPage = parseInt(params.get("page")) || 1;
  const setCurrentPage = (n) => {
    const currentParams = new URLSearchParams(location.search);
    currentParams.set("page", n);
    navigate(`${location.pathname}?${currentParams.toString()}`, {
      replace: true,
    });
  };

  //pagination page change (page number wise)
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  //pagination previous button
  const previous = () => {
    let page = currentPage;
    if (page > 1) setCurrentPage(page - 1);
  };

  //pagination next button
  const next = () => {
    let page = currentPage;
    if (page < pageCount) setCurrentPage(page + 1);
  };

  const defaultValues = {
    city: [],
    make: "",
    model: "",
    price: {
      min: "",
      max: "",
      finance: false,
    },
    rentType: "daily",
    year: {
      min: "",
      max: "",
    },
    km: {
      min: "",
      max: "",
    },
    regional: [],
    condition: [],
    paint: [],
    colour: [],
    interiorColor: [],
    gearbox: [],
    fuel: [],
    bodyType: [],
    size: {
      min: "",
      max: "",
    },
    batterySize: {
      min: "",
      max: "",
    },
    power: {
      min: "",
      max: "",
    },
    cylinders: {
      min: "",
      max: "",
    },
    doors: {
      min: "",
      max: "",
    },
    seats: {
      min: "",
      max: "",
    },
    sort: "",
    plateType: "",
    adUserType: "",
    cityPlate: []
  };

  const [allFilters, setAllFilters] = useState({
    ...defaultValues,
    ...location?.state?.filters,
  });
  const filtersRef = useRef(allFilters);
  const resetFilters = () => {
    setAllFilters(defaultValues);
  };

  useEffect(() => {
    dispatch(
      fetchDefaultFilterData({
        page: selectedContent,
        currentPage: currentPage,
        limit: itemLimit,
        brandId: brandId,
        ownerId: ownerId,
        filters: allFilters,
        // selectedFilters: getSelectedFilters().join(",") || "",
      })
    );
  }, [currentPage, allFilters, page, ownerId]);

  useEffect(() => {
    setAllFilters({
      ...allFilters,
      sort: sortVal.sort.concat(sortVal.order ? "," + sortVal.order : ""),
    });
  }, [sortVal]);

  //list of saved cars of current user
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(fetchSaved());
    }
  }, []);

  const handleClick = (a, b, c) => {
    $(".switch-button-case").removeClass("left");
    $("#" + b).addClass("left");
  };

  // sending filters to context
  // useDidMountEffect(() => {
  //   // setCourierFilters(allFilters);
  //   console.log('all filters changed', allFilters)
  // }, [allFilters]);

  return (
    <>
      <section class="advance_search car_search_results">
        <div class="container">
          <h6 className="back back2">
            <a onClick={() => navigate(-1)}>
              <img src="../assets/images/backbt.png" /> {t("Go back")}
            </a>
          </h6>
          <div class="advance_search_in">
            <FilterSideBar
              dataset={SideBarDatSet}
              // data={sidebar}
              handleClickevent={handleClick}
              allFilters={allFilters}
              setAllFilters={setAllFilters}
              page={page}
              resetFilters={resetFilters}
              selectedContent={page}
              // getSelectedFilters={getSelectedFilters}
              filtersRef={filtersRef}
              setCurrentPage={setCurrentPage}
            />
            <SortBy
              data={sortBy.sortData}
              setSelectedContent={setSelectedContent}
              selectedContent={selectedContent}
              ownerId={ownerId}
              previous={previous}
              next={next}
              sortVal={sortVal}
              setSortVal={setSortVal}
            />
            <h4 className="d-lg-none d-flex align-items-center justify-content-between">
              <span
                class="d-flex align-items-center"
                style={{ fontSize: "16px" }}
              >
                <img src="../assets/images/SortBy.png" height={50} alt="img" />
                <ReactSelectCustom
                  values={sortBy.sortData}
                  width={"180px"}
                  // boxWidth={'140px'}
                  placeholder={""}
                  selectedOption={t("Relevance")}
                  onChange={(e) =>
                    setSortVal({
                      sort: e.item.sort,
                      order: e.item.order,
                    })
                  }
                />
              </span>
              <span
                class="filter_ic"
                style={{ cursor: "pointer" }}
                onClick={() => $(".sidebar").addClass("main")}
              >
                <img src="../assets/images/filter_ic.png" />
                {t("Filters")}
              </span>
            </h4>
            {/* {ownerId && 
          <div className="d-flex justify-content-center d-lg-none">
            <div className="row" style={{ width: "60%" }}>
              <span className="login">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <ListItem
                    innerhtmlType={"button"}
                    key={0}
                    btnType={"button"}
                    onClick={() => setSelectedContent("normal")}
                    style={"nav-item"}
                    buttonStyle={
                      selectedContent == "normal"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    buttonTitle={t("cars")}
                  ></ListItem>
                  <ListItem
                    innerhtmlType={"button"}
                    key={1}
                    btnType={"button"}
                    onClick={() => setSelectedContent("rental")}
                    style={"nav-item"}
                    buttonStyle={
                      selectedContent == "rental"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    buttonTitle={t("rentals")}
                  ></ListItem>
                </ul>
              </span>
            </div>
          </div>          
          } */}

            {loading ? (
              <div
                class="d-flex justify-content-center"
                style={{ minHeight: "100vh", marginTop: "25vh" }}
              >
                <div class="spinner-grow" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                class="tab-content"
                id="myTabContent"
                style={{ minHeight: "1300px" }}
              >
                {/* <LoginForm type={selectedOption}/> */}
                {search && search.length > 0 ? (
                  search.map((item, index) => {
                    return (
                      <SearchItem
                        data={item}
                        key={index}
                        rentType={allFilters.rentType}
                        allFilters={allFilters}
                      />
                    );
                  })
                ) : (
                  <h1
                    style={{
                      minHeight: "100vh",
                      textAlign: "center",
                      fontSize: "20px",
                      marginTop: "25vh",
                    }}
                  >
                    {"0 ".concat(t("Cars found"))}
                  </h1>
                )}
              </div>
            )}
            <nav aria-label="Page navigation example">
              <Pagination
                style={`pagination float-end mb-5 mt-4 ${
                  lang !== "en" && "paginationButtonAr"
                }`}
                next={"Next"}
                forcePage={currentPage}
                previous={"Previous"}
                pageCount={pageCount}
                rangeDisplayed={3}
                handlePageClick={handlePageClick}
              />
            </nav>
          </div>
        </div>
      </section>

      <JsonLDSchemaHandler
        data={page == "normal" ? ldSchemas?.buyCar : ldSchemas?.rentCar}
      />
    </>
  );
}

import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import "./i18n";
import { changeLanguage, setToken, setUser } from "./Reducer/authSlice";
import "react-toastify/dist/ReactToastify.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
//stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import i18n from "./i18n";
import { lang } from "moment";

import { Translator } from "react-auto-translate";
import { GOOGLE_API_KEY } from "./utils/constants";
import UnderMaintenance from "./under-maintenance";

const user =
  localStorage.getItem("user") !== "undefined"
    ? JSON.parse(localStorage.getItem("user"))
    : "";
const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : "";
const language = localStorage.getItem("cl")
  ? localStorage.getItem("cl")
  : "Arabic";

// const stripePromise = loadStripe(
//   "pk_test_51NPfjmSBYRGRwjpeDS42y0ade1mWwJbO7LcAtX0QtRNv0QyEiypvMy10JWR2z95fU52JX1TFNaRj9oUKQP43WRR200wzZwTNst"
// );

if (user && token) {
  store.dispatch(setUser(user));
  store.dispatch(setToken(token));
}
if (language) {
  console.log("meri base lang", language);
  store.dispatch(changeLanguage(language));
}

const options = {
  // passing the client secret obtained from the server
  // clientSecret: "pi_3OiCKZSBYRGRwjpe0JMKU17m_secret_R1KKGbA4Z8mGrzYwRzBbGS9L7",
  locale:
    language === "English"
      ? "en"
      : language === "Arabic"
      ? "ar"
      : language === "Kurdish"
      ? "ku"
      : "en",
};

// <Elements stripe={stripePromise} options={options}>
// </Elements>

ReactDOM.render(
  <Provider store={store}>
    <Translator
      // cacheProvider={cacheProvider}
      from="en"
      to={
        language === "English"
          ? "en"
          : language === "Arabic"
          ? "ar"
          : language === "Kurdish"
          ? "ckb"
          : "en"
      }
      googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
    >
      {/* <ToastContainer {...TostOptions} bodyClassName={language === 'English' ? "toaster_en" : 'toaster_ar'}/> */}
      <App />
      {/* <UnderMaintenance /> */}
    </Translator>
  </Provider>,
  document.getElementById("root")
);

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import RentalCarFrom from "./RentalForm";
import { fromDetail } from "../../../Reducer/formSlice";
import { toast } from "react-toastify";
import { getAdFiltersList1, getModels } from "../../../Reducer/api";
import { bannerRent } from "../../../utils/paths";
import {
  getAllFiltersData,
  getAllFiltersData1,
  getSelectedFilters,
} from "../../../utils/constants";

export default function RentalBanner({ page, carTypes, rtl }) {
  const { t, i18n } = useTranslation();
  const detail = useSelector((state) => state.fromsdata.fromsdata);
  // const loading  = useSelector(state => state.fromsdata.loading);
  const error = useSelector((state) => state.fromsdata.error);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fromDetail());
  }, [dispatch]);

  if (error) {
    toast.error(error);
  }

  const defaultValues = {
    city: [],
    make: "",
    model: "",
    modelTrim: "",
    bodyType: [],
    price: {
      min: "",
      max: "",
    },
  };
  const [models, setModels] = useState([]);
  const [filterData, setFilterData] = useState(defaultValues);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const filtersRef = useRef(filterData);
  // const [makes, setMakes] = useState([]);
  // useEffect(() => {
  //   getMake({isLive: true, limit: 200, ownerId: "", page: page, filters: filterData}).then((res) => {
  //     // res = res?.map((v) => ({ label: v, value: v }));
  //     let data = {
  //       name: t("Any"),
  //       value: "Any",
  //     };
  //     setMakes([data, ...res?.makes]);
  //   });
  // }, [filterData]);
  useEffect(() => {
    setModels([]);
    if (filterData.make) {
      getModels({
        makeId: filterData.make,
        live: true,
        ownerId: "",
        page: page,
      }).then((res) => {
        res = res?.map((v) => ({ label: v, value: v }));
        setModels(res);
      });
    }
    // else {
    //   getAllModels().then((res) => {
    //     res = res?.map((v) => ({ label: v, value: v }));
    //     setModels(res);
    //   });
    // }
  }, [filterData.make]);

  useEffect(() => console.log(filterData), [filterData]);

  const resetFilters = () => {
    setFilterData(defaultValues);
    setModels([]);
  };

  const [adFiltersList, setAdFiltersList] = useState([]);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const getFilters = async () => {
    // setFiltersLoading(true);
    // const apiCall = await getAllFiltersData(
    //   page,
    //   "",
    //   filterData,
    //   getSelectedFilters(
    //     selectedFilters,
    //     setSelectedFilters,
    //     filterData,
    //     filtersRef
    //   )
    // );
    // setAdFiltersList(apiCall);
    // setFiltersLoading(false);
    const keys = ["cities", "modelTrim", "makes"];
    const selected = getSelectedFilters(
      selectedFilters,
      setSelectedFilters,
      filterData,
      filtersRef
    );
    keys.forEach(async (key) => {
      let response = await getAdFiltersList1(
        page,
        "",
        filterData,
        selected,
        key
      );
      setAdFiltersList((adFiltersList) => ({
        ...adFiltersList,
        ...getAllFiltersData1(response),
      }));
    });
  };
  useEffect(() => {
    getFilters();
  }, [filterData]);

  return (
    <section
      class="banner"
      style={{
        backgroundImage: `url(${
          bannerRent
            ? bannerRent
            : "/assets/images/Bmw-Wallpaper-04-1920-x-1200.jpg"
        })`,
      }}
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div
              class="setform account-card usedNewTabs"
              style={{ overflow: "unset" }}
            >
              {/* <div className="mb-3">
                <span className="selected tab-label">
                  {t("Find your rental")}
                </span>
              </div> */}
              <h5>{t("Find your rental")}</h5>
              <div class="tab-content" id="myTabContent">
                <RentalCarFrom
                  data={detail}
                  carTypes={carTypes}
                  models={models}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  resetFilters={resetFilters}
                  adFiltersList={adFiltersList}
                  loading={filtersLoading}
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            {/* <h2 style={{ textShadow: "1px 1px 5px gray" }}>
              {t("Find your perfect Rental")}
            </h2> */}
          </div>
        </div>
      </div>
    </section>
  );
}

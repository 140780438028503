import React, { useEffect, useState } from "react";
import SelectOptions from "../../Tools/SelctOptions";
import Button from "../../Tools/Button";
import { homebanner } from "../../../data/content";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import filter from "../../../data/filter.json";
import SelectCustomItems from "../../Tools/SelectCustomItems";
import { showFilteredValues } from "../../../utils/constants";
import { useSelector } from "react-redux";
import { paths } from "../../../utils/paths";
import SelectMultiple from "../../Tools/SelectMultiple";
import ReactSelectForm from "../../Tools/ReactSelectForm";
export default function RentalCarFrom({
  data,
  carTypes,
  models,
  filterData,
  setFilterData,
  resetFilters,
  adFiltersList,
  loading,
}) {
  const [searchCount, setSerchCount] = useState(0);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  let responce = data && Object.keys(data).length !== 0 ? data : homebanner;
  const language = useSelector((state) => state.auth.language);
  const lang2 =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  return (
    <div
      class="tab-pane fade show active"
      id="tab1"
      role="tabpanel"
      aria-labelledby="tab1-tab"
    >
      <form>
        <div class="row">
          <div class="col-6">
            {/* <DynamicSelectComponent
              values={cities}
              filterData={filterData}
              setFilterData={setFilterData}
              placeholder={t("Cities")}
              name={"city"}
            /> */}
            {/* <SelectOptions
              selectedOption={filterData.city}
              placeholder={t("Cities")}
              name="cities"
              onChange={(e) =>
                setFilterData({ ...filterData, city: [e.target.value] })
              }
              style={"form-control"}
              values={adFiltersList?.cities || []}
              loading={loading}
            /> */}
            <SelectMultiple
              selectedOption={
                filterData?.city &&
                filterData?.city?.length > 0 &&
                filterData?.city !== t("Cities")
                  ? {
                      label: t(filterData?.city?.[0]),
                      value: filterData?.city?.[0],
                    }
                  : ""
              }
              hasAny
              placeholder={t("Cities")}
              onChange={(e) =>
                setFilterData({
                  ...filterData,
                  city: e?.value ? [e?.value] : [],
                })
              }
              values={adFiltersList?.cities}
              // showDropdownIcon
              isSearchable
            />
          </div>
          <div class="col-6">
            <SelectCustomItems
              selectedOption={filterData.make}
              items={adFiltersList?.make || []}
              // items1={makes?.slice(0, 5)}
              items2={adFiltersList?.make || []}
              // title1={"Most searched for"}
              title2={"All makes"}
              placeholder={t("Make")}
              handelChange={(e) =>
                setFilterData({ ...filterData, make: e?.value })
              }
              width={"100%"}
              hasAny
              loading={loading}
            />
          </div>
          <div class="col-6">
            {/* <SelectOptions
              selectedOption={filterData.model}
              placeholder={t("Model")}
              // disabled={models.length == 0}
              onChange={(e) =>
                setFilterData({ ...filterData, model: e.target.value })
              }
              style={"form-control"}
              disabled={models.length == 0}
              values={models}
              loading={loading}
            /> */}
            <ReactSelectForm
              hasAny
              selectedOption={filterData?.model || t("Model")}
              items={models}
              onChange={(e) => setFilterData({ ...filterData, model: e.value })}
              // showDropdownIcon
              width={"100%"}
              disabled={models?.length == 0}
            />
          </div>
          {/* <div class="col-6">
            <SelectOptions
              selectedOption={filterData?.bodyType}
              placeholder={t("Body Type")}
              onChange={(e) => {
                if (e.target.value == t("Any")) {
                  setFilterData({
                    ...filterData,
                    bodyType: [],
                  });
                } else {
                  setFilterData({
                    ...filterData,
                    bodyType: [
                      carTypes.filter(
                        (v) => v?.name?.[lang2] === e.target.value
                      )?.[0]?.name?.["en"],
                    ],
                  });
                }
              }}
              name={"bodyType"}
              style={"form-control"}
              values={adFiltersList.body_type}
              loading={loading}
            />
          </div> */}
          <div class="col-6">
            {/* <SelectOptions
              selectedOption={filterData.modelTrim}
              placeholder={t("Model Variant")}
              onChange={(e) =>
                setFilterData({ ...filterData, modelTrim: e.target.value })
              }
              style={"form-control"}
              disabled={models?.length == 0}
              values={adFiltersList?.modelTrim || []}
              loading={loading}
            /> */}
            <ReactSelectForm
              hasAny
              selectedOption={filterData?.modelTrim || t("Model Variant")}
              items={adFiltersList?.modelTrim?.map((v) => ({
                label: v?.name?.en,
                value: v?.name?.en,
              }))}
              onChange={(e) =>
                setFilterData({ ...filterData, modelTrim: e.value })
              }
              // showDropdownIcon
              width={"100%"}
              disabled={models?.length == 0}
            />
          </div>
          <div class="col-6">
            <SelectOptions
              selectedOption={filterData?.price?.min}
              placeholder={t("Min Price")}
              onChange={(e) => {
                if (e.target.value == t("Any")) {
                  setFilterData({
                    ...filterData,
                    price: { ...filterData.price, min: "" },
                  });
                } else {
                  setFilterData({
                    ...filterData,
                    price: { ...filterData.price, min: e.target.value },
                  });
                }
              }}
              name={"minPrice"}
              style={"form-control"}
              // values={filter.data.sideBar.min_max_price.min.daily}
              values={showFilteredValues(
                "min_max_price",
                filter.data.sideBar.min_max_price.min.daily,
                filterData?.price?.max,
                "min"
              )}
              loading={loading}
            />
          </div>
          <div class="col-6">
            <SelectOptions
              selectedOption={filterData?.price?.max}
              placeholder={t("Max Price")}
              onChange={(e) => {
                if (e.target.value == t("Any")) {
                  setFilterData({
                    ...filterData,
                    price: { ...filterData.price, max: "" },
                  });
                } else {
                  setFilterData({
                    ...filterData,
                    price: { ...filterData.price, max: e.target.value },
                  });
                }
              }}
              name={"maxPrice"}
              style={"form-control"}
              // values={filter.data.sideBar.min_max_price.max.daily}
              values={showFilteredValues(
                "min_max_price",
                filter.data.sideBar.min_max_price.max.daily,
                filterData?.price?.min,
                "max"
              )}
              loading={loading}
            />
          </div>
          <div class="col-sm-12">
            <Button
              type="submit"
              disabled={!filterData.make}
              onClick={() =>
                navigate(paths.rentCar, { state: { filters: filterData } })
              }
              style="btn"
              title={t("Search") + " " + t("cars")}
            />
          </div>
          <div class="col-6">
            <Button
              style="bnnr-btn"
              onClick={(e) => {
                e.preventDefault();
                resetFilters();
              }}
              title={
                <>
                  <img src={"assets/images/reset_settings.png"} />
                  {t("Reset settings")}
                </>
              }
            />
          </div>
          <div class="col-6 text-end">
            <Button
              onClick={() =>
                navigate(paths.advancedSearch, {
                  state: { selectedOption: "Rentals" },
                })
              }
              style="bnnr-btn"
              title={
                <>
                  <img src={"assets/images/advanced_filters.png"} />
                  {t("Advanced filters")}
                </>
              }
            />
          </div>
        </div>
      </form>
    </div>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import Banner from "./Banner";
import Stock from "./Stock";
import { title } from "../../../data/content";
import BrandSection from "../../Tools/BrandSection";
import Benefits from "../../Tools/Benifits";
import Popular from "../../Tools/Popular";
import { getEntityTypes, getMake } from "../../../Reducer/api";
import { useState } from "react";
import { useEffect } from "react";
import ComingSoon from "../../ComingSoon";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";
export default function ClassicCar() {
  const { t, i18n } = useTranslation();
  const [carTypes, setCarTypes] = useState([]);
  const [selectedCarType, setSelectedCarType] = useState(null);
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  // const getCarTypes = async () => {
  //   const res = await getEntityTypes({page: page, isLive: true});
  //   setCarTypes(res?.data);
  //   setSelectedCarType(res?.data?.[0]);
  // };

  // useEffect(() => {
  //   getCarTypes();
  // }, []);
  // const [makes, setMakes] = useState([]);
  // useEffect(() => {
  //   getMake().then((res) => {
  //     // res = res?.map((v) => ({ label: v, value: v }));
  //     setMakes(res?.makes);
  //   });
  // }, []);

  return (
    <>
      <ComingSoon />
      {/* <Banner carTypes={carTypes} makes={makes} setMakes={setMakes} />
      <BrandSection
      brandTitle={t("Browse by brand")}
      makes={makes}
      setMakes={setMakes}
      />
      <Stock />
      <Popular title={t("Find deals on popular models")} />
      <Benefits /> */}

      <JsonLDSchemaHandler data={ldSchemas?.classicCars} />
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Home,
  Login,
  ElectricCars,
  ClassicCar,
  RentalCar,
  Header,
  Dealer_search,
  Footer,
  Enquiry,
  Signup,
  BasicDetail,
  SellYourCar,
  RentYourCar,
  Saved,
  Compare,
  CheapCars,
  NewCars,
  SingleCar,
  CheckCar,
  Search,
  RentalSearch,
  DealerRental,
  SearchResultpage,
  UsedCars,
} from "./Components";
import AuthRoute from "./AuthRoute";
import {
  MyVehicle,
  MyProfile,
  Sidebar,
  Advertising_TCs,
  PrivacyPolicies,
  Transactions,
  Messages,
  Performance,
  EditWorkingHour,
} from "./Components/Pages/MyAccount";
import ArticleBlog from "./Components/Pages/ArticleBlog";
import SearchResult from "./Components/Pages/SearchResult";
import EditVehicle from "./Components/Pages/MyAccount/Pages/MyVehicle/EditVehicle";
import EditProfile from "./Components/Pages/MyAccount/Pages/MyProfile/EditProfile";
import Security from "./Components/Pages/MyAccount/Pages/MyProfile/Security";
import EditSecurity from "./Components/Pages/MyAccount/Pages/MyProfile/EditSecurity";
import UploadFormdocument from "./Components/Pages/SigupMedia/UploadMedia";
import ForgotPassword from "./Components/Pages/ForgotPassword";
import EnterOTP from "./Components/Pages/ForgotPassword/enterOTP";
import Policy from "./Components/Pages/Policies/Policy";
import TermsAndCondition from "./Components/Pages/TermsAndConditions";
import Cookies from "./Components/Pages/Cookies";
import AboutPage from "./Components/Pages/AboutPage";
import CloseAccount from "./Components/MyComponents/ContactUs/CloseAccount";
import Contactus from "./Components/MyComponents/ContactUs";
import ContactUS from "./Components/Pages/ContactUs";
import WhyAko from "./Components/MyComponents/WhyAko";
import Careers from "./Components/Pages/Careers";
import "./App.css";
import AdvancedSearch from "./Components/Pages/AdvancedSearch";
import AllArticles from "./Components/Pages/AllArticles";
import Guide from "./Components/Pages/Guide";
import SingleCarCheck from "./Components/Pages/CheckCar/SingleCarCheck";
import Plan from "./Components/Tools/plan";
import GeneralPayment from "./Components/Tools/GeneralPayment";
import Payments from "./Components/Tools/Payments";
import ThiredStep from "./Components/Tools/SRPages/ThirdStep";
import RenewPlan from "./Components/Pages/renew-plan/renewPlan";
import { beforeRefresh, onPageLoaded } from "./utils/constants";
import Meta from "./Components/meta";
import { paths } from "./utils/paths";
import { meta_des_all, page_titles } from "../src/data/metaContent";
import { t } from "i18next";
import i18n from "./i18n";
import { myprofilefetch } from "./Reducer/myprofileSlice";
import { useDispatch } from "react-redux";
import TestRoute from "./Components/MyComponents/test-route";
import { Icon } from "@iconify/react/dist/iconify.js";
import PageNotFound from "./404";
import SingleBrand from "./Components/Pages/SingleBrand";
import BrandSearch from "./Components/Pages/BrandSearch";
import FaqPage from "./Components/Pages/FaqPage/FaqPage";
import CookieConsent from "./Components/Tools/CookieConsent/CookieConsent";
import SellYourCarPage from "./Components/Pages/SellYourCarPage";
import RentYourCarPage from "./Components/Pages/RentYourCarPage";
import BuyCarInBaghdad from "./Components/Pages/BuyCarInBaghdad";

function App() {
  window.onbeforeunload = function (event) {
    beforeRefresh();
  };

  window.onload = function (event) {
    onPageLoaded();
  };

  const user =
    localStorage.getItem("user") !== "undefined"
      ? JSON.parse(localStorage.getItem("user"))
      : "";
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";

  const dispatch = useDispatch();

  const callOnIntervals = () => {
    // if (user && token) dispatch(myprofilefetch());
  };

  useEffect(() => {
    callOnIntervals();
    const intervalId = setInterval(() => {
      callOnIntervals();
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const [metaTagContent, setContent] = useState(t("metaDescription"));

  const RouteChangeListener = () => {
    const location = useLocation();

    useEffect(() => {
      let curr_key = "";
      for (const key in paths) {
        if (paths[key] === location.pathname) {
          curr_key = key;
          break;
        }
      }
      if (curr_key.length === 0) {
        curr_key = "";
      }
      document.title = page_titles?.[curr_key]?.[i18n.language] || ". . .";

      if (meta_des_all?.[curr_key]?.[i18n.language]) {
        setContent(meta_des_all?.[curr_key]?.[i18n.language]);
      }
      return;

      // Optionally, you can trigger logic based on the route change here
    }, [location.pathname]);
    return <></>;
  };

  const wholePageRoutes = [paths.storeDetect, paths.serviceDown];

  return (
    <div className="App">
      <BrowserRouter>
        <Meta content={metaTagContent} />
        <CookieConsent />
        <RouteChangeListener />

        {!wholePageRoutes.includes(window.location.pathname) ? (
          <Header />
        ) : null}
        {/* <Header /> */}
        <Routes>
          <Route path="*" element={<PageNotFound />} />

          <Route exact path={paths.home} element={<Home page={"normal"} />} />
          <Route exact path={paths.login} element={<Login />} />
          <Route
            exact
            path={paths.forgotPassword}
            element={<ForgotPassword />}
          />
          <Route exact path={paths.enterOTP} element={<EnterOTP />} />
          <Route exact path={paths.signup} element={<Signup />} />
          <Route
            exact
            path={paths.basicDetails}
            element={<AuthRoute element={<BasicDetail />} />}
          />
          <Route exact path={paths.saved} element={<Saved />} />
          <Route exact path={paths.comparing} element={<Compare />} />
          <Route exact path={paths.sellYourCar} element={<SellYourCar />} />
          <Route
            exact
            path={paths.sellYourCarPage}
            element={<SellYourCarPage />}
          />
          <Route exact path={paths.rentYourCar} element={<RentYourCar />} />
          <Route
            exact
            path={paths.rentYourCarPage}
            element={<RentYourCarPage />}
          />
          <Route exact path={paths.viewImage} element={<RentYourCar />} />
          <Route exact path={paths.plan} element={<Plan />} />
          <Route exact path={paths.renew} element={<RenewPlan />} />
          <Route exact path={paths.payment} element={<GeneralPayment />} />

          <Route exact path={paths.viewMap} element={<RentYourCar />} />
          <Route exact path={paths.cheapCars} element={<CheapCars />} />
          <Route
            exact
            path={paths.newCarDeals}
            element={<NewCars page={"normal"} />}
          />
          <Route
            exact
            path={paths.usedCarDeals}
            element={<UsedCars page={"normal"} />}
          />
          <Route exact path={paths.classicCars} element={<ClassicCar />} />
          <Route
            exact
            path={paths.electricCars}
            element={<ElectricCars page={"normal"} />}
          />
          <Route
            exact
            path={paths.rentPerfectCars}
            element={<RentalCar page={"rental"} />}
          />

          <Route
            exact
            path={`${paths.singleBlog}/:id`}
            element={<ArticleBlog />}
          />
          <Route exact path={`${paths.singleGuide}/:id`} element={<Guide />} />
          <Route exact path={paths.articles} element={<AllArticles />} />
          <Route
            exact
            path={paths.singleCar}
            element={<SingleCar page={"normal"} />}
          />
          <Route
            exact
            path={paths.singleRental}
            element={<SingleCar page={"rental"} />}
          />
          <Route
            exact
            path={paths.dealershipDocument}
            element={<UploadFormdocument />}
          />
          <Route exact path={paths.search} element={<Search />} />
          <Route exact path={paths.rentalSearch} element={<RentalSearch />} />
          <Route
            exact
            path={paths.advancedSearch}
            element={<AdvancedSearch />}
          />

          <Route exact path={paths.checkYourCars} element={<CheckCar />} />
          <Route
            exact
            path={paths.singleCarCheck}
            element={<SingleCarCheck />}
          />

          <Route
            exact
            path={paths.searchResult}
            element={<SearchResult page={""} back={"/"} />}
          />
          <Route
            exact
            path={paths.buyCar}
            element={<SearchResult page={"normal"} back={"/"} />}
          />
          <Route
            exact
            path={paths.rentalSearchResult}
            element={<SearchResult page={"rental"} />}
          />
          <Route
            exact
            path={paths.rentCar}
            element={<SearchResult page={"rental"} back={"/"} />}
          />
          <Route
            exact
            path={paths.dealerRental}
            element={<DealerRental page={"rental"} />}
          />
          <Route
            exact
            path={paths.dealerCarView}
            element={<DealerRental page={"normal"} />}
          />
          <Route
            exact
            path={paths.searchResultPage}
            element={<SearchResultpage />}
          />
          <Route exact path={paths.singleBrand} element={<SingleBrand />} />
          <Route
            exact
            path={paths.brandSearchResults}
            element={<BrandSearch />}
          />
          <Route
            exact
            path={paths.dealerSearchResults}
            element={<Dealer_search />}
          />
          <Route exact path={paths.enquiry} element={<Enquiry />} />

          <Route exact path={paths.policies} element={<Policy />} />
          <Route
            exact
            path={paths.conditions}
            element={<TermsAndCondition />}
          />
          <Route exact path={paths.cookies} element={<Cookies />} />
          <Route exact path={paths.aboutSayara} element={<AboutPage />} />
          <Route exact path={paths.contact} element={<ContactUS />} />
          <Route exact path={paths.whyAkoSayara} element={<WhyAko />} />
          <Route exact path={paths.careers} element={<Careers />} />

          <Route
            exact
            path={paths.myAccount}
            element={
              <AuthRoute element={<Sidebar component={<MyVehicle />} />} />
            }
          />
          <Route
            index
            path={paths.myVehicles}
            element={
              <AuthRoute element={<Sidebar component={<MyVehicle />} />} />
            }
          />
          <Route
            index
            path={paths.myTermsConditions}
            element={
              <AuthRoute
                element={<Sidebar component={<Advertising_TCs />} />}
              />
            }
          />
          <Route
            index
            path={paths.myWhyAkoSayara}
            element={<AuthRoute element={<Sidebar component={<WhyAko />} />} />}
          />
          <Route
            index
            path={paths.myPrivacyPolicies}
            element={
              <AuthRoute
                element={<Sidebar component={<PrivacyPolicies />} />}
              />
            }
          />
          <Route
            index
            path={paths.myContactUs}
            element={
              <AuthRoute element={<Sidebar component={<Contactus />} />} />
            }
          />
          <Route index path={paths.closeAccount} element={<CloseAccount />} />

          <Route
            index
            path={paths.myCloseAccount}
            element={
              <AuthRoute element={<Sidebar component={<CloseAccount />} />} />
            }
          />

          <Route
            index
            path={paths.editCar}
            element={
              <AuthRoute element={<Sidebar component={<EditVehicle />} />} />
            }
          />
          <Route
            index
            path={paths.editProfile}
            element={
              <AuthRoute element={<Sidebar component={<EditProfile />} />} />
            }
          />
          <Route
            index
            path={paths.security}
            element={
              <AuthRoute element={<Sidebar component={<Security />} />} />
            }
          />
          <Route
            index
            path={paths.editSecurity}
            element={
              <AuthRoute element={<Sidebar component={<EditSecurity />} />} />
            }
          />
          <Route
            index
            path={paths.transactions}
            element={
              <AuthRoute element={<Sidebar component={<Transactions />} />} />
            }
          />
          <Route
            index
            path={paths.messages}
            element={
              <AuthRoute element={<Sidebar component={<Messages />} />} />
            }
          />
          <Route
            index
            path={paths.perfomance}
            element={
              <AuthRoute element={<Sidebar component={<Performance />} />} />
            }
          />
          <Route
            index
            path={paths.myProfile}
            element={
              <AuthRoute
                element={
                  <Sidebar component={<MyProfile page={"personal"} />} />
                }
              />
            }
          />
          <Route
            index
            path={paths.myProfile1}
            element={
              <AuthRoute
                element={<Sidebar component={<MyProfile page={"dealer"} />} />}
              />
            }
          />
          <Route
            index
            path={paths.editWorkHour}
            element={
              <AuthRoute
                element={<Sidebar component={<EditWorkingHour />} />}
              />
            }
          />
          <Route
            index
            path={paths.myCookies}
            element={
              <AuthRoute element={<Sidebar component={<Cookies />} />} />
            }
          />
          <Route index path={paths.faqPage} element={<FaqPage />} />

          <Route
            exact
            path={paths.buyCarInBaghdad}
            element={<BuyCarInBaghdad />}
          />

          {/* test route */}
          <Route index path={paths.storeDetect} element={<TestRoute />} />
        </Routes>

        {!wholePageRoutes.includes(window.location.pathname) ? (
          <Footer />
        ) : null}
        {/* <Footer /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;

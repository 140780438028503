import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { paths } from "../../../utils/paths";
import { t } from "i18next";

function CarsShowcase({ title, data, lang }) {
  const navigate = useNavigate();

  const rtl = lang !== "en";
  const searchCars = (filters) => {
    navigate(paths.buyCar, {
      state: { filters: { ...filters, page: "normal" } },
    });
  };
  return (
    <section class="text-center">
      <div class="container">
        <h2 class="hed">{title}</h2>
        <OwlCarousel
          {...{
            loop: false,
            // autoplay: true,
            autoplayTimeout: 3000,
            margin: 15,
            responsiveClass: true,
            responsive: {
              0: { items: 1, nav: true },
              576: { items: 1, nav: true },
              768: { items: 2, nav: true },
              992: { items: 3, nav: true },
              1200: { items: 3, nav: true },
            },
          }}
          className={`owl-carousel benefits-owl owl.carousel.min owl-theme owl-rtl owl-loaded owl-drag ${
            rtl && "invertButtons"
          }`}
          // margin={10}
          rtl={rtl}
        >
          {data
            ? data.map((item, key) => {
                return (
                  <div
                    class="set border h-100 "
                    style={{ minHeight: "400px", cursor: "pointer" }}
                    key={key}
                    onClick={(e) => searchCars(item?.filters)}
                  >
                    <img
                      src={item.image}
                      alt="icon"
                      style={{
                        height: "220px",
                        objectFit: "contain",
                      }}
                      className="mb-2 mt-2"
                    />
                    <div className="d-flex justify-content-center">
                      <div class="car-shadow"></div>
                    </div>
                    <h5 style={{ marginTop: "25px" }}>
                      {item.title ? t(item.title) : ""}
                    </h5>
                    <p style={{ minHeight: "70px" }}>
                      {item.description ? t(item.description) : ""}{" "}
                    </p>
                  </div>
                );
              })
            : ""}
        </OwlCarousel>
      </div>
    </section>
  );
}

export default CarsShowcase;

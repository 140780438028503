import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import {
  fetchSearched,
  fetchPostByBrand,
} from "../../../Reducer/searchedSlice";
import SelectViewCar from "../../Tools/SelectViewCar";
import { getEntityTypes } from "../../../Reducer/api";

export default function SearchedSection({
  rtl,
  page,
  carTypes,
  setCarTypes,
  selectedCarType,
  setSelectedCarType,
  title,
  filters
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const searched = useSelector((state) => state.searched.searched);
  const loading = useSelector((state) => state.searched.loading);
  const auth = useSelector((state) => state.auth.auth);
  let data = { id: auth && auth.id ? auth.id : "", page: page, filters: filters || {} };

  useEffect(() => {
    dispatch(fetchSearched(data));
  }, [dispatch]);

  const selectBrand = (string, item) => {
    setSelectedCarType({ ...carTypes.filter((v) => v?.id == item?.id)[0] });
    $(".sl").removeClass("active");
    $(string + item?.id).addClass("active");
    let form = {
      // bodyType: item?.name?.en,
      filters: {bodyType: [item?.name?.en], ...filters || {}},
      id: auth.id ? auth.id : "",
      page: page,
    };
    dispatch(fetchPostByBrand(form));
  };

  let responceList =
    searched.default_active && searched.default_active.length > 0
      ? searched.default_active
      : [];

  const handleClick = (datalist) => {
    if (datalist) {
      dispatch(fetchSearched(data));
    }
  };


  // if (loading) {
  //   return (
  //     <div
  //       class="d-flex justify-content-center"
  //       style={{ marginBlock: "25vh" }}
  //     >
  //       <div class="spinner-grow" role="status">
  //         <span class="visually-hidden">Loading...</span>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <SelectViewCar
      rtl={rtl}
      responce={carTypes}
      responceList={responceList}
      title={title || t("Most searched car types")}
      path={"hatchback_cars"}
      carTypes={carTypes}
      selectBrand={selectBrand}
      selectedCarType={selectedCarType}
      handleClick={handleClick}
      loading={loading}
      page={page}
      adsArray={searched?.allAdIds}
      filters={filters}
    />
  );
}

export const whyChooseAkoInBaghdad = [
  {
    title: "Wide Range of Options",
    description: `Brand-new cars from top manufacturers. Pre-owned vehicles at affordable prices. SUVs, sedans, hatchbacks, and luxury models.`,
    image:
      "assets/images/shadow/buy-car-in-baghdad/why-ako-baghdad/wide-catalog.svg",
  },
  {
    title: "Verified Listings",
    description: `All cars listed on Ako Sayara are thoroughly vetted to ensure quality and authenticity, giving buyers peace of mind.`,
    image:
      "assets/images/shadow/buy-car-in-baghdad/why-ako-baghdad/verified-list.svg",
  },
  {
    title: "Competitive Pricing",
    description: `Ako Sayara connects you directly with sellers, eliminating unnecessary middlemen and ensuring you get the best deals.`,
    image:
      "assets/images/shadow/buy-car-in-baghdad/why-ako-baghdad/competitive-price.svg",
  },
  {
    title: "Advanced Search Features",
    description: `The platform’s intuitive search filters allow you to narrow down options based on brand, model, year, price range, and more, making it easy to find the perfect car.`,
    image:
      "assets/images/shadow/buy-car-in-baghdad/why-ako-baghdad/features.svg",
  },
  {
    title: "Seamless Communication",
    description: `Buyers can directly communicate with sellers to inquire about the car's condition, history, and negotiate prices.`,
    image:
      "assets/images/shadow/buy-car-in-baghdad/why-ako-baghdad/communication.svg",
  },
];

export const stepGuideBuyInBaghdad = [
  {
    title: "Browse the Listings",
    description: `Visit the Ako Sayara website or app. Use the search filters to refine your results by brand, model, price, and location. Explore detailed listings, including photos, specifications, and seller information.`,
  },
  {
    title: "Contact the Seller",
    description: `Once you find a car that meets your needs, use the contact details provided to reach out to the seller. Ask questions about the car’s condition, maintenance history, and any additional details not mentioned in the listing.`,
  },
  {
    title: "Inspect and Test Drive",
    description: `Arrange a meeting with the seller to inspect the car in person. Check for any visible wear and tear, verify documentation, and request a test drive to assess performance.`,
  },
  {
    title: "Negotiate the Price",
    description: `Use the information gathered during your inspection and research to negotiate a fair price with the seller.`,
  },
  {
    title: "Finalise the Purchase",
    description: `Once you agree on the price, complete the transaction and ensure all paperwork is in order, including ownership transfer and registration.`,
  },
];

export const whyBuyBaghdad = [
  {
    id: 1,
    image: "assets/images/shadow/buy-car-in-baghdad/why-buy-baghdad/growing-culture.svg",
    icon: "",
    title: "Growing Car Culture",
    description:
      "Baghdad is home to a growing community of car enthusiasts, making it easier to find and explore a variety of models.",
  },
  {
    id: 2,
    image: "assets/images/shadow/buy-car-in-baghdad/why-buy-baghdad/affordable-price.svg",
    icon: "",
    title: "Affordable Prices",
    description:
      "The competitive market ensures buyers have access to cars at fair and affordable prices.",
  },
  {
    id: 3,
    image: "assets/images/shadow/buy-car-in-baghdad/why-buy-baghdad/easy-maintenance.svg",
    icon: "",
    title: "Easy Access to Maintenance Services",
    description:
      "The city has numerous service centres and mechanics to help you maintain your car in top condition.",
  },
  {
    id: 4,
    image: "assets/images/shadow/buy-car-in-baghdad/why-buy-baghdad/convenience-freedom.svg",
    icon: "",
    title: "Convenience and Freedom",
    description:
      "Owning a car in Baghdad offers convenience and freedom to explore the city and beyond at your own pace.",
  },
  {
    id: 5,
    image: "assets/images/shadow/buy-car-in-baghdad/why-buy-baghdad/customisation-options.svg",
    icon: "",
    title: "Customisation Options",
    description:
      "Baghdad’s automotive scene includes shops and specialists who can help customise your vehicle to suit your style.",
  },
];

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NewcarBanner from "./NewcarBanner";
import BrandSection from "../../Tools/BrandSection";
import Benefits from "../../Tools/Benifits";
import Popular from "../../Tools/Popular";
import { useState } from "react";
import { getEntityTypes, getMake } from "../../../Reducer/api";
import ComingSoon from "../../ComingSoon";
import JsonLDSchemaHandler from "../../Tools/JSONLDSchemaUtil";
import { ldSchemas } from "../../../utils/json-schemas";
import PopularModal from "../../Tools/PopularModal";
import RecentlyCarView from "../../Tools/RecentCarView";
import SearchedSection from "../Home/SearchedSection";
import Follow from "../Home/Follow";
import { StepsToBuyNew, StockSection, TopNewCars, WhyNewCars } from "./NewCarsContent";
import PlaceSection from "../Home/PlaceSection";
import FaqSection from "../Home/FaqSection";
import { faq_newcars } from "../../../data/faqContent";
export default function NewCars({ page }) {
  const { t, i18n } = useTranslation();
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  const [carTypes, setCarTypes] = useState([]);
  const [selectedCarType, setSelectedCarType] = useState(null);

  const getCarTypes = async () => {
    const res = await getEntityTypes({page: page, isLive: true});
    setCarTypes(res?.data);
    // setSelectedCarType(res?.data?.[0]);
  };
  useEffect(() => {
    getCarTypes();
  }, []);

  const filters = {
    brandNew: true,
  };

  return (
    <>
      {/* <ComingSoon /> */}
      {/* <Popular title={t("Find deals on popular models")} /> */}
      <NewcarBanner page={page} carTypes={carTypes} filters={filters} />
      <PopularModal
        page={page}
        rtl={rtl}
        filters={filters}
        title={t("Brand new car deals")}
        buttonTitle={"View All New Car Deals"}
      />
      <StockSection />
      <StepsToBuyNew />
      <PlaceSection page={page} />
      <BrandSection brandTitle={t("Browse by brand")} page={page} filters={filters} />
      <TopNewCars />
      <WhyNewCars /> 
      <SearchedSection
        rtl={rtl}
        page={page}
        carTypes={carTypes}
        setCarTypes={setCarTypes}
        selectedCarType={selectedCarType}
        setSelectedCarType={setSelectedCarType}
        filters={filters}
      />
      <FaqSection faq={faq_newcars} type={"newcars"} />
      <RecentlyCarView
        rtl={rtl}
        page={page}
        title={"Recently viewed car deals"}
      />
      <Follow />
      <JsonLDSchemaHandler data={ldSchemas?.newCarDeals} />
    </>
  );
}

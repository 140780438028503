import React, { useEffect, useState } from "react";
import SelectOptions from "../SelctOptions";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SelectMultiple from "../SelectMultiple";
export default function SidebarDropDown({
  title,
  data,
  id,
  handleChange,
  allFilters,
  setAllFilters,
  type,
  showFilteredValues,
  page,
}) {
  const language = useSelector((state) => state.auth.language);
  const { t, i18n } = useTranslation();
  const dataType = page === "normal" ? "data" : "dataRental";

  const setValueToAllFilters = (e, minmax) => {
    const value = e.target.value;
    switch (type) {
      case "min_max_year":
        if (minmax === "min") {
          setAllFilters({
            ...allFilters,
            year: { ...allFilters.year, min: value },
          });
        } else {
          setAllFilters({
            ...allFilters,
            year: { ...allFilters.year, max: value },
          });
        }
        break;
      case "min_max_kilometer":
        if (minmax === "min") {
          setAllFilters({
            ...allFilters,
            km: { ...allFilters.km, min: value },
          });
        } else {
          setAllFilters({
            ...allFilters,
            km: { ...allFilters.km, max: value },
          });
        }
        break;
    }
  };

  const [priceRadio, setPriceRadio] = useState(
    allFilters?.brandNew ? "new" : "select"
  );
  const handleChangePriceRadio = (e) => {
    setPriceRadio(e.target.value);
    if (e.target.value === "select") {
      setAllFilters({ ...allFilters, brandNew: false });
    } else {
      setAllFilters({ ...allFilters, brandNew: true });
    }
  };
  const render = (name) => {
    if (data) {
      switch (name) {
        case "min_max_year":
          return (
            <div class="setp">
              {page === "normal" && (
                <div
                  class="btn-group w-100"
                  style={{ height: "65px" }}
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <input
                    type="radio"
                    class="btn-check"
                    value={"select"}
                    name="btnradionew"
                    onChange={handleChangePriceRadio}
                    id="btnradionew1"
                    autocomplete="off"
                    checked={priceRadio === "select"}
                  />
                  <label
                    class={`btn border rental-price-radio ${
                      priceRadio === "select" ? "active" : ""
                    }`}
                    for="btnradionew1"
                    style={{
                      borderRadius:
                        i18n.language === "English"
                          ? "5px 0px 0px 5px"
                          : "0px 5px 5px 0px",
                    }}
                  >
                    {t("Select year")}
                  </label>

                  <input
                    type="radio"
                    class="btn-check"
                    value={"new"}
                    name="btnradionew"
                    onChange={handleChangePriceRadio}
                    id="btnradionew2"
                    autocomplete="off"
                    checked={priceRadio === "new"}
                  />

                  <label
                    class={`btn border rental-price-radio ${
                      priceRadio === "new" ? "active" : ""
                    }`}
                    for="btnradionew2"
                    style={{
                      borderRadius:
                        i18n.language === "English"
                          ? "0px 5px 5px 0px"
                          : "5px 0px 0px 5px",
                    }}
                  >
                    {t("Brand new")}
                  </label>
                </div>
              )}
              {!allFilters?.brandNew && (
                <>
                  <div class="row">
                    <div
                      class={`col-6 text-${
                        language === "English" ? "start" : "end"
                      }`}
                    >
                      <label>{t(data.min.name[i18n.language])}</label>
                      <SelectOptions
                        style={"form-control"}
                        selectedOption={
                          data?.min?.data?.filter(
                            (v) => v?.value == allFilters?.year?.min
                          )?.[0]?.name?.en || t("Any")
                        }
                        name={data.min.field}
                        values={showFilteredValues(
                          "min_max_year",
                          data.min.data,
                          allFilters?.year?.max,
                          "min"
                        )}
                        onChange={(e) => {
                          setValueToAllFilters(e, "min");
                        }}
                      />
                    </div>
                    <div
                      class={`col-6 text-${
                        language === "English" ? "start" : "end"
                      }`}
                    >
                      <label>{t(data.max.name[i18n.language])}</label>
                      <SelectOptions
                        style={"form-control"}
                        selectedOption={
                          data?.max?.data?.filter(
                            (v) => v?.value == allFilters?.year?.max
                          )?.[0]?.name?.en || t("Any")
                        }
                        name={data.max.field}
                        values={showFilteredValues(
                          "min_max_year",
                          data.max?.[dataType],
                          allFilters?.year?.min,
                          "max"
                        )}
                        onChange={(e) => {
                          setValueToAllFilters(e, "max");
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        case "min_max_kilometer":
          return (
            <div class="setp">
              <div class="row">
                <div
                  class={`col-6 text-${
                    language === "English" ? "start" : "end"
                  }`}
                >
                  <label>{t(data.min.name[i18n.language])}</label>
                  <SelectOptions
                    style={"form-control"}
                    selectedOption={
                      data?.min?.data?.filter(
                        (v) => v.value + "" === allFilters.km.min
                      )?.[0]?.name || t("Any")
                    }
                    name={data.min.field}
                    values={showFilteredValues(
                      "min_max_kilometer",
                      data.min.data,
                      allFilters?.km?.max,
                      "min"
                    )}
                    onChange={(e) => {
                      setValueToAllFilters(e, "min");
                    }}
                  />
                </div>
                <div
                  class={`col-6 text-${
                    language === "English" ? "start" : "end"
                  }`}
                >
                  <label>{t(data.max.name[i18n.language])}</label>
                  <SelectOptions
                    style={"form-control"}
                    selectedOption={
                      data?.max?.data?.filter(
                        (v) => v.value + "" === allFilters.km.max
                      )?.[0]?.name || t("Any")
                    }
                    name={data.max.field}
                    values={showFilteredValues(
                      "min_max_kilometer",
                      data.max.data,
                      allFilters?.km?.min,
                      "max"
                    )}
                    onChange={(e) => {
                      setValueToAllFilters(e, "max");
                    }}
                  />
                </div>
              </div>
            </div>
          );
        case "cityPlates":
          return (
            <div class="">
              <SelectMultiple
                selectedOption={
                  allFilters?.cityPlate &&
                  allFilters?.cityPlate?.length > 0 &&
                  allFilters?.cityPlate !== t("National")
                    ? {
                        label: t(allFilters?.cityPlate?.[0]),
                        value: allFilters?.cityPlate?.[0],
                      }
                    : ""
                }
                placeholder={t("National")}
                onChange={(e) =>
                  setAllFilters({ ...allFilters, cityPlate: e?.value ? [e?.value] : [] })
                }
                values={data}
                showDropdownIcon
                isSearchable
              />
            </div>
          );
      }
    }
  };
  return (
    <ul class="dropdown-menu" id={id}>
      {render(type)}
    </ul>
  );
}

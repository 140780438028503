import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import moment from "moment";
import { convertTime } from "../../../utils/constants";
import { t } from "i18next";
import {
  MultiSectionDigitalClock,
  StaticTimePicker,
} from "@mui/x-date-pickers";
import "dayjs/locale/ar";
import updateLocale from "dayjs/plugin/updateLocale";
import { useMediaQuery } from "@mui/material";

const langT = localStorage.getItem("cl");
let lang = "";
switch (langT) {
  case "English":
    lang = "en";
    break;
  case "Arabic":
    lang = "ar";
    break;
  case "Kurdish":
    lang = "ku";
    break;
}

dayjs.extend(updateLocale);
dayjs.updateLocale(lang, {
  meridiem: (hour, minute, isLowercase) => {
    return hour < 12 ? t("AM") : t("PM"); // Arabic for AM and PM
  },
});
export default function CustomTimePicker({
  modal,
  handleCloseModal,
  modalData,
  onChange,
  onSubmit,
}) {
  const createInputString = (time) => {
    if (time) {
      const date = new Date();
      const time24 = convertTime(time, "24-hour");
      date.setHours(time24.slice(0, 5).split(":")[0]);
      date.setMinutes(time24.slice(0, 5).split(":")[1]);
      return moment(date);
    }
  };
  const [time, setTime] = useState();
  useEffect(() => {
    setTime(dayjs(createInputString(modalData?.time)));
  }, [modalData]);

  const handleSubmit = () => {
    onSubmit(time);
    handleCloseModal();
  };

  return (
    <div>
      <Modal isOpen={modal} centered toggle={handleCloseModal}>
        <ModalHeader
          style={{ alignSelf: "end" }}
          toggle={handleCloseModal}
          className="d-flex justify-content-between border-0 w-100"
        >
          <span className="">{t(modalData?.day)}</span>
        </ModalHeader>
        <ModalBody className="pt-0">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
            <StaticTimePicker
              orientation={
                useMediaQuery("(max-width: 576px)") ? "portrait" : "landscape"
              }
              className="myTimePicker d-flex justify-content-around flex-column flex-sm-row"
              defaultValue={dayjs(createInputString(modalData?.time))}
              ampm
              value={time}
              onChange={(val) => setTime(val)}
              slotProps={{
                layout: {
                  classes: {},
                },
                toolbar: {
                  classes: {
                    ampmSelection: "my-picker-ampm",
                    ampmLabel: "my-picker-ampmLabel",
                    separator: "p-0 m-0 d-flex justify-content-center",
                    hourMinuteLabel: "my-picker-hourMinute",
                  },
                  dir: "ltr",
                },
              }}
            />
            {/* <MultiSectionDigitalClock
              className="justify-content-center align-items-center border-0 mui-timepicker"
              value={time}
              onChange={(val) => setTime(val)}
              ampm
            /> */}
          </LocalizationProvider>

          <div className="d-flex justify-content-end gap-3">
            <button
            className="themeButton w-100 mt-4"
            onClick={(e) => handleSubmit()}
          >
            {t("Save")}
          </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

import { t } from "i18next";
import { numberWithCommas, numWithZero } from "../../../utils/constants";
import { useSelector } from "react-redux";
const any = t("Any");
const skip = ["", NaN, null, undefined, "Any", any];

export default function ValueOnButton({ item, allFilters }) {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  const showValueOnButton = (name) => {
    let text = "";
    switch (name) {
      case "make":
        text = allFilters?.make ? t(allFilters?.make) : any;
        break;
      case "model":
        text = allFilters?.model ? t(allFilters?.model) : any;
        break;
      case "modelTrim":
        text = allFilters?.modelTrim ? t(allFilters?.modelTrim) : any;
        break;
      case "body":
        text =
          allFilters?.condition?.length == 0
            ? any
            : allFilters?.condition?.length > 1
            ? t("Multi")
            : t(allFilters?.condition?.[0]);
        break;
      case "paint":
        text =
          allFilters?.paint?.length == 0
            ? any
            : allFilters?.paint?.length > 1
            ? t("Multi")
            : t(allFilters?.paint?.[0]);
        break;
      case "colour":
        text =
          allFilters?.colour?.length == 0
            ? any
            : allFilters?.colour?.length > 1
            ? t("Multi")
            : t(allFilters?.colour?.[0]);
        break;
      case "interiorColor":
        text =
          allFilters?.interiorColor?.length == 0
            ? any
            : allFilters?.interiorColor?.length > 1
            ? t("Multi")
            : t(allFilters?.interiorColor?.[0]);
        break;
      case "cityPlates":
        text =
          allFilters?.cityPlate?.length == 0
            ? any
            : allFilters?.cityPlate?.length > 1
            ? t("Multi")
            : t(allFilters?.cityPlate?.[0]);
        break;
      case "regional_space":
        text =
          allFilters?.regional?.length == 0
            ? any
            : allFilters?.regional?.length > 1
            ? t("Multi")
            : t(allFilters?.regional?.[0]);
        break;
      case "gearbox":
        text =
          allFilters?.gearbox?.length == 0
            ? any
            : allFilters?.gearbox?.length > 1
            ? t("Multi")
            : t(allFilters?.gearbox?.[0]);
        break;
      case "fuel":
        text =
          allFilters?.fuel?.length == 0
            ? any
            : allFilters?.fuel?.length > 1
            ? t("Multi")
            : t(allFilters?.fuel?.[0]);
        break;
      case "body_type":
        text =
          allFilters?.bodyType?.length == 0
            ? any
            : allFilters?.bodyType?.length > 1
            ? t("Multi")
            : t(allFilters?.bodyType?.[0]);
        break;
      case "plateTypes":
        text =
          allFilters?.plateType?.length == 0
            ? any
            : allFilters?.plateType?.length > 1
            ? t("Multi")
            : t(allFilters?.plateType?.[0]);
        break;
      case "min_max_price":
        if (
          skip.includes(allFilters.price.min) &&
          skip.includes(allFilters.price.max)
        ) {
          text = any;
        } else {
          let min = !skip.includes(allFilters.price.min)
            ? numberWithCommas(allFilters.price.min)
            : any;
          let max = !skip.includes(allFilters.price.max)
            ? numberWithCommas(allFilters.price.max)
            : any;
          text =
            lang === "en"
              ? `${t("IQD")} ${min} - ${t("IQD")} ${max}`
              : `${min} ${t("IQD")} - ${max} ${t("IQD")}`;
        }
        break;
      case "min_max_year":
        if (allFilters.brandNew) {
          text = t("Brand new");
          break;
        }
        if (
          skip.includes(allFilters.year.min) &&
          skip.includes(allFilters.year.max)
        ) {
          text = any;
        } else {
          let min = !skip.includes(allFilters.year.min)
            ? allFilters.year.min
            : any;
          let max = !skip.includes(allFilters.year.max)
            ? allFilters.year.max == 9999
              ? t("Brand new")
              : allFilters.year.max
            : any;
          text = min + " - " + max;
        }
        break;
      case "min_max_kilometer":
        if (
          skip.includes(allFilters.km.min) &&
          skip.includes(allFilters.km.max)
        ) {
          text = any;
        } else {
          let min = !skip.includes(allFilters.km.min)
            ? numberWithCommas(allFilters.km.min)
            : any;
          let max = !skip.includes(allFilters.km.max)
            ? numberWithCommas(allFilters.km.max)
            : any;
          text = max + " - " + min;
        }
        break;
      case "min_max_engine":
        if (
          skip.includes(allFilters.size.min) &&
          skip.includes(allFilters.size.max)
        ) {
          text = any;
        } else {
          let min = !skip.includes(allFilters.size.min)
            ? t(numWithZero(allFilters.size.min) + " " + t("Litre"))
            : any;
          let max = !skip.includes(allFilters.size.max)
            ? t(numWithZero(allFilters.size.max) + " " + t("Litre"))
            : any;
          text = min + " - " + max;
        }
        break;
      case "min_max_battery":
        if (
          skip.includes(allFilters.batterySize.min) &&
          skip.includes(allFilters.batterySize.max)
        ) {
          text = any;
        } else {
          let min = !skip.includes(allFilters.batterySize.min)
            ? t(allFilters.batterySize.min + " " + t("kWh"))
            : any;
          let max = !skip.includes(allFilters.batterySize.max)
            ? t(allFilters.batterySize.max + " " + t("kWh"))
            : any;
          text = min + " - " + max;
        }
        break;
      case "min_max_engine_power":
        if (
          skip.includes(allFilters.power.min) &&
          skip.includes(allFilters.power.max)
        ) {
          text = any;
        } else {
          let min = !skip.includes(allFilters.power.min)
            ? t(allFilters.power.min + " bhb")
            : any;
          let max = !skip.includes(allFilters.power.max)
            ? t(allFilters.power.max + " bhb")
            : any;
          text = min + " - " + max;
        }
        break;
      case "cylinders":
        if (
          skip.includes(allFilters.cylinders.min) &&
          skip.includes(allFilters.cylinders.max)
        ) {
          text = any;
        } else {
          let min = !skip.includes(allFilters.cylinders.min)
            ? allFilters.cylinders.min
            : any;
          let max = !skip.includes(allFilters.cylinders.max)
            ? allFilters.cylinders.max
            : any;
          text = min + " - " + max;
        }
        break;
      case "doors":
        if (
          skip.includes(allFilters.doors.min) &&
          skip.includes(allFilters.doors.max)
        ) {
          text = any;
        } else {
          let min = !skip.includes(allFilters.doors.min)
            ? allFilters.doors.min
            : any;
          let max = !skip.includes(allFilters.doors.max)
            ? allFilters.doors.max
            : any;
          text = min + " - " + max;
        }
        break;
      case "seats":
        if (
          skip.includes(allFilters.seats.min) &&
          skip.includes(allFilters.seats.max)
        ) {
          text = any;
        } else {
          let min = !skip.includes(allFilters.seats.min)
            ? allFilters.seats.min
            : any;
          let max = !skip.includes(allFilters.seats.max)
            ? allFilters.seats.max
            : any;
          text = min + " - " + max;
        }
        break;
    }

    // if (text === t('Any').concat(" - ").concat(any)) return any
    // if (text === (" - ").concat(any)) return ""
    // if (text === t('Any').concat(" - ")) return ""

    return text?.length <= 35 ? text : text?.substring(0, 20).concat("...");
  };

  return (
    <span
      class={`badge m${lang === "en" ? "e" : "s"}-4 rounded-5`}
      style={{
        padding: "5px",
        fontSize: "13px",
        backgroundColor: "none",
        color: "#4C7EE8",
      }}
    >
      {showValueOnButton(item?.backend_column)}
    </span>
  );
}

import { t } from "i18next";
import React from "react";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useSelector } from "react-redux";

function FourCardCarousel({ title, data, cardHeight }) {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const rtl = lang !== "en";
  return (
    <section class="benefits text-center">
      <div class="container">
        <h2 class="hed">{t(title)}</h2>
        <OwlCarousel
          {...{
            loop: true,
            autoplay: true,
            autoplayTimeout: 5000,
            margin: 15,
            responsiveClass: true,
            responsive: {
              0: { items: 1, nav: true },
              576: { items: 1, nav: true },
              768: { items: 2, nav: true },
              992: { items: 4, nav: true },
              1200: { items: 4, nav: true },
            },
          }}
          className={`owl-carousel benefits-owl owl.carousel.min owl-theme owl-rtl owl-loaded owl-drag ${
            rtl && "invertButtons"
          }`}
          // margin={10}
          rtl={rtl}
        >
          {data
            ? data.map((item, key) => {
                return (
                  <div class="set h-100 p-1" key={key}>
                    <div
                      className="shadow-sm rounded p-3 d-flex gap-3 flex-column align-items-center"
                      style={{ minHeight: cardHeight || 270 }}
                    >
                      <img
                        src={item.image}
                        alt="icon"
                        className="m-0"
                        style={{ width: "unset" }}
                      />
                      <h5 className="m-0">{item.title ? t(item.title) : ""}</h5>
                      <p className="m-0">{item.description ? t(item.description) : ""} </p>
                    </div>
                  </div>
                );
              })
            : ""}
        </OwlCarousel>
      </div>
    </section>
  );
}

export default FourCardCarousel;

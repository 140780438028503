import React from "react";
import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useSelector } from "react-redux";
import { t } from "i18next";

function InfoCardsCarousel({ data, title, cols, cardHeight, sectionClassName }) {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const rtl = lang !== "en";
  //   const data = rentBenefitData?.[lang] || [];
  //   const title = rentBenefitData?.title?.[lang] || [];
  const responsive = {
    0: { items: 1, nav: true },
    576: { items: 1, nav: true },
    768: { items: 2, nav: true },
    992: { items: cols || 3, nav: true },
    1200: { items: cols || 3, nav: true },
  };
  return (
    <section className={`benefits text-center ${sectionClassName || ""}`}>
      <div class="container">
        <h2 class="hed">{t(title)}</h2>
        <OwlCarousel
          {...{
            loop: true,
            autoplay: true,
            autoplayTimeout: 5000,
            margin: 15,
            responsiveClass: true,
            responsive: responsive,
          }}
          className={`owl-carousel benefits-owl owl.carousel.min owl-theme owl-rtl owl-loaded owl-drag ${
            rtl && "invertButtons"
          }`}
          // margin={10}
          rtl={rtl}
        >
          {data
            ? data.map((item, key) => {
                return (
                  <div
                    class="set card-border rounded h-100 d-flex flex-column gap-3 w-100"
                    style={{ minHeight: cardHeight || "270px" }}
                    key={key}
                  >
                    <img src={item.image} alt="icon" className="mb-2 mt-2" />
                    <h5 className="m-0">{item.title ? t(item.title) : ""}</h5>
                    <p className="m-0">
                      {item.description ? t(item.description) : ""}{" "}
                    </p>
                  </div>
                );
              })
            : ""}
        </OwlCarousel>
      </div>
    </section>
  );
}

export default InfoCardsCarousel;

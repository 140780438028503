import React from "react";
import { useTranslation } from "react-i18next";
import SelectOptions from "../SelctOptions";
import ListItem from "../ListItem";
import i18n from "../../../i18n";
import { useEffect } from "react";
import { useState } from "react";
import FilterCheckBox from "./filterCheckBox";
import SelectMultiple from "../SelectMultiple";
import { isFieldRequired } from "../Forms/AddCar/addFormUtils";
export default function Specification({
  data,
  allFilters,
  setAllFilters,
  showFilteredValues,
  page,
}) {
  const { t, i18n } = useTranslation();

  return (
    <div class="set fuel1">
      <h4>{t("Specifications")}</h4>

      <FilterCheckBox
        options={data?.colour || []}
        name={"colour"}
        title={"Exterior color"}
        allFilters={allFilters}
        setAllFilters={setAllFilters}
      />

      <FilterCheckBox
        options={data?.interiorColor || []}
        name={"interiorColor"}
        title={"Interior color"}
        allFilters={allFilters}
        setAllFilters={setAllFilters}
      />

      <div class="row">
        {isFieldRequired("plateCity", page) && (
          <div class="col-sm-12">
            <label>{t("Plate city")}</label>
            <SelectMultiple
              placeholder={t("National")}
              onChange={(e) =>
                setAllFilters({
                  ...allFilters,
                  cityPlate: e?.value ? [e?.value] : [],
                })
              }
              values={data?.cityPlates}
              showDropdownIcon
              isSearchable
            />
          </div>
        )}
        {isFieldRequired("plateType", page) && (
          <div class="col-sm-12">
            <label>{t("Plate type")}</label>
            <SelectOptions
              style={"form-control"}
              selectedOption={allFilters?.plateType || t("Any")}
              onChange={(e) =>
                setAllFilters({
                  ...allFilters,
                  plateType: [e.target.value],
                })
              }
              name={"plateTypes"}
              values={data?.plateTypes}
            />
          </div>
        )}
        <div class="col-sm-12">
          <label>{t("Doors")}</label>
          <SelectOptions
            style={"form-control"}
            selectedOption={allFilters?.doors?.min || t("Any")}
            onChange={(e) =>
              setAllFilters({
                ...allFilters,
                doors: { min: e.target.value, max: e.target.value },
              })
            }
            name={"doors"}
            values={data?.doors?.max?.data}
          />
        </div>

        <div class="col-sm-6">
          <label>{t("Min seats")}</label>
          <SelectOptions
            style={"form-control"}
            selectedOption={allFilters?.seats?.min || t("Any")}
            onChange={(e) =>
              setAllFilters({
                ...allFilters,
                seats: { ...allFilters.seats, min: e.target.value },
              })
            }
            name={"min_seats"}
            values={showFilteredValues?.(
              "seats",
              data?.seats?.min?.data,
              allFilters.seats.max,
              "min"
            )}
          />
        </div>
        <div class="col-sm-6">
          <label>{t("Max seats")}</label>
          <SelectOptions
            style={"form-control"}
            selectedOption={allFilters?.seats?.max || t("Any")}
            onChange={(e) =>
              setAllFilters({
                ...allFilters,
                seats: { ...allFilters.seats, max: e.target.value },
              })
            }
            name={"max_seats"}
            values={showFilteredValues?.(
              "seats",
              data?.seats?.max?.data,
              allFilters.seats.min,
              "max"
            )}
          />
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Ratting from "./Ratting";
import { NavLink } from "react-router-dom";
import Button from "../Button";
import CompareItem from "../CompareBox/CompareItem";

import {
  camelToNormal,
  getFeatureName,
  getNameFromVal,
  highlightColor,
} from "../../../utils/constants";

import { Icon } from "@iconify/react";
import { translateInTwoLanguages } from "../../../Reducer/api";

export default function Facts({
  data,
  keys,
  index,
  ratting,
  onChange,
  fullData,
  highlight,
  compareData,
  featureNamesData
}) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const removeItem = (key) => {
    onChange(key);
  };

  const typeOfValue = (v) => {
    const type = typeof v;
    switch (type) {
      case "string":
        return v;
      case "boolean":
        return v;
      case "object":
        return v?.["en"];
      case "number":
        return v;
      default:
        return v;
    }
  };
  const factValues = compareData?.map((v) => Object.values(v));
  const factKeys = compareData && Object.keys(compareData[0]);
  // console.log('comparedata', compareData)
  // console.log(factValues)

  let highlightedItems = [];

  for (let i = 0; i < factValues[0].length; i++) {
    let temp = typeOfValue(factValues[0][i]);
    for (let j = 0; j < factValues.length; j++) {
      if (typeOfValue(factValues[j][i]) != temp) {
        highlightedItems.push(factKeys[i]);
        break;
      }
    }
  }

  const defElse = {
    en: "--",
    ar: "--",
    ku: "--",
  };
  const [elseState, setElse] = useState(defElse);
  useEffect(() => {
    if (data?.[2]?.anyThingElse) {
      translateInTwoLanguages("en", data?.[2]?.anyThingElse).then((res) => {
        setElse(res);
      });
    } else {
      setElse(defElse);
    }
  }, [data]);

  const lineClamp = 2;
  const rowHeight = "67px";

  const makeValue = (name, value) => {
    if (name.length == 0 && value.length == 0) return null;
    const featurName = getFeatureName(name);
    // if (name === "color") {
    //   return (
    //     <tr
    //       style={{
    //         height: rowHeight,
    //         backgroundColor:
    //           highlight && highlightedItems.includes(name)
    //             ? highlightColor
    //             : null,
    //       }}
    //     >
    //       <td>{"Colour"}</td>
    //       <td
    //         className={`float-${lang === "en" ? "end" : "start"} w-50`}
    //         style={{
    //           height: rowHeight,
    //           margin: "15px",
    //           backgroundColor: value,
    //           height: "25px",
    //           border: "2px solid lightgrey",
    //           borderRadius: "5px",
    //         }}
    //       ></td>
    //     </tr>
    //   );
    // }

    if (name === "anyThingElse") {
      return null;
    }

    if (name === "carDescription") {
      // return (
      //   <tr>
      //     <td>
      //       <span
      //         style={{
      //           display: "-webkit-box",
      //           WebkitLineClamp: lineClamp,
      //           WebkitBoxOrient: "vertical",
      //           overflow: "hidden",
      //         }}
      //       >
      //         {t("Car Description")}
      //       </span>
      //     </td>
      //     <td>
      //       <span
      //         style={{
      //           display: "-webkit-box",
      //           WebkitLineClamp: lineClamp,
      //           WebkitBoxOrient: "vertical",
      //           overflow: "hidden",
      //         }}
      //       >
      //         {value?.[lang] ? value?.[lang] : '--'}
      //       </span>
      //     </td>
      //   </tr>
      // );
      return null;
    }
    if (name === "anyThingElse") {
      return (
        <tr
          style={{
            height: rowHeight,
            backgroundColor:
              highlight && highlightedItems.includes(name)
                ? highlightColor
                : null,
          }}
        >
          <td>
            <span
              style={{
                display: "-webkit-box",
                WebkitLineClamp: lineClamp,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {t("Anything else")}
            </span>
          </td>
          <td>
            <span
              style={{
                display: "-webkit-box",
                WebkitLineClamp: lineClamp,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {value ? value : "--"}
            </span>
          </td>
        </tr>
      );
    }
    if (name === "engineSize") {
      const obj = name.replace("engine", "").toLowerCase();
      return (
        <tr
          style={{
            height: rowHeight,
            backgroundColor:
              highlight && highlightedItems.includes(name)
                ? highlightColor
                : null,
          }}
        >
          <td>
            <span
              style={{
                display: "-webkit-box",
                WebkitLineClamp: lineClamp,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {t(featurName)}
            </span>
          </td>
          <td>
            <span
              style={{
                display: "-webkit-box",
                WebkitLineClamp: lineClamp,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {value ? getNameFromVal(obj, value, lang) : "--"}
            </span>
          </td>
        </tr>
      );
    }
    if (name === "enginePower") {
      return (
        <tr
          style={{
            height: rowHeight,
            backgroundColor:
              highlight && highlightedItems.includes(name)
                ? highlightColor
                : null,
          }}
        >
          <td>
            <span
              style={{
                display: "-webkit-box",
                WebkitLineClamp: lineClamp,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {t(featurName)}
            </span>
          </td>
          <td>
            <span
              style={{
                display: "-webkit-box",
                WebkitLineClamp: lineClamp,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {value ? `${value} ${t("bhb")}` : "--"}
            </span>
          </td>
        </tr>
      );
    }
    if (!featurName) {
      console.log("feature name for " + name + " not found in features list");
      return null;
    }
    const type = typeof value;
    switch (type) {
      case "boolean":
        if(!featureNamesData?.[name]?.[lang]) return null
        return value ? (
          <tr
            style={{
              height: rowHeight,
              backgroundColor:
                highlight && highlightedItems.includes(name)
                  ? highlightColor
                  : null,
            }}
          >
            <td>
              <span
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: lineClamp,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {featureNamesData?.[name]?.[lang]}{" "}
              </span>
            </td>
            <td>
              <Icon icon="ic:baseline-check" color="green" width={15} />
            </td>
          </tr>
        ) : (
          <tr
            style={{
              height: rowHeight,
              backgroundColor:
                highlight && highlightedItems.includes(name)
                  ? highlightColor
                  : null,
            }}
          >
            <td>
              <span
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: lineClamp,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {featureNamesData?.[name]?.[lang]}
              </span>{" "}
            </td>
            <td>
              <Icon icon="maki:cross" color="red" width={15} />
            </td>
          </tr>
        );
      case "string":
        return (
          <tr
            style={{
              height: rowHeight,
              backgroundColor:
                highlight && highlightedItems.includes(name)
                  ? highlightColor
                  : null,
            }}
          >
            <td>
              <span
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: lineClamp,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {t(featurName) ? t(featurName) : name}
              </span>
            </td>
            <td>
              <span
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: lineClamp,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {value ? t(value) : "--"}
              </span>
            </td>
          </tr>
        );
      case "number":
        return (
          <tr
            style={{
              height: rowHeight,
              backgroundColor:
                highlight && highlightedItems.includes(name)
                  ? highlightColor
                  : null,
            }}
          >
            <td>
              <span
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: lineClamp,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {t(featurName) ? t(featurName) : name}
              </span>
            </td>
            <td>
              <span
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: lineClamp,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {value ? value : "--"}
              </span>
            </td>
          </tr>
        );
      case "object":
        return (
          <tr
            style={{
              height: rowHeight,
              backgroundColor:
                highlight && highlightedItems.includes(name)
                  ? highlightColor
                  : null,
            }}
          >
            <td>
              <span
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: lineClamp,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {t(featurName)}
              </span>
            </td>
            <td>
              <span
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: lineClamp,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {value?.[lang] ? t(value?.[lang]) : "--"}
              </span>
            </td>
          </tr>
        );
    }
  };

  return (
    <div class="" style={{ width: "270px" }}>
      <CompareItem item={fullData} index={index} onChange={onChange} />
      <table class="table">
        <tbody>
          {/* {ratting === true && (
            <Ratting
              ratting={data.ratting ? data.ratting : 3}
              reviews={data.reviews}
            />
          )} */}
          {data && Object.keys(data).map((item) => {
            let val = data?.[item];
            if (val === "true") val = true;
            if (val === "false") val = false;
            const valu = makeValue(item, val);
            return valu;
          })}
        </tbody>
      </table>
      <div class="text-center">
        <NavLink to={`/Chat/${data.id}`} className="Contactbtn mb-1">
          {t("Contact")}
        </NavLink>
        <Button
          style="Removebtn"
          onClick={() => removeItem(index)}
          title={t("Remove")}
        />
      </div>
    </div>
  );
}
